import serializePostImage from './serialization/serializePostImage'
import EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class PostImageFileBuilder {
  private width: number | null = null

  constructor(private postImageId: string) {}

  withWidth(width: number | null): this {
    this.width = width

    return this
  }

  build(): EmbeddedFile {
    const bytes = serializePostImage({
      type: 'post-image',
      postImageId: this.postImageId,
      width: this.width,
    })

    return EmbeddedFile.fromBytes(MediaTypes.PostImage, bytes)
  }
}
