import React, { useEffect, useState } from 'react'
import type { LoaderFunction } from 'react-router-dom'
import { json, useLoaderData } from 'react-router-dom'

import type { RequestContext } from '@computomatic/service-types'

import type PostMetadata from '../../workspace/controller/client/PostMetadata'
import type WorkspaceController from '../../workspace/controller/client/WorkspaceController'
import HomeScreen from '../../workspace/home/HomeScreen'
import type { PublishedPostLink } from '../../workspace/home/Post'
import type Post from '../../workspace/home/Post'
import type UpdateDomainResult from '../../workspace/home/domain/UpdateDomainResult'
import type AppConfiguration from '../AppConfiguration'
import { useRequestContext, useWorkspaceController } from '../services'

function postEditorUrl(postId: string): string {
  return `/posts/${postId}/editor`
}

interface HomeScreenLoaderData {
  domainName: string
  rootDomain: string
  hasCustomDomain: boolean
  posts: PostMetadata[]
}

export const homeScreenDataLoader =
  (
    ctx: RequestContext,
    { websitesRootDomain }: AppConfiguration,
    workspaceController: WorkspaceController,
  ): LoaderFunction =>
  async () => {
    const {
      website: { primaryDomainName: domainName, hasCustomDomain },
    } = await workspaceController.getCurrentWebsite(ctx, {})

    const { posts } = await workspaceController.findPosts(ctx, {})

    return json<HomeScreenLoaderData>({ domainName, rootDomain: websitesRootDomain, hasCustomDomain, posts })
  }

export default function HomeScreenBinding(): React.ReactElement {
  const ctx = useRequestContext()
  const controller = useWorkspaceController()
  const {
    domainName: initialDomainName,
    rootDomain,
    hasCustomDomain: initialHasCustomDomain,
    posts: initialPosts,
  } = useLoaderData() as HomeScreenLoaderData
  const [domainName, setDomainName] = useState(initialDomainName)
  const [hasCustomDomain, setHasCustomDomain] = useState(initialHasCustomDomain)
  const [posts, setPosts] = useState(initialPosts)

  // Sort posts from most-recently updated to least-recently updated.
  const sortedPosts = posts
  sortedPosts.sort(({ updated: a }, { updated: b }) => b - a)
  const postTiles = sortedPosts.map(({ id, filename, urlPath, canonicalUrl }): Post => {
    let publishedPost: PublishedPostLink | undefined

    if (urlPath !== null && canonicalUrl !== null) {
      publishedPost = {
        canonicalUrl,
        urlPath,
      }
    }

    return {
      postId: id,
      filename,
      publishedPost,
      editorUrl: postEditorUrl(id),
    }
  })

  // Reload post metadata when the domain name changes
  useEffect(() => {
    const doAsyncUpdate = async (): Promise<void> => {
      const { posts: latestPosts } = await controller.findPosts(ctx, {})
      setPosts(latestPosts)
    }

    doAsyncUpdate()
  }, [controller, ctx, domainName])

  const updateDomain = async (newDomainName: string): Promise<UpdateDomainResult> => {
    if (hasCustomDomain) {
      throw new Error(`Site already has custom domain.`)
    }

    const result = await controller.setCustomDomainName(ctx, { domainName: newDomainName })
    if (result.status === 'success') {
      setDomainName(newDomainName)
      setHasCustomDomain(true)
    }

    return result
  }

  const removeDomain = (): void => {
    async function doRemove(): Promise<void> {
      const {
        website: { primaryDomainName, hasCustomDomain: respHasCustomDomain },
      } = await controller.removeCustomDomainName(ctx, {})

      setHasCustomDomain(respHasCustomDomain)
      setDomainName(primaryDomainName)
    }

    doRemove()
  }

  return (
    <HomeScreen
      domain={domainName}
      rootDomain={rootDomain}
      hasCustomDomain={hasCustomDomain}
      posts={postTiles}
      onSetCustomDomain={updateDomain}
      onRemoveCustomDomain={removeDomain}
    />
  )
}
