import React from 'react'

import PublishSuccessModal from './PublishSuccessModal'
import usePostEditorState from './state/usePostEditorState'
import ModalOverlay from '../ui/overlays/ModalOverlay'

/**
 * @returns a modal overlay for any active modal, otherwise null.
 */
export default function EditorModals(): React.ReactElement | null {
  const { showPublishSuccess } = usePostEditorState()

  if (showPublishSuccess) {
    const { pageUrl } = showPublishSuccess

    return (
      <ModalOverlay>
        <PublishSuccessModal
          href={pageUrl}
          // TODO: Clean up URL for presentation
          prettyUrl={pageUrl}
        />
      </ModalOverlay>
    )
  }

  return null
}
