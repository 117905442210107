import type { AriaAttributes } from 'react'
import React, { useEffect, useState } from 'react'

import { Node } from '@tiptap/core'
import History from '@tiptap/extension-history'
import Text from '@tiptap/extension-text'
import type { EditorEvents, EditorOptions, JSONContent } from '@tiptap/react'
import { useEditor } from '@tiptap/react'
import classNames from 'classnames'

import styles from './InlineTextEditor.module.scss'

const InlineDocument = Node.create({
  name: 'doc',
  topNode: true,
  content: 'inline*',
})

const TIPTAP_EXTENSIONS = [InlineDocument, Text, History]
const EDITOR_CLASS_NAME = styles.tipTapEditor

function toJsonContent(value: string): JSONContent {
  const textNodes: JSONContent[] = []
  if (value.length > 0) {
    textNodes.push({ type: 'text', text: value })
  }

  return {
    type: 'doc',
    content: textNodes,
  }
}

function fromJsonContent(json: JSONContent): string {
  if (json.type !== 'doc') {
    throw new Error(`Unexpected node type for document (${json.type})`)
  }

  const textNodes = json.content ?? []
  textNodes.forEach(({ type }) => {
    if (type !== 'text') {
      throw new Error(`Unexpected node type for text node (${type})`)
    }
  })

  return textNodes.map(({ text = '' }) => text).join('')
}

interface StaticDisplayProps {
  value: string
  className?: string
}

function StaticDisplay({ value, className }: StaticDisplayProps): React.ReactElement {
  return <span className={className}>{value}</span>
}

interface Props extends Pick<AriaAttributes, 'aria-label'> {
  className?: string
  defaultValue: string
  onBlur?: () => void
  onChange: (value: string) => void
  onFocus?: () => void
}

export default function InlineTextEditor({
  className,
  defaultValue,
  'aria-label': ariaLabel,
  onChange,
  onBlur,
  onFocus,
}: Props): React.ReactElement {
  const [spanRef, setSpanRef] = useState<HTMLSpanElement | null>(null)
  const [editable, setEditable] = useState(false)

  // Prevent rendering editor during server-side render
  useEffect(() => setEditable(true), [])

  // Capture initial value and ignore subsequent changes to the prop
  const [initialValue] = useState(toJsonContent(defaultValue))

  const attributes: Record<string, string> = {}

  if (EDITOR_CLASS_NAME) {
    attributes.class = EDITOR_CLASS_NAME
  }

  if (ariaLabel) {
    attributes['aria-label'] = ariaLabel
  }

  const editorProps = { attributes }

  const handleEditorUpdated = ({ editor: updatedEditor }: EditorEvents['update']): void => {
    const json = updatedEditor.getJSON()
    const updatedValue = fromJsonContent(json)
    onChange(updatedValue)
  }

  const opts: Partial<EditorOptions> & Record<string, unknown> = {
    extensions: TIPTAP_EXTENSIONS,
    content: initialValue,
    editorProps,
    onUpdate: handleEditorUpdated,
    onBlur,
    onFocus,
  }

  // Workaround for a bug in TitTap that tries to use own props when value is undefined.
  Object.keys(opts).forEach((key) => {
    if (opts[key] === undefined) {
      delete opts[key]
    }
  })

  if (spanRef !== null) {
    opts.element = spanRef
  }

  const editorOptions = opts
  useEditor(editorOptions, [spanRef])

  if (!editable) {
    return (
      <StaticDisplay
        value={defaultValue}
        className={className}
      />
    )
  }

  return (
    <span
      ref={setSpanRef}
      className={classNames(styles.InlineTextEditor, className)}
    />
  )
}
