import renderCssRule from './renderCssRule'
import type Stylesheet from '../../services/publishing/client/Stylesheet'

function appendClassRule(sheet: CSSStyleSheet, cssClassName: string, cssProps: React.CSSProperties): void {
  const cssRule = renderCssRule(`.${cssClassName}`, cssProps)
  sheet.insertRule(cssRule)
}

function buildCssStylesheet(stylesheet: Stylesheet): CSSStyleSheet {
  const sheet = new CSSStyleSheet()

  Object.entries(stylesheet).forEach(([className, cssProps]) => {
    appendClassRule(sheet, className, cssProps)
  })

  return sheet
}

/**
 * Generates a CSS stylesheet and installs it into the DOM as an adopted (ie, generated) stylesheet.
 *
 * @param stylesheet a map of CSS Property declarations, keyed by CSS class name
 * @returns an uninstall function which removes the stylesheet when invoked.
 */
export default function installStyles(stylesheet: Stylesheet): () => void {
  const sheet = buildCssStylesheet(stylesheet)

  document.adoptedStyleSheets.push(sheet)

  const uninstall = (): void => {
    // Remove the sheet
    const idx = document.adoptedStyleSheets.indexOf(sheet)
    if (idx >= 0) {
      document.adoptedStyleSheets.splice(idx, 1)
    }
  }

  return uninstall
}
