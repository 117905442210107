import { createContext, useContext } from 'react'

import type SelectionState from './SelectionState'

export const SelectionStateContext = createContext<SelectionState | undefined>(undefined)

export default function useSelectionState(): SelectionState {
  const result = useContext(SelectionStateContext)
  if (result === undefined) {
    throw new Error(`SelectionStateContext is unexpectedly empty`)
  }

  return result
}
