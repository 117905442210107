import React from 'react'

import DragDropProvider from './drag-drop/DragDropProvider'
import EmbeddedFileProvider from './files/EmbeddedFileProvider'
import { GetImageUrlContext } from './images/useGetImageUrl'
import type { UploadImageHandler } from './images/useUploadImage'
import { UploadImageContext } from './images/useUploadImage'
import SelectionProvider from './selection/SelectionProvider'
import StylesheetProvider from './stylesheets/StylesheetProvider'
import type EmbeddedFileCollection from '../services/posts/client/EmbeddedFileCollection'
import type EmbeddedFile from '../services/posts/files/EmbeddedFile'
import type Stylesheet from '../services/publishing/client/Stylesheet'

interface Props {
  embeddedFiles: EmbeddedFileCollection
  stylesheet: Stylesheet
  createEmbeddedFile: (file: EmbeddedFile) => Promise<{ fileId: string }>
  getImageUrl: (imageId: string) => string
  uploadImage: UploadImageHandler
  onEmbeddedFileChange: (fileId: string, updatedFile: EmbeddedFile) => void
  children: React.ReactNode
}

export default function PostContentProvider({
  embeddedFiles,
  stylesheet,
  createEmbeddedFile,
  getImageUrl,
  uploadImage,
  onEmbeddedFileChange,
  children,
}: Props): React.ReactElement {
  return (
    <EmbeddedFileProvider
      embeddedFiles={embeddedFiles}
      onCreateFile={createEmbeddedFile}
      onUpdateFile={onEmbeddedFileChange}
    >
      <UploadImageContext.Provider value={uploadImage}>
        <GetImageUrlContext.Provider value={getImageUrl}>
          <DragDropProvider>
            <StylesheetProvider stylesheet={stylesheet}>
              <SelectionProvider>{children}</SelectionProvider>
            </StylesheetProvider>
          </DragDropProvider>
        </GetImageUrlContext.Provider>
      </UploadImageContext.Provider>
    </EmbeddedFileProvider>
  )
}
