import { InlineText20240118 } from './InlineText20240118'
import type InlineText from '../InlineText'

const CURRENT_SCHEMA = InlineText20240118

function mapInlineTextFrom20240118(parsed: InlineText20240118): InlineText {
  // Current schema
  return parsed
}

function mapLegacySchema(parsed: unknown): InlineText {
  // Base case
  if (CURRENT_SCHEMA.is(parsed)) {
    return parsed
  }

  // Test schemas in reverse-chronological order.
  if (InlineText20240118.is(parsed)) {
    const next = mapInlineTextFrom20240118(parsed)

    return mapLegacySchema(next)
  }

  throw new Error(`Parsed json does not match any known schema.`)
}

export default function parseInlineText(data: string): InlineText {
  const parsed = JSON.parse(data)

  return mapLegacySchema(parsed)
}
