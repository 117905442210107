import React, { useState } from 'react'

import { type KvDatabaseEntry, type KvDatabaseMetadata } from '../kvdb-editor-backend/KvDbEditorBackendClient'

import styles from './KvDbEditor.module.scss'

export interface SelectedDatabase {
  databaseId: string
  entries: KvDatabaseEntry[]
}

interface EntriesProps {
  databaseId: string
  entries: KvDatabaseEntry[]
  onClickAddValue: (key: string, value: string) => void
}

function DatabaseDetail({ databaseId, entries, onClickAddValue }: EntriesProps): React.ReactElement {
  const [newKeyInput, setNewKeyInput] = useState('')
  const [newValueInput, setNewValueInput] = useState('')

  const handleAddValueSubmitted = (e: React.SyntheticEvent): void => {
    e.preventDefault()

    onClickAddValue(newKeyInput, newValueInput)
    setNewKeyInput('')
    setNewValueInput('')
  }

  const entryItems = entries.map(({ key, value }) => (
    <li key={key}>
      {key}: {`${value}`}
    </li>
  ))

  if (entryItems.length === 0) {
    entryItems.push(<li>There are no entries in this database.</li>)
  }

  return (
    <div className={styles.DatabaseDetail}>
      <h1>{databaseId}</h1>
      <ul>{entryItems}</ul>
      <form onSubmit={handleAddValueSubmitted}>
        <p>
          <input
            type="text"
            placeholder="Key"
            value={newKeyInput}
            onChange={(e) => setNewKeyInput(e.target.value)}
          />
          <input
            type="text"
            placeholder="Value"
            value={newValueInput}
            onChange={(e) => setNewValueInput(e.target.value)}
          />
          <button
            type="submit"
            onClick={handleAddValueSubmitted}
          >
            Add Value
          </button>
        </p>
      </form>
    </div>
  )
}

interface DatabaseListProps {
  databases: KvDatabaseMetadata[]
}

function DatabaseList({ databases }: DatabaseListProps): React.ReactElement {
  const dbItems = databases.map(({ databaseId }) => (
    <li key={databaseId}>
      <a href={`/kv-databases/${databaseId}`}>{databaseId}</a>
    </li>
  ))

  if (dbItems.length === 0) {
    dbItems.push(<li>No databases exist</li>)
  }

  return (
    <div className={styles.DatabaseList}>
      <h1>KV Database Editor</h1>
      <ul>{dbItems}</ul>
    </div>
  )
}

interface Props {
  databases: KvDatabaseMetadata[]
  selectedDatabase: SelectedDatabase | undefined

  onClickCreate: () => void
  onClickAddValue: (key: string, value: string) => void
}

export default function KvDbEditor({
  databases,
  selectedDatabase,
  onClickCreate,
  onClickAddValue,
}: Props): React.ReactElement {
  let dbDetail: React.ReactNode = null

  if (selectedDatabase !== undefined) {
    dbDetail = (
      <DatabaseDetail
        databaseId={selectedDatabase.databaseId}
        entries={selectedDatabase.entries}
        onClickAddValue={onClickAddValue}
      />
    )
  }

  return (
    <div className={styles.KvDbEditor}>
      <div className={styles.databases}>
        <DatabaseList databases={databases} />
        <p>
          <button
            type="button"
            onClick={onClickCreate}
          >
            Create Database
          </button>
        </p>
      </div>

      <div className={styles.detail}>{dbDetail}</div>
    </div>
  )
}
