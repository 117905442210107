import { useEffect } from 'react'

export interface KeyDownEvent {
  key: string
  shiftKey: boolean
  metaKey: boolean
}

function mapKeyDownEvent(e: KeyboardEvent): KeyDownEvent {
  const { shiftKey, metaKey: browserMeta, ctrlKey, key } = e

  // Let meta be Cmd on Mac or Ctrl on Windows. This implementation happens to include Ctrl on mac as collateral damage.
  // TODO: Avoid treating Ctrl on mac as meta
  const normalizedMeta = browserMeta || ctrlKey

  return {
    key,
    shiftKey,
    metaKey: normalizedMeta,
  }
}

interface Props {
  onKeyDown: (e: KeyDownEvent) => boolean
}

export default function KeyboardListener({ onKeyDown }: Props): null {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      const keyDownEvent = mapKeyDownEvent(e)
      const handled = onKeyDown(keyDownEvent)

      if (handled) {
        e.preventDefault()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onKeyDown])

  return null
}
