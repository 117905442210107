import React from 'react'

import styles from './ModalOverlay.module.scss'

interface Props {
  children: React.ReactNode
}

export default function ModalOverlay({ children }: Props): React.ReactElement {
  return <div className={styles.ModalOverlay}>{children}</div>
}
