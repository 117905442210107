import type ActionDemoController from '../app/controllers/ActionDemoController'
import { expectEnv } from '../env'
import type KvDbEditorBackendClient from '../kvdb-editor-backend/KvDbEditorBackendClient'
import type { PostEditorBackendClient } from '../post-editor-backend/client'
import ProxyClient from '../proxy/ProxyClient'
import type WorkspaceController from '../workspace/controller/client/WorkspaceController'

function buildEndpointUrl(controllerPath: string): string {
  const rpcEndpoint = expectEnv('RPC_ENDPOINT')

  return `${rpcEndpoint}${controllerPath}`
}

export function createWorkspaceControllerHttpClient(): WorkspaceController {
  const endpoint = buildEndpointUrl('/workspace')

  return ProxyClient.create<WorkspaceController>(endpoint)
}

export function createKvDbEditorBackendHttpClient(): KvDbEditorBackendClient {
  const endpoint = buildEndpointUrl('/kvdb-editor')

  return ProxyClient.create<KvDbEditorBackendClient>(endpoint)
}

export function createActionDemoControllerHttpClient(): ActionDemoController {
  const endpoint = buildEndpointUrl('/action-demo')

  return ProxyClient.create<ActionDemoController>(endpoint)
}

export function createPostEditorBackendHttpClient(): PostEditorBackendClient {
  const endpoint = buildEndpointUrl('/post-editor')

  return ProxyClient.create<PostEditorBackendClient>(endpoint)
}
