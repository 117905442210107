import type { PostImage20240531 } from './PostImage20240531'
import type { PostImage20240611 } from '../2024-06-11/PostImage20240611'

export default function mapPostImageFrom20240531(parsed: PostImage20240531): PostImage20240611 {
  // Default to null width
  const width = null

  return {
    ...parsed,
    width,
  }
}
