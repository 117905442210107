import type { DragEventHandler } from 'react'
import React from 'react'

import type EmbeddedFile from '../../../services/posts/files/EmbeddedFile'
import type { DragStartedState } from '../../drag-drop/DragDropState'
import type XYCoord from '../../drag-drop/XYCoord'
import { getEmbeddedFile, hasEmbeddedFileJson } from '../../drag-drop/draggingFileData'
import useDragDropDispatch from '../../drag-drop/useDragDropDispatch'
import useDragDropState from '../../drag-drop/useDragDropState'

type DropEffect = DataTransfer['dropEffect']

function subtract(minuend: XYCoord, subtrahend: XYCoord): XYCoord {
  return {
    x: minuend.x - subtrahend.x,
    y: minuend.y - subtrahend.y,
  }
}

/** coordinates of the mouse pointer between the event and the padding edge of the target node */
function readDropPointerOffset(e: React.DragEvent): XYCoord {
  const {
    /** X coordinate of the mouse pointer between the event and the padding edge of the target node */
    offsetX: pointerOffsetX,
    /** Y coordinate of the mouse pointer between the event and the padding edge of the target node */
    offsetY: pointerOffsetY,
  } = e.nativeEvent

  return { x: pointerOffsetX, y: pointerOffsetY }
}

function calculateItemOffsetRelativeToTarget(dragState: DragStartedState, e: React.DragEvent): XYCoord {
  let initialPointerOffsetRelativeToSourceItem: XYCoord = { x: 0, y: 0 }
  if (dragState.stateType === 'dragging') {
    const { initialPointerRelativeOffset } = dragState
    initialPointerOffsetRelativeToSourceItem = initialPointerRelativeOffset
  }

  const dropPointerOffset = readDropPointerOffset(e)

  return subtract(dropPointerOffset, initialPointerOffsetRelativeToSourceItem)
}

interface Props {
  onDragOver: () => void
  onDragLeave: () => void
  onDrop: (droppedFile: EmbeddedFile, relativeItemOffset: XYCoord, itemWidth: number, dropEffect: DropEffect) => void
  children: React.ReactNode
}

export default function FileDropTarget({ onDragOver, onDragLeave, onDrop, children }: Props): React.ReactElement {
  const dispatch = useDragDropDispatch()
  const { dragState } = useDragDropState()

  const handleDragLeave: DragEventHandler = (e) => {
    if (!hasEmbeddedFileJson(e.dataTransfer)) {
      return
    }

    onDragLeave()
  }

  const handleDragOver: DragEventHandler = (e) => {
    const hasFileJson = hasEmbeddedFileJson(e.dataTransfer)
    if (!hasFileJson) {
      return
    }

    /* Calling preventDefault() indicates to the browser that this is a valid drop target. */
    e.preventDefault()

    e.dataTransfer.effectAllowed = 'copyMove'

    onDragOver()
  }

  const handleDrop: DragEventHandler = (e) => {
    const hasFileJson = hasEmbeddedFileJson(e.dataTransfer)
    if (!hasFileJson) {
      return
    }
    e.preventDefault()

    dispatch({ type: 'dragDrop/drop', payload: {} })

    const { dropEffect } = e.dataTransfer

    const file = getEmbeddedFile(e.dataTransfer)

    let dropItemOffsetRelativeToTarget: XYCoord = { x: 0, y: 0 }
    let itemWidth = 200
    if (dragState.stateType === 'dragging') {
      const { initialSourceRenderedWidthPx } = dragState
      itemWidth = initialSourceRenderedWidthPx
      dropItemOffsetRelativeToTarget = calculateItemOffsetRelativeToTarget(dragState, e)
    }

    onDrop(file, dropItemOffsetRelativeToTarget, itemWidth, dropEffect)
  }

  return (
    <div
      onDragLeave={handleDragLeave}
      /** required for browser to enable drop events */
      onDragOver={handleDragOver}
      /** required for browser to enable drop events */
      onDrop={handleDrop}
      // Note about "dragend": the browser will only fire dragend events on drag sources, not drop targets
    >
      {children}
    </div>
  )
}
