import { createContext, useContext } from 'react'

import type EmbeddedFileCollection from '../../services/posts/client/EmbeddedFileCollection'
import EmbeddedFile from '../../services/posts/files/EmbeddedFile'

export const EmbeddedFilesContext = createContext<EmbeddedFileCollection>({})

export default function useEmbeddedFile(fileId: string): EmbeddedFile {
  const embeddedFiles = useContext(EmbeddedFilesContext)

  const fileJson = embeddedFiles[fileId]
  if (fileJson === undefined) {
    throw new Error(`File not found in collection. (${fileId})`)
  }

  return EmbeddedFile.fromJson(fileJson)
}
