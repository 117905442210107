import type FlowDocument from './FlowDocument'
import stringifyFlowDocument from './serialization/stringifyFlowDocument'
import EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class FlowDocumentFileBuilder {
  constructor(private value: FlowDocument) {}

  build(): EmbeddedFile {
    const textData = stringifyFlowDocument(this.value)

    return EmbeddedFile.fromText(MediaTypes.FlowDocument, textData)
  }
}
