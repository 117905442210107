import React from 'react'

import classNames from 'classnames'

import styles from './MenuDrawer.module.scss'

const STYLE_OPEN = styles.open ?? '__unknown-style__'
const STYLE_CLOSED = styles.closed ?? '__unknown-style__'

interface Props {
  open: boolean
  testingId: string | undefined
  children: React.ReactNode
}

export default function MenuDrawer({ open, testingId, children }: Props): React.ReactElement {
  return (
    <div
      className={classNames(styles.MenuDrawer, {
        [STYLE_OPEN]: open,
        [STYLE_CLOSED]: !open,
      })}
      data-testing-id={testingId}
    >
      <div className={styles.content}>{children}</div>
    </div>
  )
}
