import type { FrameState } from './FrameState'
import parseFrame from './serialization/parseFrame'
import type EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class FrameFileReader {
  private constructor(public readonly state: FrameState) {}

  static openFile(file: EmbeddedFile): FrameFileReader {
    if (file.mediaType !== MediaTypes.Frame) {
      throw new Error(`Unexpected media type: ${file.mediaType}`)
    }

    const json = file.readAsText()
    const document = parseFrame(json)

    return new FrameFileReader(document)
  }
}
