import { createContext, useContext } from 'react'

import type { RequestContext } from '@computomatic/service-types'

import type ActionDemoController from './controllers/ActionDemoController'
import type KvDbEditorBackendClient from '../kvdb-editor-backend/KvDbEditorBackendClient'
import type { PostEditorBackendClient } from '../post-editor-backend/client'
import PublishingPluginRepository from '../services/publishing/plugins/PublishingPluginRepository'
import type WorkspaceController from '../workspace/controller/client/WorkspaceController'

export const WorkspaceControllerContext = createContext<WorkspaceController | undefined>(undefined)
export function useWorkspaceController(): WorkspaceController {
  const client = useContext(WorkspaceControllerContext)
  if (client === undefined) {
    throw new Error(`WorkspaceController is unexpectedly empty.`)
  }

  return client
}

export const KvDbEditorBackendContext = createContext<KvDbEditorBackendClient | undefined>(undefined)
export function useKvDbEditorBackend(): KvDbEditorBackendClient {
  const client = useContext(KvDbEditorBackendContext)
  if (client === undefined) {
    throw new Error(`KvEditorBackendContext is unexpectedly empty`)
  }

  return client
}

export const PostEditorBackendContext = createContext<PostEditorBackendClient | undefined>(undefined)
export function usePostEditorBackendClient(): PostEditorBackendClient {
  const client = useContext(PostEditorBackendContext)
  if (client === undefined) {
    throw new Error(`PostEditorBackendClient is unexpectedly empty`)
  }

  return client
}

export const ActionDemoControllerContext = createContext<ActionDemoController | undefined>(undefined)
export function useActionDemoController(): ActionDemoController {
  const client = useContext(ActionDemoControllerContext)
  if (client === undefined) {
    throw new Error(`ActionDemoController is unexpectedly empty`)
  }

  return client
}

export const PublishingPluginsContext = createContext<PublishingPluginRepository>(new PublishingPluginRepository({}))
export function usePublishingPlugins(): PublishingPluginRepository {
  return useContext(PublishingPluginsContext)
}

export const RequestContextContext = createContext<RequestContext | undefined>(undefined)
export function useRequestContext(): RequestContext {
  const ctx = useContext(RequestContextContext)
  if (ctx === undefined) {
    throw new Error(`RequestContext is unexpectedly empty.`)
  }

  return ctx
}
