import type { FrameElement, FrameState } from './FrameState'
import serializeFrame from './serialization/serializeFrame'
import { appendElement } from './transforms'
import EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class FrameFileBuilder {
  private state: FrameState

  constructor(height: number) {
    this.state = {
      type: 'frame.v1',
      height,
      elements: [],
    }
  }

  withFileBinding(nodeId: string, index: number, fileId: string, x: number, y: number): this {
    const block: FrameElement = {
      id: nodeId,
      index,
      type: 'block',
      x,
      y,
      blockType: 'file-binding',
      fileRefType: 'embedded-file',
      fileId,
    }
    this.state = appendElement(this.state, block)

    return this
  }

  build(): EmbeddedFile {
    const json = serializeFrame(this.state)

    return EmbeddedFile.fromText(MediaTypes.Frame, json)
  }

  static fromState(state: FrameState): FrameFileBuilder {
    const { height, elements } = state

    return elements.reduce((acc, element) => {
      if (element.type !== 'block' || element.blockType !== 'file-binding' || element.fileRefType !== 'embedded-file') {
        throw new Error(`Unexpected element: ${element.type}/${element.blockType}`)
      }

      const { id: nodeId, index, x, y, fileId } = element

      return acc.withFileBinding(nodeId, index, fileId, x, y)
    }, new FrameFileBuilder(height))
  }
}
