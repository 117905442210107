import React, { useState } from 'react'
import type { LoaderFunction } from 'react-router-dom'
import { json, useLoaderData } from 'react-router-dom'

import type { RequestContext } from '@computomatic/service-types'

import { useActionDemoController } from '../../services'

interface LoaderData {
  ctx: RequestContext
}

export const actionDemoScreenDataLoader =
  (ctx: RequestContext): LoaderFunction =>
  async () =>
    json<LoaderData>({ ctx })

export default function ActionDemoScreen(): React.ReactElement {
  const { ctx } = useLoaderData() as LoaderData
  const controller = useActionDemoController()

  const [databaseId, setDatabaseId] = useState('')
  const [key, setKey] = useState('example-key')
  const [value, setValue] = useState('')

  const [showSaved, setShowSaved] = useState(false)

  const handleFormSubmitted = (e: React.FormEvent): void => {
    e.preventDefault()

    const asyncSave = async (): Promise<void> => {
      await controller.invokeAction(ctx, { databaseId, key, value })

      setShowSaved(true)
    }

    asyncSave()
  }

  const saveMessage = showSaved ? <p style={{ backgroundColor: '#ffd7d7', fontWeight: 'bold' }}>Data saved!</p> : null

  return (
    <form onSubmit={handleFormSubmitted}>
      <p>
        Database ID{' '}
        <input
          type="text"
          value={databaseId}
          onChange={(e) => setDatabaseId(e.target.value)}
        />
      </p>
      <p>
        Key{' '}
        <input
          type="text"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </p>
      <p>
        Value{' '}
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </p>
      {saveMessage}
      <p>
        <button
          type="submit"
          onClick={handleFormSubmitted}
        >
          Save
        </button>
      </p>
    </form>
  )
}
