import type PostEditorState from './PostEditorState'
import type { PostMetadata } from './PostEditorState'
import type EmbeddedFileCollection from '../../services/posts/client/EmbeddedFileCollection'
import type { PageTemplate } from '../../services/posts/client/PostContent'

const INITIAL_VERSION = 1

export default function initialState(
  postMetadata: PostMetadata,
  pageTemplate: PageTemplate,
  embeddedFiles: EmbeddedFileCollection,
  imageUrlsById: Record<string, string>,
): PostEditorState {
  const { postId } = postMetadata

  const embeddedFileVersions: Record<string, number> = Object.keys(embeddedFiles).reduce<Record<string, number>>(
    (acc, fileId) => Object.assign(acc, { [fileId]: INITIAL_VERSION }),
    {},
  )

  return {
    postId,
    /**
     * Indicates when there may be unsaved local changes. Version numbers are only meaningful within a single editor
     * session. These version numbers are not sent to or used by the server.
     */
    localFilenameVersion: INITIAL_VERSION,
    localTemplateVersion: INITIAL_VERSION,
    localEmbeddedFileVersions: embeddedFileVersions,
    backendVersion: INITIAL_VERSION,

    postMetadata,
    pageTemplate,
    embeddedFiles,

    publishRequestedVersion: null,

    textSelectionCoords: null,
    imageUrlsById,

    showDebugPanel: false,
    showPublishSuccess: null,
    showSlashMenu: null,
    showFormattingMenu: null,
    showLinkMenu: null,
  }
}
