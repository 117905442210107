import type {
  FlowBlockNode,
  FlowCodeBlockElement,
  FlowCodeElement,
  FlowEmphasisElement,
  FlowFileBinding,
  FlowInlineNode,
  FlowLinkElement,
  FlowListElement,
  FlowListItemElement,
  FlowParagraphElement,
  FlowStrongElement,
  FlowTextNode,
} from '../../../services/posts/files/flow-document/FlowDocument'
import type FlowDocument from '../../../services/posts/files/flow-document/FlowDocument'
import type {
  JsonBlock,
  JsonBoldMark,
  JsonBulletList,
  JsonCodeBlock,
  JsonCodeMark,
  JsonFileBinding,
  JsonInlineMark,
  JsonItalicMark,
  JsonLinkMark,
  JsonListItem,
  JsonParagraph,
  JsonText,
  RichTextDocument,
} from '../rich-text/model'

function mergeInlineMarks(jsonInline: JsonText, newMarks: JsonInlineMark[]): JsonText {
  const { marks: prevMarks = [] } = jsonInline
  const mergedMarks = [...prevMarks, ...newMarks]

  return {
    ...jsonInline,
    marks: mergedMarks,
  }
}

function mapTextToJsonText(node: FlowTextNode): JsonText {
  const { text } = node

  return {
    type: 'text',
    text,
  }
}

function mapStrongTextToJsonText(node: FlowStrongElement): JsonText[] {
  const { content } = node

  const jsonText = content
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .flatMap(mapInlineContentToJsonInline)
    .map((jsonChild) => {
      const boldMark: JsonBoldMark = { type: 'bold' }

      return mergeInlineMarks(jsonChild, [boldMark])
    })

  return jsonText
}

function mapEmphasisTextToJsonText(node: FlowEmphasisElement): JsonText[] {
  const { content } = node

  const jsonText = content
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .flatMap(mapInlineContentToJsonInline)
    .map((jsonChild) => {
      const italicMark: JsonItalicMark = { type: 'italic' }

      return mergeInlineMarks(jsonChild, [italicMark])
    })

  return jsonText
}

function mapInlineCodeToJsonText(node: FlowCodeElement): JsonText[] {
  const { content } = node

  const jsonText = content
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .flatMap(mapInlineContentToJsonInline)
    .map((jsonChild) => {
      const codeMark: JsonCodeMark = { type: 'code' }

      return mergeInlineMarks(jsonChild, [codeMark])
    })

  return jsonText
}

function mapInlineHyperlinkToJsonText(hyperlink: FlowLinkElement): JsonText[] {
  const { href, content } = hyperlink

  const contentNodes = content
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .flatMap(mapInlineContentToJsonInline)
    .map((contentNode) => {
      const linkMark: JsonLinkMark = {
        type: 'link',
        attrs: { href },
      }

      return mergeInlineMarks(contentNode, [linkMark])
    })

  return contentNodes
}

function mapInlineContentToJsonInline(inlineContent: FlowInlineNode): JsonText[] {
  const { type } = inlineContent
  switch (type) {
    case 'text':
      return [mapTextToJsonText(inlineContent)]
    case 'strong':
      return mapStrongTextToJsonText(inlineContent)
    case 'emphasis':
      return mapEmphasisTextToJsonText(inlineContent)
    case 'inline-code':
      return mapInlineCodeToJsonText(inlineContent)
    case 'inline-hyperlink':
      return mapInlineHyperlinkToJsonText(inlineContent)
    default:
      throw new Error(`Unknown inline content type: ${type}`)
  }
}

function mapParagraphToJsonParagraph(paragraph: FlowParagraphElement): JsonParagraph {
  const { content } = paragraph
  const inlineContent = content.flatMap((inlineSpan) => mapInlineContentToJsonInline(inlineSpan))

  // TODO: Add support for block-level class names (eg, bullet points).

  return {
    type: 'paragraph',
    content: inlineContent,
  }
}

function mapCodeBlockToJsonCodeBlock(codeBlock: FlowCodeBlockElement): JsonCodeBlock {
  const { content: textNodes } = codeBlock

  const content = textNodes.map(mapTextToJsonText)

  return {
    type: 'codeBlock',
    attrs: {
      // Code tags are not currently supported.
      language: null,
    },
    content,
  }
}

function mapListItemToJsonListItem(listItem: FlowListItemElement): JsonListItem {
  const { content } = listItem
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const jsonBlocks = content.map((block) => mapBlockToJsonBlock(block))

  return {
    type: 'listItem',
    content: jsonBlocks,
  }
}

function mapListToJsonBulletList(list: FlowListElement): JsonBulletList {
  const { items } = list

  const jsonListItems = items.map((listItem) => mapListItemToJsonListItem(listItem))

  return {
    type: 'bulletList',
    content: jsonListItems,
  }
}

function mapFileBindingToJsonFileBinding(fileBinding: FlowFileBinding): JsonFileBinding {
  const { ref: fileRef } = fileBinding
  if (fileRef.refType !== 'embedded-file') {
    throw new Error(`Unknown file ref type: ${fileRef.refType}`)
  }

  const { fileId } = fileRef

  return {
    type: 'fileBinding',
    attrs: {
      fileId,
    },
  }
}

function mapBlockToJsonBlock(block: FlowBlockNode): JsonBlock {
  const { type } = block
  switch (type) {
    case 'paragraph':
      return mapParagraphToJsonParagraph(block)
    case 'code-block':
      return mapCodeBlockToJsonCodeBlock(block)
    case 'list':
      return mapListToJsonBulletList(block)
    case 'file-binding':
      return mapFileBindingToJsonFileBinding(block)
    case 'image':
      // Image blocks are a legacy concept that is no longer supported.
      // Posts server should be migrating all image blocks before sending flow docs to the client.
      throw new Error(`Unexpected image block.`)
    default:
      throw new Error(`Unknown block type: ${type}`)
  }
}

export default function mapFlowDocumentToRichTextJson(flowDocument: FlowDocument): RichTextDocument {
  const { content: blocks } = flowDocument

  const docContent = blocks.map((block) => mapBlockToJsonBlock(block))

  // There must be at least one paragraph
  if (docContent.length === 0) {
    const emptyParagraph: JsonParagraph = { type: 'paragraph', content: [] }
    docContent.push(emptyParagraph)
  }

  return {
    type: 'doc',
    content: docContent,
  }
}
