import type { Type, TypeOf } from 'io-ts'
import { array, intersection, literal, nullType, partial, recursion, string, type, union } from 'io-ts'

/**
 * The types in this file map the document model used by the Tiptap editor. This model must be updated when new Tiptap
 * extensions are installed.
 */

export type JsonBoldMark = TypeOf<typeof JsonBoldMark>
export type JsonCodeMark = TypeOf<typeof JsonCodeMark>
export type JsonItalicMark = TypeOf<typeof JsonItalicMark>
export type JsonLinkMark = TypeOf<typeof JsonLinkMark>
export type JsonInlineMark = TypeOf<typeof JsonInlineMark>

export type JsonText = TypeOf<typeof JsonText>

export type JsonParagraph = TypeOf<typeof JsonParagraph>
export type JsonCodeBlock = TypeOf<typeof JsonCodeBlock>

export interface JsonListItem {
  type: 'listItem'
  content: JsonBlock[]
}

export interface JsonBulletList {
  type: 'bulletList'
  content: JsonListItem[]
}

export interface JsonFileBinding {
  type: 'fileBinding'
  attrs: {
    fileId: string
  }
}

export type JsonBlock = JsonParagraph | JsonCodeBlock | JsonBulletList | JsonFileBinding

export type RichTextDocument = TypeOf<typeof RichTextDocument>

export const JsonBoldMark = type({
  type: literal('bold'),
})

export const JsonCodeMark = type({
  type: literal('code'),
})

export const JsonItalicMark = type({
  type: literal('italic'),
})

export const JsonLinkMark = type({
  type: literal('link'),
  attrs: type({
    href: string,
  }),
})

export const JsonInlineMark = union([JsonBoldMark, JsonCodeMark, JsonItalicMark, JsonLinkMark])

export const JsonText = intersection([
  type({
    type: literal('text'),
    text: string,
  }),
  partial({
    marks: array(JsonInlineMark),
  }),
])

export const JsonParagraph = intersection([
  type({
    type: literal('paragraph'),
  }),
  partial({
    content: array(JsonText),
  }),
])

export const JsonCodeBlock = intersection([
  type({
    type: literal('codeBlock'),
    attrs: type({
      language: union([string, nullType]),
    }),
  }),
  partial({
    content: array(JsonText),
  }),
])

export const JsonListItem: Type<JsonListItem> = recursion('JsonListItem', () =>
  type({
    type: literal('listItem'),
    content: array(JsonBlock),
  }),
)

export const JsonBulletList: Type<JsonBulletList> = recursion('JsonBulletList', () =>
  type({
    type: literal('bulletList'),
    content: array(JsonListItem),
  }),
)

export const JsonFileBinding: Type<JsonFileBinding> = recursion('JsonFileBinding', () =>
  type({
    type: literal('fileBinding'),
    attrs: type({
      fileId: string,
    }),
  }),
)

export const JsonBlock: Type<JsonBlock> = recursion('JsonBlock', () =>
  union([JsonParagraph, JsonCodeBlock, JsonBulletList, JsonFileBinding]),
)

export const RichTextDocument = type({
  type: literal('doc'),
  content: array(JsonBlock),
})
