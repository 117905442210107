import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type SelectionAction from './SelectionAction'

type SelectionDispatch = Dispatch<SelectionAction>

export const SelectionDispatchContext = createContext<SelectionDispatch | undefined>(undefined)

export default function useSelectionDispatch(): SelectionDispatch {
  const result = useContext(SelectionDispatchContext)
  if (result === undefined) {
    throw new Error(`SelectionDispatchContext is unexpectedly empty`)
  }

  return result
}
