import EmbeddedFile from '../../services/posts/files/EmbeddedFile'
import type EmbeddedFileJson from '../../services/posts/files/EmbeddedFileJson'

const MEDIA_TYPE_EMBEDDED_FILE_JSON = 'application/vnd.computomatic.embedded-file.v1+json'

export function hasEmbeddedFileJson(dataTransfer: DataTransfer): boolean {
  return dataTransfer.types.includes(MEDIA_TYPE_EMBEDDED_FILE_JSON)
}

export function getEmbeddedFile(dataTransfer: DataTransfer): EmbeddedFile {
  const itemData = dataTransfer.getData(MEDIA_TYPE_EMBEDDED_FILE_JSON)
  if (itemData === '') {
    throw new Error(`Did not find any embedded files in transfer data.`)
  }

  // TODO: Verify data schema with io-ts
  const parsedJson = JSON.parse(itemData) as EmbeddedFileJson

  return EmbeddedFile.fromJson(parsedJson)
}

export function setEmbeddedFileJson(dataTransfer: DataTransfer, fileJson: EmbeddedFileJson): void {
  const itemData = JSON.stringify(fileJson)
  dataTransfer.setData(MEDIA_TYPE_EMBEDDED_FILE_JSON, itemData)
}
