import type { AriaAttributes } from 'react'
import React from 'react'

import styles from './BlockMenu.module.scss'

interface ItemProps extends Pick<AriaAttributes, 'aria-label'> {
  label: string
  onClick: () => void
}

function MenuItem({ label, 'aria-label': ariaLabel, onClick }: ItemProps): React.ReactElement {
  return (
    <button
      className={styles.MenuItem}
      type="button"
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

interface Props {
  enableFrames: boolean
  onClickInsertImage: () => void
  onClickInsertCanvas: () => void
}

export default function BlockMenu({
  enableFrames,
  onClickInsertImage,
  onClickInsertCanvas,
}: Props): React.ReactElement {
  const frameButton = enableFrames ? (
    <MenuItem
      label="+ Frame"
      aria-label="insert frame"
      onClick={onClickInsertCanvas}
    />
  ) : null

  return (
    <ul
      className={styles.BlockMenu}
      aria-label="block menu"
    >
      <MenuItem
        label="+ Image"
        aria-label="insert image"
        onClick={onClickInsertImage}
      />
      {frameButton}
    </ul>
  )
}
