import React from 'react'

import { CreateEmbeddedFileContext, type EmbeddedFileCreateHandler } from './useCreateEmbeddedFile'
import { EmbeddedFilesContext } from './useEmbeddedFile'
import { type EmbeddedFileUpdateHandler, UpdateEmbeddedFileContext } from './useUpdateEmbeddedFile'
import type EmbeddedFileCollection from '../../services/posts/client/EmbeddedFileCollection'

interface Props {
  embeddedFiles: EmbeddedFileCollection
  onCreateFile: EmbeddedFileCreateHandler
  onUpdateFile: EmbeddedFileUpdateHandler
  children: React.ReactNode
}

export default function EmbeddedFileProvider({
  embeddedFiles,
  onUpdateFile,
  onCreateFile,
  children,
}: Props): React.ReactElement {
  return (
    <EmbeddedFilesContext.Provider value={embeddedFiles}>
      <CreateEmbeddedFileContext.Provider value={onCreateFile}>
        <UpdateEmbeddedFileContext.Provider value={onUpdateFile}>{children}</UpdateEmbeddedFileContext.Provider>
      </CreateEmbeddedFileContext.Provider>
    </EmbeddedFilesContext.Provider>
  )
}
