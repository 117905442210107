import { useId } from 'react'

import { getChildIdFromKey, isNodeSelected } from '../selectors'
import useSelectionNodeId from '../useSelectionNode'
import useSelectionState from '../useSelectionState'

interface UseSelectableChildResult {
  childKey: string
  childNodeId: string | undefined
  isSelected: boolean
}

export default function useSelectableChild(): UseSelectableChildResult {
  const state = useSelectionState()
  const { nodeId: parentId } = useSelectionNodeId()

  const childKey = `${parentId}#${useId()}`

  const childNodeId = getChildIdFromKey(state, childKey)

  const isSelected = childNodeId !== undefined && isNodeSelected(state, childNodeId)

  return { childKey, childNodeId, isSelected }
}
