import React from 'react'

import styles from './CenteredLayout.module.scss'

interface Props {
  children: React.ReactNode
}

export default function CenteredLayout({ children }: Props): React.ReactElement {
  return (
    <div className={styles.CenteredLayout}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
