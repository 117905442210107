import React, { useState } from 'react'
import type { LoaderFunction } from 'react-router-dom'
import { json, useLoaderData } from 'react-router-dom'

import type { RequestContext } from '@computomatic/service-types'

import KvDbEditor, { type SelectedDatabase } from '../../../kvdb-editor/KvDbEditor'
import type KvDbEditorBackendClient from '../../../kvdb-editor-backend/KvDbEditorBackendClient'
import { type KvDatabaseMetadata } from '../../../kvdb-editor-backend/KvDbEditorBackendClient'
import { useKvDbEditorBackend } from '../../services'

interface LoaderData {
  ctx: RequestContext
  databases: KvDatabaseMetadata[]
  selectedDatabase: SelectedDatabase | undefined
}

export const kvDbEditorScreenDataLoader =
  (ctx: RequestContext, editorBackend: KvDbEditorBackendClient): LoaderFunction =>
  async ({ params }) => {
    const { databaseId: selectedDatabaseId } = params

    const { databases } = await editorBackend.findDatabases(ctx, {})

    const data: LoaderData = {
      ctx,
      databases,
      selectedDatabase: undefined,
    }

    if (selectedDatabaseId !== undefined) {
      const selectedDatabase = databases.find((database) => database.databaseId === selectedDatabaseId)
      if (selectedDatabase === undefined) {
        throw new Error(`Database is not available. (${selectedDatabaseId})`)
      }

      const { entries } = await editorBackend.findEntries(ctx, { databaseId: selectedDatabaseId })

      data.selectedDatabase = {
        databaseId: selectedDatabaseId,
        entries,
      }
    }

    return json<LoaderData>(data)
  }

export default function KvDbEditorScreen(): React.ReactElement {
  const { ctx, databases: initialDatabases, selectedDatabase: initialSelectionData } = useLoaderData() as LoaderData
  const editorBackend = useKvDbEditorBackend()

  const [databases, setDatabases] = useState(initialDatabases)
  const [selectedDatabase, setSelectedDatabase] = useState(initialSelectionData)

  const handleCreateClicked = (): void => {
    const asyncCreate = async (): Promise<void> => {
      await editorBackend.createDatabase(ctx, {})

      // Fetch updated list of databases
      const { databases: updatedDatabases } = await editorBackend.findDatabases(ctx, {})
      setDatabases(updatedDatabases)
    }

    asyncCreate()
  }

  const handleAddValueClicked = (key: string, value: string): void => {
    const asyncSetValue = async (): Promise<void> => {
      if (selectedDatabase === undefined) {
        throw new Error(`Cannot set value when no database is selected.`)
      }
      const { databaseId } = selectedDatabase

      await editorBackend.setValue(ctx, { databaseId, key, type: 'String', value })

      // Fetch updated entries
      const { entries } = await editorBackend.findEntries(ctx, { databaseId })

      setSelectedDatabase({
        databaseId,
        entries,
      })
    }

    asyncSetValue()
  }

  return (
    <KvDbEditor
      databases={databases}
      selectedDatabase={selectedDatabase}
      onClickCreate={handleCreateClicked}
      onClickAddValue={handleAddValueClicked}
    />
  )
}
