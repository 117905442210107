import React from 'react'

import usePostEditorDispatch from './state/usePostEditorDispatch'
import SuccessAnimation from './ui/SuccessAnimation'
import ModalDialog from '../ui/overlays/ModalDialog'

import styles from './PublishSuccessModal.module.scss'

interface PublishSuccessModalProps {
  href: string
  prettyUrl: string
}

export default function PublishSuccessModal({ href, prettyUrl }: PublishSuccessModalProps): React.ReactElement {
  const dispatch = usePostEditorDispatch()

  const closeModal = (): void => {
    dispatch({ type: 'editor/closePublishSuccessModal', payload: {} })
  }

  return (
    <ModalDialog>
      <div className={styles.PublishSuccessModal}>
        <button
          className={styles.close}
          type="button"
          onClick={closeModal}
        >
          Close
        </button>
        <div className={styles.successAnimation}>
          <SuccessAnimation />
        </div>
        <p className={styles.message}>Your post is now published!</p>
        <a
          className={styles.link}
          href={href}
          aria-label="Published URL"
          target="_blank"
          rel="noreferrer"
        >
          {prettyUrl}
        </a>
      </div>
    </ModalDialog>
  )
}
