import React from 'react'

import CitrusDropletLarge from './images/CitrusDroplet-large.svg'
import CitrusDropletSmall from './images/CitrusDroplet-small.svg'
import CitrusDropletXSmall from './images/CitrusDroplet-xsmall.svg' // cSpell:words xsmall
import CitrusSplash01 from './images/CitrusSplash01.svg'
import CitrusWedgeDripping from './images/CitrusWedge-dripping.png'
import CitrusWedgeTwist from './images/CitrusWedge-twist.png'

import styles from './CitrusSplashWallpaper.module.scss'

function CitrusSplashSet(): React.ReactElement {
  return (
    <div className={styles.CitrusSplashSet}>
      <CitrusSplash01 className={styles['CitrusSplashSet--splash']} />
      <CitrusDropletXSmall className={styles['CitrusSplashSet--dropletXSmall']} />
      <CitrusDropletSmall className={styles['CitrusSplashSet--dropletSmall']} />
      <CitrusDropletLarge className={styles['CitrusSplashSet--dropletLarge']} />
      <img
        className={styles['CitrusSplashSet--wedge']}
        src={CitrusWedgeTwist}
        alt="decorative lemon wedge illustration"
      />
    </div>
  )
}

function Background(): React.ReactElement {
  return (
    <div className={styles.Background}>
      <div className={styles['Background--citrusSplashSet']}>
        <CitrusSplashSet />
      </div>
      <img
        className={styles['Background--drippingWedge']}
        src={CitrusWedgeDripping}
        alt="illustration of a lemon wedge dripping with citrus juice"
      />
      <CitrusSplash01 className={styles['Background--splash']} />
      <CitrusDropletXSmall className={styles['Background--dropletXSmall']} />
      <CitrusDropletSmall className={styles['Background--dropletSmall']} />
      <CitrusDropletLarge className={styles['Background--dropletLarge']} />

      <img
        className={styles['Background--splashingWedge']}
        src={CitrusWedgeDripping}
        alt="illustration of a lemon wedge dripping with citrus juice"
      />
    </div>
  )
}

interface Props {
  children: React.ReactNode
}

export default function CitrusSplashWallpaper({ children }: Props): React.ReactElement {
  return (
    <div className={styles.CitrusSplashWallpaper}>
      <Background />
      <div className={styles.content}>{children}</div>
    </div>
  )
}
