import type ChannelEndpoint from './ChannelEndpoint'
import type ProxyRequest from './ProxyRequest'

/**
 * Builds a proxy for the given target object. All method calls on the returned instance will be converted to ProxyRequests and passed to the given handler.
 * @param targetInterface The interface to imitate.
 * @param receiver The request handler to receive requests.
 * @returns An instance of T which proxies all method invocations.
 */
export default function buildProxy<T extends object>(targetInterface: T, receiver: ChannelEndpoint): T {
  const proxyHandler = {
    // Methods on a class are just properties with function values. Therefor, we can intercept the gets to swap the values.
    get: (_target: T, p: string | symbol) => {
      const methodName = String(p)

      /**
       * Note: this forces every API method to be async. This is generally true anyway so seems reasonable.
       */
      return async (...args: unknown[]): Promise<unknown> => {
        const request: ProxyRequest = {
          methodName,
          args,
        }

        const response = await receiver.invoke(request)

        const { status } = response
        switch (status) {
          case 'SUCCESS': {
            const { result } = response

            return result
          }
          case 'ERROR': {
            // Convert any errors back to actual exceptions.
            const { message } = response
            throw new Error(message)
          }
          default:
            throw new Error(`Unknown response status from proxy receiver: ${status}`)
        }
      }
    },
  }

  return new Proxy(targetInterface, proxyHandler)
}
