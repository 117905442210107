import type { AriaAttributes } from 'react'
import React from 'react'

import InlineTextEditor from './inline/InlineTextEditor'
import type EmbeddedFile from '../../services/posts/files/EmbeddedFile'
import InlineTextFileBuilder from '../../services/posts/files/inline-text/InlineTextFileBuilder'
import InlineTextFileReader from '../../services/posts/files/inline-text/InlineTextFileReader'

interface Props extends Pick<AriaAttributes, 'aria-label'> {
  file: EmbeddedFile
  onChange: (updatedFile: EmbeddedFile) => void
  onFocus: () => void
  onBlur: () => void
}

export default function InlineTextBindingField({
  file,
  'aria-label': ariaLabel,
  onChange,
  onFocus,
  onBlur,
}: Props): React.ReactElement {
  const { text } = InlineTextFileReader.openFile(file)
  const initialStringValue = text

  const handleChanged = (newValue: string): void => {
    const updatedFile = new InlineTextFileBuilder(newValue).build()

    onChange(updatedFile)
  }

  return (
    <InlineTextEditor
      defaultValue={initialStringValue}
      aria-label={ariaLabel}
      onChange={handleChanged}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}
