import { createContext, useContext } from 'react'

import type DragDropState from './DragDropState'

export const DragDropStateContext = createContext<DragDropState | undefined>(undefined)

export default function useDragDropState(): DragDropState {
  const result = useContext(DragDropStateContext)
  if (result === undefined) {
    throw new Error(`DragDropStateContext is unexpectedly empty`)
  }

  return result
}
