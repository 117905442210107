import { isClientEnv, readClientEnv } from './client/env'
import { isServerEnv, readServerEnv } from './server/env'

export function readEnv(key: string): string | undefined {
  if (isServerEnv()) {
    return readServerEnv(key)
  }
  if (isClientEnv()) {
    return readClientEnv(key)
  }

  throw new Error(`Unable to determine current environment. Reading: ${key}`)
}

/**
 * Eagerly reads all expected environment variables to ensure misconfigurations are caught as early as possible.
 *
 * @param name The environment variable to read
 * @param allowEmpty Providing true will allow empty strings (eg, "MY_VAR="). Providing false (default) will throw an Error when values are empty.
 */
export function expectEnv(name: string, allowEmpty = false): string {
  const value = readEnv(name)
  if (value === undefined) {
    throw new Error(`Required variable not present: ${name}`)
  }

  if (value === '' && !allowEmpty) {
    throw new Error(`Required variable is empty: ${name}`)
  }

  return value
}

function isNodeEnv(env: string): boolean {
  return isServerEnv() && expectEnv('NODE_ENV') === env
}

export function isDev(): boolean {
  return isNodeEnv('development')
}
