import type { Draft } from 'immer'
import { produce } from 'immer'

import type PostEditorAction from './PostEditorAction'
import {
  type ClosePublishSuccessModal,
  type CompletePublish,
  type HideFormattingMenu,
  type HideLinkMenu,
  type HideSlashMenu,
  type RequestPublish,
  type SavePostImageUrl,
  type SetDebugMenuVisible,
  type ShowFormattingMenu,
  type ShowLinkMenu,
  type ShowPublishSuccessModal,
  type ShowSlashMenu,
  type UpdateBackendVersion,
  type UpdateEmbeddedFile,
  type UpdatePageTemplate,
  type UpdatePostFilename,
  type UpdateTextFieldSelectionBounds,
} from './PostEditorAction'
import type PostEditorState from './PostEditorState'

type ActionHandler<A extends PostEditorAction> = (state: PostEditorState, action: A) => PostEditorState

const immerHandler =
  <A extends PostEditorAction>(recipe: (draft: Draft<PostEditorState>, action: A) => void): ActionHandler<A> =>
  (state, action) =>
    produce(state, (draft) => recipe(draft, action))

function currentLocalVersion(state: PostEditorState): number {
  const { localFilenameVersion, localTemplateVersion, localEmbeddedFileVersions } = state

  const maxEmbeddedFileVersion = Object.values(localEmbeddedFileVersions).reduce(
    (acc, version) => Math.max(acc, version),
    Number.NEGATIVE_INFINITY,
  )

  return Math.max(localFilenameVersion, localTemplateVersion, maxEmbeddedFileVersion)
}

const updatePostFilename = immerHandler<UpdatePostFilename>((draft, { payload }) => {
  const { filename } = payload

  draft.postMetadata.filename = filename

  draft.localFilenameVersion = currentLocalVersion(draft) + 1
})

const updatePageTemplate = immerHandler<UpdatePageTemplate>((draft, { payload }) => {
  const { pageTemplate } = payload

  draft.pageTemplate = pageTemplate

  draft.localTemplateVersion = currentLocalVersion(draft) + 1
})

const updateEmbeddedFile = immerHandler<UpdateEmbeddedFile>((draft, { payload }) => {
  const { fileId, file } = payload

  draft.embeddedFiles[fileId] = file.toJson()

  draft.localEmbeddedFileVersions[fileId] = currentLocalVersion(draft) + 1
})

const updateBackendVersion = immerHandler<UpdateBackendVersion>((draft, { payload }) => {
  const { backendVersion } = payload

  draft.backendVersion = Math.max(backendVersion, draft.backendVersion)
})

const requestPublish = immerHandler<RequestPublish>((draft) => {
  const currentVersion = currentLocalVersion(draft)

  draft.publishRequestedVersion = currentVersion
})

const completePublish = immerHandler<CompletePublish>((draft, { payload }) => {
  const { publishedVersion, pageUrl } = payload
  const { publishRequestedVersion } = draft

  if (publishRequestedVersion !== null && publishRequestedVersion <= publishedVersion) {
    // Clear the request flag if the most recently requested version has now been published.
    draft.publishRequestedVersion = null
  }

  draft.postMetadata.publishedPageUrl = pageUrl
  draft.showPublishSuccess = { pageUrl }
})

const updateTextFieldSelectionBounds = immerHandler<UpdateTextFieldSelectionBounds>((draft, { payload }) => {
  const { selectionCoordinates } = payload

  draft.textSelectionCoords = selectionCoordinates
})

const setDebugMenuVisible = immerHandler<SetDebugMenuVisible>((draft, { payload }) => {
  const { visible } = payload
  draft.showDebugPanel = visible
})

const showSlashMenu = immerHandler<ShowSlashMenu>((draft, { payload }) => {
  const { fieldId } = payload
  draft.showSlashMenu = { fieldId }
})

const hideSlashMenu = immerHandler<HideSlashMenu>((draft) => {
  draft.showSlashMenu = null
})

const showFormattingMenu = immerHandler<ShowFormattingMenu>((draft, { payload }) => {
  const { fieldId } = payload
  draft.showFormattingMenu = { fieldId }
})

const hideFormattingMenu = immerHandler<HideFormattingMenu>((draft) => {
  draft.showFormattingMenu = null
})

const showLinkMenu = immerHandler<ShowLinkMenu>((draft, { payload }) => {
  const { fieldId } = payload
  draft.showLinkMenu = { fieldId }
})

const hideLinkMenu = immerHandler<HideLinkMenu>((draft) => {
  draft.showLinkMenu = null
})

const showPublishSuccessModal = immerHandler<ShowPublishSuccessModal>((draft, { payload }) => {
  const { pageUrl } = payload
  draft.showPublishSuccess = { pageUrl }
})

const closePublishSuccessModal = immerHandler<ClosePublishSuccessModal>((draft) => {
  draft.showPublishSuccess = null
})

const savePostImageUrl = immerHandler<SavePostImageUrl>((draft, { payload }) => {
  const { imageId, getUrl } = payload
  draft.imageUrlsById[imageId] = getUrl
})

export default function applyAction(state: PostEditorState, action: PostEditorAction): PostEditorState {
  const { type } = action
  switch (type) {
    case 'editor/updatePostFilename':
      return updatePostFilename(state, action)
    case 'editor/updatePageTemplate':
      return updatePageTemplate(state, action)
    case 'editor/updateEmbeddedFile':
      return updateEmbeddedFile(state, action)
    case 'editor/updateBackendVersion':
      return updateBackendVersion(state, action)
    case 'editor/requestPublish':
      return requestPublish(state, action)
    case 'editor/completePublish':
      return completePublish(state, action)
    case 'editor/updateTextFieldSelectionBounds':
      return updateTextFieldSelectionBounds(state, action)
    case 'editor/setDebugMenuVisible':
      return setDebugMenuVisible(state, action)
    case 'editor/showSlashMenu':
      return showSlashMenu(state, action)
    case 'editor/hideSlashMenu':
      return hideSlashMenu(state, action)
    case 'editor/showFormattingMenu':
      return showFormattingMenu(state, action)
    case 'editor/hideFormattingMenu':
      return hideFormattingMenu(state, action)
    case 'editor/showLinkMenu':
      return showLinkMenu(state, action)
    case 'editor/hideLinkMenu':
      return hideLinkMenu(state, action)
    case 'editor/showPublishSuccessModal':
      return showPublishSuccessModal(state, action)
    case 'editor/closePublishSuccessModal':
      return closePublishSuccessModal(state, action)
    case 'editor/savePostImageUrl':
      return savePostImageUrl(state, action)
    default:
      // Traditionally, a reducer returns the unmodified state when an unknown action is encountered. In practice,
      // however, unhandled actions tend to be the source of bugs more often than they are intended.
      throw new Error(`Unknown PostEditorAction type: ${type}`)
  }
}
