import type { AxiosInstance } from 'axios'
import axios from 'axios'

import type ChannelEndpoint from './ChannelEndpoint'
import type ProxyRequest from './ProxyRequest'
import type ProxyResponse from './ProxyResponse'
import buildProxy from './buildProxy'

export default class ProxyClient implements ChannelEndpoint {
  private readonly axios: AxiosInstance
  private readonly serviceEndpoint: string

  constructor(serviceEndpoint: string) {
    this.axios = axios.create({
      url: serviceEndpoint,
    })
    this.serviceEndpoint = serviceEndpoint
  }

  async invoke(req: ProxyRequest): Promise<ProxyResponse> {
    const resp = await this.axios.request<ProxyResponse>({
      method: 'POST',
      url: this.serviceEndpoint,
      data: req,
    })

    return resp.data
  }

  static create<T extends object>(serviceEndpoint: string): T {
    const receiver = new ProxyClient(serviceEndpoint)

    return buildProxy({} as T, receiver)
  }
}
