import type { PageNode } from '@computomatic/pages'

import type CssProperties from '../../../posts/client/CssProperties'
import EmbeddedFile from '../../../posts/files/EmbeddedFile'
import FrameFileReader from '../../../posts/files/frame/FrameFileReader'
import type { FrameElement, FrameState } from '../../../posts/files/frame/FrameState'
import { selectFrameElements, selectFrameHeight } from '../../../posts/files/frame/selectors'
import type Stylesheet from '../../client/Stylesheet'
import resolveStyle from '../../server/planning/page-content/resolveStyle'
import type MappingContext from '../MappingContext'
import type PublishingPlugin from '../PublishingPlugin'

export const FRAME_STYLE_CLASS = 'frame'
export const FRAME_BLOCK_STYLE_CLASS = 'frame__block'

const FRAME_STYLESHEET: Stylesheet = {
  [FRAME_STYLE_CLASS]: {
    // Box
    position: 'relative',
    width: '100%',

    // Style
    backgroundColor: '#ffffff',
  },
  [FRAME_BLOCK_STYLE_CLASS]: {
    // Box
    position: 'absolute',
  },
}

export function dynamicFrameStyle(height: number): CssProperties {
  return {
    // Box
    height: `${height}px`,
  }
}

export function dynamicFrameBlockStyle(x: number, y: number): CssProperties {
  return {
    // Box
    top: `${y}px`,
    left: `${x}px`,
  }
}

function mapFrameElementToPageNode(context: MappingContext, element: FrameElement): PageNode {
  const { publishingPlugins, embeddedFiles, stylesheet } = context
  if (element.type !== 'block') {
    throw new Error(`Unknown element type: ${element.type}`)
  }
  const { x, y } = element

  if (element.blockType !== 'file-binding') {
    throw new Error(`Unknown block type: ${element.blockType}`)
  }

  const dynamicStyle = dynamicFrameBlockStyle(x, y)
  const blockStyle = resolveStyle(stylesheet, [FRAME_BLOCK_STYLE_CLASS], dynamicStyle)

  if (element.fileRefType !== 'embedded-file') {
    throw new Error(`Unknown file ref type: ${element.fileRefType}`)
  }
  const { fileId } = element

  const fileJson = embeddedFiles[fileId]
  if (fileJson === undefined) {
    // TODO: Support async file loading
    throw new Error(`File not found: ${fileId}`)
  }

  const file = EmbeddedFile.fromJson(fileJson)
  const filePlugin = publishingPlugins.requirePlugin(file.mediaType)
  const fileNode = filePlugin.mapToPageNode(context, file)

  return {
    type: 'dom-element',
    tag: 'div',
    style: blockStyle,
    attributes: {},
    children: [fileNode],
  }
}

function mapFrameToPageNode(context: MappingContext, frame: FrameState): PageNode {
  const { stylesheet } = context

  const height = selectFrameHeight(frame)
  const dynamicStyle = dynamicFrameStyle(height)
  const frameStyle = resolveStyle(stylesheet, [FRAME_STYLE_CLASS], dynamicStyle)

  const children = selectFrameElements(frame).map((element) => mapFrameElementToPageNode(context, element))

  return {
    type: 'dom-element',
    tag: 'div',
    style: frameStyle,
    attributes: {},
    children,
  }
}

export default {
  stylesheet: FRAME_STYLESHEET,

  mapToPageNode(context, file) {
    const { state } = FrameFileReader.openFile(file)

    return mapFrameToPageNode(context, state)
  },
} as const satisfies PublishingPlugin
