import { StrictMode } from 'react'

import type AppRenderContext from './AppRenderContext'
import Layout from './Layout'
import {
  ActionDemoControllerContext,
  KvDbEditorBackendContext,
  PostEditorBackendContext,
  PublishingPluginsContext,
  RequestContextContext,
  WorkspaceControllerContext,
} from './services'

interface Props {
  renderContext: AppRenderContext
  children: React.ReactNode
}

export default function App({ renderContext, children }: Props): React.ReactElement {
  const { ctx, workspaceController, kvDbEditorBackend, postEditorBackend, actionDemoController, publishingPlugins } =
    renderContext

  return (
    <StrictMode>
      <PublishingPluginsContext.Provider value={publishingPlugins}>
        <PostEditorBackendContext.Provider value={postEditorBackend}>
          <KvDbEditorBackendContext.Provider value={kvDbEditorBackend}>
            <ActionDemoControllerContext.Provider value={actionDemoController}>
              <WorkspaceControllerContext.Provider value={workspaceController}>
                <RequestContextContext.Provider value={ctx}>
                  <Layout>{children}</Layout>
                </RequestContextContext.Provider>
              </WorkspaceControllerContext.Provider>
            </ActionDemoControllerContext.Provider>
          </KvDbEditorBackendContext.Provider>
        </PostEditorBackendContext.Provider>
      </PublishingPluginsContext.Provider>
    </StrictMode>
  )
}
