const FlowDocumentClassNames = {
  /* Inline elements */
  Code: 'code',
  Emphasis: 'emphasis',
  Hyperlink: 'hyperlink',
  Strong: 'strong',

  /* Block elements */
  CodeBlock: 'code-block',
  FileBinding: 'file-binding',
  ListItem: 'list-item',
  List: 'list',
} as const

export default FlowDocumentClassNames
