import { createDraft, produce } from 'immer'

import type { AppendFrameElement, FrameEditorAction, RemoveFrameElement } from './FrameEditorAction'
import type FrameEditorState from './FrameEditorState'
import { appendElement, deleteElement } from '../../../../services/posts/files/frame/transforms'

function applyAppendFrameElement(state: FrameEditorState, { payload }: AppendFrameElement): FrameEditorState {
  const { element } = payload
  const updatedFrame = appendElement(state.frameState, element)

  return produce(state, (draft) => {
    draft.frameState = createDraft(updatedFrame)
    draft.localFileVersion += 1
  })
}

function applyRemoveFrameElement(state: FrameEditorState, { payload }: RemoveFrameElement): FrameEditorState {
  const { nodeId } = payload

  const updatedFrame = deleteElement(state.frameState, nodeId)

  return produce(state, (draft) => {
    draft.frameState = createDraft(updatedFrame)
    draft.localFileVersion += 1
  })
}

export default function frameEditorReducer(state: FrameEditorState, action: FrameEditorAction): FrameEditorState {
  const { type } = action
  switch (type) {
    case 'frameEditor/appendFrameElement':
      return applyAppendFrameElement(state, action)
    case 'frameEditor/removeFrameElement':
      return applyRemoveFrameElement(state, action)
    default:
      throw new Error(`Unknown action type: ${type}`)
  }
}
