import Base64 from './Base64'
import type EmbeddedFileJson from './EmbeddedFileJson'

/**
 * An alternative to Blob that provides synchronous operations. Represents the content of a file.
 */
export default class EmbeddedFile {
  private constructor(
    public readonly mediaType: string,
    private readonly data: Uint8Array,
  ) {}

  /** The file size in bytes */
  get size(): number {
    return this.data.length
  }

  readAsText(): string {
    const decoder = new TextDecoder('utf-8')

    return decoder.decode(this.data, { stream: false })
  }

  readAsBytes(): Uint8Array {
    return Uint8Array.from(this.data)
  }

  readAsBase64(): string {
    return Base64.encode(this.data)
  }

  toJson(): EmbeddedFileJson {
    return {
      mediaType: this.mediaType,
      encoding: 'base64',
      encodedData: this.readAsBase64(),
    }
  }

  static fromText(mediaType: string, text: string): EmbeddedFile {
    const encoder = new TextEncoder()
    const bytes = encoder.encode(text)

    return new EmbeddedFile(mediaType, bytes)
  }

  static fromBytes(mediaType: string, bytes: Uint8Array): EmbeddedFile {
    const privateCopy = Uint8Array.from(bytes)

    return new EmbeddedFile(mediaType, privateCopy)
  }

  static fromBase64(mediaType: string, encoded: string): EmbeddedFile {
    const bytes = Base64.decode(encoded)

    return new EmbeddedFile(mediaType, bytes)
  }

  static fromJson(fileJson: EmbeddedFileJson): EmbeddedFile {
    if (fileJson.encoding !== 'base64') {
      throw new Error(`Unexpected data encoding: ${fileJson.encoding}`)
    }

    const { mediaType, encodedData } = fileJson

    return EmbeddedFile.fromBase64(mediaType, encodedData)
  }
}
