import React, { useEffect, useMemo } from 'react'

import type { ClassNameMap } from './ClassNameMapContext'
import ClassNameMapContext from './ClassNameMapContext'
import installStyles from './installStyles'
import type CssProperties from '../../services/posts/client/CssProperties'
import type Stylesheet from '../../services/publishing/client/Stylesheet'

/**
 * Insecure, fast hashing of strings.
 *
 * Source: https://stackoverflow.com/a/52171480
 *
 * cSpell:words cyrb53
 */
const cyrb53 = (str: string, seed: number = 0): number => {
  /* eslint-disable no-bitwise */
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i += 1) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
  /* eslint-enable no-bitwise */
}

function hashStylesheet(stylesheet: Stylesheet): string {
  const input = JSON.stringify(stylesheet)

  return cyrb53(input).toString(16)
}

function buildCssClassNameMap(stylesheet: Stylesheet): ClassNameMap {
  const nonce = hashStylesheet(stylesheet)

  return Object.keys(stylesheet).reduce<ClassNameMap>((acc, flowClassName) => {
    const uniqueClass = `editor-style__${flowClassName}--${nonce}`

    return Object.assign(acc, { [flowClassName]: uniqueClass })
  }, {})
}

interface Props {
  stylesheet: Stylesheet
  children: React.ReactNode
}

/**
 * Installs a CSS stylesheet with unique class names, mitigating risk of collisions.
 *
 * Mapped class names are available via useStylesheetClass().
 */
export default function StylesheetProvider({ stylesheet, children }: Props): React.ReactElement {
  const classNameMappings = useMemo(() => buildCssClassNameMap(stylesheet), [stylesheet])

  const mappedEntries = Object.entries(stylesheet).map<[string, CssProperties]>(([flowDocClass, cssProps]) => {
    const cssClassName = classNameMappings[flowDocClass]
    if (cssClassName === undefined) {
      throw new Error(`Did not find mapped CSS class name for flow doc class: ${flowDocClass}`)
    }

    return [cssClassName, cssProps]
  })

  const mappedStylesheet = Object.fromEntries<CssProperties>(mappedEntries)

  useEffect(() => installStyles(mappedStylesheet), [mappedStylesheet])

  return <ClassNameMapContext.Provider value={classNameMappings}>{children}</ClassNameMapContext.Provider>
}
