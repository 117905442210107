import React from 'react'

import type EmbeddedFile from '../../../services/posts/files/EmbeddedFile'
import PostImageFileReader from '../../../services/posts/files/post-image/PostImageFileReader'
import {
  dynamicPostImageStyle,
  IMAGE_STYLE_CLASS,
} from '../../../services/publishing/plugins/post-image/PostImagePublishingPlugin'
import useGetImageUrl from '../../images/useGetImageUrl'
import useStylesheetClass from '../../stylesheets/useStylesheetClass'

interface Props {
  file: EmbeddedFile
}

export default function PostImageEditor({ file }: Props): React.ReactElement {
  const getImageUrl = useGetImageUrl()

  const { postImageId, width } = PostImageFileReader.openFile(file)

  const imageUrl = getImageUrl(postImageId)

  const dynamicStyle = dynamicPostImageStyle(width)
  const cssClassName = useStylesheetClass(IMAGE_STYLE_CLASS)

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        className={cssClassName}
        style={dynamicStyle}
        src={imageUrl}
      />
    </>
  )
}
