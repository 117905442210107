import React from 'react'

import classNames from 'classnames'

import styles from './Button.module.scss'

interface Props {
  label: string
  variant: 'primary' | 'secondary'
  submitButton?: boolean
  disabled?: boolean
  ariaLabel?: string
  href?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function Button({
  label,
  variant,
  submitButton = false,
  disabled = false,
  ariaLabel = label,
  href,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props): React.ReactElement {
  const className = classNames(styles.Button, styles[variant])
  if (href && !disabled) {
    return (
      <a
        className={className}
        href={href}
        aria-label={ariaLabel}
        onClick={onClick}
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
      >
        {label}
      </a>
    )
  }

  return (
    <button
      type={submitButton ? 'submit' : 'button'}
      className={className}
      disabled={disabled}
      aria-label={ariaLabel}
      onClick={onClick}
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
    >
      {label}
    </button>
  )
}
