import type InlineText from './InlineText'
import stringifyInlineText from './serialization/stringifyInlineText'
import EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class InlineTextFileBuilder {
  constructor(private text: string) {}

  build(): EmbeddedFile {
    const data: InlineText = {
      type: 'inline-text',
      text: this.text,
    }
    const textData = stringifyInlineText(data)

    return EmbeddedFile.fromText(MediaTypes.InlineText, textData)
  }
}
