import type { LoaderFunction } from 'react-router-dom'
import { json, useLoaderData } from 'react-router-dom'

import PostEditor from '../../post-editor/PostEditor'
import type { PostEditorBackendClient } from '../../post-editor-backend/client'
import type EmbeddedFileCollection from '../../services/posts/client/EmbeddedFileCollection'
import type { PageTemplate } from '../../services/posts/client/PostContent'

interface LoaderData {
  postId: string
  accountId: string
  filename: string
  urlPath: string | null
  publishedPageUrl: string | null
  pageTemplate: PageTemplate
  embeddedFiles: EmbeddedFileCollection
  imageUrlsById: Record<string, string>
}

export const postEditorScreenDataLoader =
  (postEditorBackend: PostEditorBackendClient): LoaderFunction =>
  async ({ params }) => {
    const { postId } = params
    if (postId === undefined) {
      throw new Error(`Path param 'postId' is missing.`)
    }

    const { accountId, filename, urlPath, publishedPageUrl, pageTemplate, embeddedFiles, imageUrlsById } =
      await postEditorBackend.getPost({
        postId,
      })

    return json<LoaderData>({
      postId,
      accountId,
      filename,
      urlPath,
      publishedPageUrl,
      pageTemplate,
      embeddedFiles,
      imageUrlsById,
    })
  }

export default function PostEditorScreen(): React.ReactElement {
  const { postId, filename, urlPath, publishedPageUrl, pageTemplate, embeddedFiles, imageUrlsById } =
    useLoaderData() as LoaderData

  const postMetadata = {
    postId,
    filename,
    urlPath: urlPath || undefined,
    publishedPageUrl: publishedPageUrl || undefined,
  }

  return (
    <PostEditor
      postMetadata={postMetadata}
      pageTemplate={pageTemplate}
      embeddedFiles={embeddedFiles}
      imageUrlsById={imageUrlsById}
    />
  )
}
