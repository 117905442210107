import classNames from 'classnames'

import ExternalLink from '@assets/icons/ExternalLink.svg'

import type { PublishedPostLink } from './Post'
import type Post from './Post'
import FileThumbnailIcon from './ui/FileThumbnailIcon.svg'

import styles from './PostTile.module.scss'

interface FilenameProps {
  href: string
  children: React.ReactNode
}

function Filename({ href, children }: FilenameProps): React.ReactElement {
  return (
    <p className={classNames(styles.Filename, styles.truncated)}>
      <a
        aria-label="edit post"
        href={href}
      >
        {children}
      </a>
    </p>
  )
}

interface PublishedUrlProps {
  published: PublishedPostLink | undefined
}

function PublishedUrl({ published }: PublishedUrlProps): React.ReactElement {
  if (published === undefined) {
    return <p className={classNames(styles.PublishedUrl, styles.unpublished)}>Draft</p>
  }

  return (
    // Disable `react/jsx-no-target-blank` because link target is trusted and it's helpful to track referrer sources in metrics.
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={classNames(styles.PublishedUrl, styles.link)}
      href={published.canonicalUrl}
      target="_blank"
    >
      <span className={styles.truncated}>{published.urlPath}</span>

      <ExternalLink className={styles.externalLinkIcon} />
    </a>
  )
}

interface Props {
  post: Post
}

export default function PostTile({ post }: Props): React.ReactElement {
  const { filename, editorUrl, publishedPost } = post

  return (
    <div className={styles.PostTile}>
      <a
        className={styles.thumbnail}
        href={editorUrl}
      >
        <FileThumbnailIcon className={styles.icon} />
      </a>
      <div className={styles.metadata}>
        <Filename href={editorUrl}>{filename}</Filename>
        <PublishedUrl published={publishedPost} />
      </div>
    </div>
  )
}
