import type { TypeOf } from 'io-ts'

import { PostImage20240611 } from './2024-06-11/PostImage20240611'
import stringify from '../../serialization/stringify'

const CURRENT_SCHEMA = PostImage20240611
type PostImage = TypeOf<typeof CURRENT_SCHEMA>

export default function serializePostImage(postImage: PostImage): Uint8Array {
  const textData = stringify<PostImage>(CURRENT_SCHEMA, postImage)

  const textEncoder = new TextEncoder()

  return textEncoder.encode(textData)
}
