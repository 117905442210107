import type PublishingPlugin from './PublishingPlugin'
import type Stylesheet from '../client/Stylesheet'

export default class PublishingPluginRepository {
  public readonly stylesheet: Stylesheet

  constructor(private readonly pluginsByMediaType: Record<string, PublishingPlugin>) {
    this.stylesheet = Object.values(pluginsByMediaType) //
      .reduce<Stylesheet>((acc, { stylesheet }) => ({ ...acc, ...stylesheet }), {})
  }

  requirePlugin(mediaType: string): PublishingPlugin {
    const plugin = this.pluginsByMediaType[mediaType]
    if (plugin === undefined) {
      throw new Error(`No publishing plugin is registered for media type: ${mediaType}`)
    }

    return plugin
  }
}
