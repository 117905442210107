import styles from './ModalDialog.module.scss'

interface Props {
  children: React.ReactElement
}

export default function ModalDialog({ children }: Props): React.ReactElement {
  return (
    <div className={styles.ModalDialogContainer}>
      <div className={styles.ModalDialog}>{children}</div>
    </div>
  )
}
