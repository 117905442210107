import type { PageNode } from '@computomatic/pages'

import FlowDocumentClassNames from './FlowDocumentClassNames'
import type CssProperties from '../../../posts/client/CssProperties'
import EmbeddedFile from '../../../posts/files/EmbeddedFile'
import type {
  FlowBlockNode,
  FlowCodeBlockElement,
  FlowCodeElement,
  FlowEmphasisElement,
  FlowFileBinding,
  FlowInlineNode,
  FlowLinkElement,
  FlowListElement,
  FlowListItemElement,
  FlowParagraphElement,
  FlowStrongElement,
  FlowTextNode,
} from '../../../posts/files/flow-document/FlowDocument'
import type FlowDocument from '../../../posts/files/flow-document/FlowDocument'
import resolveStyle from '../../server/planning/page-content/resolveStyle'
import type MappingContext from '../MappingContext'

type DocumentNode = FlowInlineNode | FlowBlockNode | FlowListItemElement

// TODO: Migrate away from class-based styles for nodes. Introduce stylesheet rules based directly on node type.
function resolveClassName(node: DocumentNode): string | undefined {
  const { type: nodeType } = node

  switch (nodeType) {
    case 'text':
      return undefined
    case 'strong':
      return FlowDocumentClassNames.Strong
    case 'emphasis':
      return FlowDocumentClassNames.Emphasis
    case 'inline-code':
      return FlowDocumentClassNames.Code
    case 'inline-hyperlink':
      return FlowDocumentClassNames.Hyperlink
    case 'paragraph':
      // There's currently no style class defined for paragraphs
      return undefined
    case 'code-block':
      return FlowDocumentClassNames.CodeBlock
    case 'list':
      return FlowDocumentClassNames.List
    case 'list-item':
      return FlowDocumentClassNames.ListItem
    case 'file-binding':
      return FlowDocumentClassNames.FileBinding
    default:
      throw new Error(`Unknown node type: ${nodeType}`)
  }
}

function resolveNodeStyle({ stylesheet }: MappingContext, node: DocumentNode): CssProperties {
  const className = resolveClassName(node)
  if (className === undefined) {
    return {}
  }

  return resolveStyle(stylesheet, [className], undefined)
}

function mapTextToPageNode(node: FlowTextNode): PageNode {
  const { text: content } = node

  return {
    type: 'text',
    content,
  }
}

function mapStrongToPageNode(context: MappingContext, node: FlowStrongElement): PageNode {
  const { content } = node

  const style = resolveNodeStyle(context, node)

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const children = content.map((childNode) => mapInlineContentToPageNode(context, childNode))

  return {
    type: 'dom-element',
    tag: 'strong',
    style,
    attributes: {},
    children,
  }
}

function mapEmphasisToPageNode(context: MappingContext, node: FlowEmphasisElement): PageNode {
  const { content } = node

  const style = resolveNodeStyle(context, node)

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const children = content.map((childNode) => mapInlineContentToPageNode(context, childNode))

  return {
    type: 'dom-element',
    tag: 'em',
    style,
    attributes: {},
    children,
  }
}

function mapCodeToPageNode(context: MappingContext, node: FlowCodeElement): PageNode {
  const { content } = node

  const style = resolveNodeStyle(context, node)

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const children = content.map((childNode) => mapInlineContentToPageNode(context, childNode))

  return {
    type: 'dom-element',
    tag: 'code',
    style,
    attributes: {},
    children,
  }
}

function mapHyperlinkToPageNode(context: MappingContext, hyperlink: FlowLinkElement): PageNode {
  const { href, content } = hyperlink

  const nodeStyle = resolveNodeStyle(context, hyperlink)

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const children = content.map((childNode) => mapInlineContentToPageNode(context, childNode))

  return {
    type: 'dom-element',
    tag: 'a',
    style: nodeStyle,
    attributes: {
      href,
    },
    children,
  }
}

function mapInlineContentToPageNode(context: MappingContext, node: FlowInlineNode): PageNode {
  const { type } = node
  switch (type) {
    case 'text':
      return mapTextToPageNode(node)
    case 'strong':
      return mapStrongToPageNode(context, node)
    case 'emphasis':
      return mapEmphasisToPageNode(context, node)
    case 'inline-code':
      return mapCodeToPageNode(context, node)
    case 'inline-hyperlink':
      return mapHyperlinkToPageNode(context, node)
    default:
      throw new Error(`Unknown inline content type: ${type}`)
  }
}

function mapParagraphToPageNode(context: MappingContext, paragraph: FlowParagraphElement): PageNode {
  const { content } = paragraph

  const nodeStyle = resolveNodeStyle(context, paragraph)

  let children = content.map((text) => mapInlineContentToPageNode(context, text))

  // Insert content so that empty paragraphs do not collapse to zero-height at runtime.
  if (children.length === 0) {
    children = [
      {
        type: 'dom-element',
        tag: 'br',
        style: {},
        attributes: {},
        children: [],
      },
    ]
  }

  return {
    type: 'dom-element',
    tag: 'p',
    style: nodeStyle,
    attributes: {},
    children,
  }
}

function mapCodeBlockToPageNode(context: MappingContext, codeBlock: FlowCodeBlockElement): PageNode {
  const { content } = codeBlock

  const style = resolveNodeStyle(context, codeBlock)

  const children = content.map(mapTextToPageNode)

  return {
    type: 'dom-element',
    tag: 'pre',
    style,
    attributes: {},
    children: [
      {
        type: 'dom-element',
        tag: 'code',
        style: {},
        attributes: {},
        children,
      },
    ],
  }
}

function mapListItemToPageNode(context: MappingContext, listItem: FlowListItemElement): PageNode {
  const { content } = listItem
  const style = resolveNodeStyle(context, listItem)

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const childNodes = content.map((block) => mapBlockToPageNode(context, block))

  return {
    type: 'dom-element',
    tag: 'li',
    style,
    attributes: {},
    children: childNodes,
  }
}

function mapListToPageNode(context: MappingContext, list: FlowListElement): PageNode {
  const { items } = list
  const style = resolveNodeStyle(context, list)

  const childNodes = items.map((listItem) => mapListItemToPageNode(context, listItem))

  return {
    type: 'dom-element',
    tag: 'ul',
    style,
    attributes: {},
    children: childNodes,
  }
}

function mapFileBindingToPageNode(context: MappingContext, fileBinding: FlowFileBinding): PageNode {
  const {
    ref: { fileId },
  } = fileBinding
  const { publishingPlugins, embeddedFiles } = context

  const nodeStyle = resolveNodeStyle(context, fileBinding)

  const fileJson = embeddedFiles[fileId]
  if (fileJson === undefined) {
    // TODO: Support async file loading
    throw new Error(`File not found: ${fileId}`)
  }

  const file = EmbeddedFile.fromJson(fileJson)
  const filePlugin = publishingPlugins.requirePlugin(file.mediaType)
  const fileNode = filePlugin.mapToPageNode(context, file)

  // Wrap the fileNode in a div to apply the style class for file bindings
  return {
    type: 'dom-element',
    tag: 'div',
    style: nodeStyle,
    attributes: {},
    children: [fileNode],
  }
}

function mapBlockToPageNode(context: MappingContext, block: FlowBlockNode): PageNode {
  const { type } = block
  switch (type) {
    case 'paragraph':
      return mapParagraphToPageNode(context, block)
    case 'code-block':
      return mapCodeBlockToPageNode(context, block)
    case 'list':
      return mapListToPageNode(context, block)
    case 'file-binding':
      return mapFileBindingToPageNode(context, block)
    case 'image':
      throw new Error(`Image blocks are no longer supported.`)
    default:
      throw new Error(`Unknown block type: ${type}`)
  }
}

export default function mapFlowDocumentToPageNode(context: MappingContext, document: FlowDocument): PageNode {
  const { content } = document
  const blockNodes = content.map((block) => mapBlockToPageNode(context, block))

  return {
    type: 'fragment',
    children: blockNodes,
  }
}
