import type {
  FlowBlockNode,
  FlowCodeBlockElement,
  FlowFileBinding,
  FlowInlineNode,
  FlowListElement,
  FlowListItemElement,
  FlowParagraphElement,
  FlowTextNode,
} from '../../../services/posts/files/flow-document/FlowDocument'
import type FlowDocument from '../../../services/posts/files/flow-document/FlowDocument'
import type { JsonBlock, JsonInlineMark, JsonListItem, JsonText, RichTextDocument } from '../rich-text/model'
import { JsonBulletList, JsonCodeBlock, JsonFileBinding, JsonParagraph } from '../rich-text/model'

function mapJsonInlineMarkToInlineContent(mark: JsonInlineMark, content: FlowInlineNode): FlowInlineNode {
  const { type } = mark

  if (type === 'link') {
    const { attrs } = mark
    const { href } = attrs as { href: string }

    return {
      type: 'inline-hyperlink',
      href,
      content: [content],
    }
  }

  switch (type) {
    case 'bold':
      return {
        type: 'strong',
        content: [content],
      }
    case 'code':
      return {
        type: 'inline-code',
        content: [content],
      }
    case 'italic':
      return {
        type: 'emphasis',
        content: [content],
      }
    default:
      throw new Error(`Unknown mark type: ${type}`)
  }
}

function mapJsonTextToFlowText(jsonText: JsonText): FlowTextNode {
  const { marks = [], text } = jsonText

  // text with formatting marks cannot be mapped to a plain text node without information loss.
  if (marks.length > 0) {
    throw new Error(`Cannot map json text with marks.`)
  }

  return {
    type: 'text',
    text,
  }
}

function mapJsonTextToInlineContent(jsonText: JsonText): FlowInlineNode {
  const { marks = [], text } = jsonText

  const textSpan: FlowTextNode = {
    type: 'text',
    text,
  }

  return marks.reduce<FlowInlineNode>((draft, mark) => mapJsonInlineMarkToInlineContent(mark, draft), textSpan)
}

function mapJsonParagraphToFlowParagraph(jsonPar: JsonParagraph): FlowParagraphElement {
  const jsonTextNodes: JsonText[] = jsonPar.content ?? []

  const content = jsonTextNodes.map((jsonInline) => mapJsonTextToInlineContent(jsonInline))

  return {
    type: 'paragraph',
    content,
  }
}

function mapJsonCodeBlockToFlowCodeBlock(jsonCodeBlock: JsonCodeBlock): FlowCodeBlockElement {
  const jsonTextNodes: JsonText[] = jsonCodeBlock.content ?? []

  const content = jsonTextNodes.map(mapJsonTextToFlowText)

  return {
    type: 'code-block',
    content,
  }
}

function mapJsonListItemToFlowListItem(jsonListItem: JsonListItem): FlowListItemElement {
  const { content } = jsonListItem

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const blocks = content.map(mapJsonBlockToFlowBlock)

  return {
    type: 'list-item',
    content: blocks,
  }
}

function mapJsonBulletListToFlowList(jsonBulletList: JsonBulletList): FlowListElement {
  const { content } = jsonBulletList

  const listItems = content.map(mapJsonListItemToFlowListItem)

  return {
    type: 'list',
    items: listItems,
  }
}

function mapJsonFileBindingToFlowFileBinding(jsonFileBinding: JsonFileBinding): FlowFileBinding {
  const {
    attrs: { fileId },
  } = jsonFileBinding

  return {
    type: 'file-binding',
    ref: {
      refType: 'embedded-file',
      fileId,
    },
  }
}

function mapJsonBlockToFlowBlock(jsonBlock: JsonBlock): FlowBlockNode {
  if (JsonParagraph.is(jsonBlock)) {
    return mapJsonParagraphToFlowParagraph(jsonBlock)
  }

  if (JsonBulletList.is(jsonBlock)) {
    return mapJsonBulletListToFlowList(jsonBlock)
  }

  if (JsonCodeBlock.is(jsonBlock)) {
    return mapJsonCodeBlockToFlowCodeBlock(jsonBlock)
  }

  if (JsonFileBinding.is(jsonBlock)) {
    return mapJsonFileBindingToFlowFileBinding(jsonBlock)
  }

  const { type: blockType } = jsonBlock
  throw new Error(`Unexpected json content type: ${blockType}`)
}

function mapJsonDocumentToFlowDoc(json: RichTextDocument): FlowDocument {
  const jsonBlocks: JsonBlock[] = json.content ?? []

  const blocks = jsonBlocks.map((jsonBlock) => mapJsonBlockToFlowBlock(jsonBlock))

  return {
    type: 'document',
    content: blocks,
  }
}

export default function mapRichTextJsonToFlowDocument(json: RichTextDocument): FlowDocument {
  return mapJsonDocumentToFlowDoc(json)
}
