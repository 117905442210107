import React from 'react'

import Lottie from 'lottie-react'

import animationData from './SuccessAnimation.lottie.json'

export default function SuccessAnimation(): React.ReactElement {
  return (
    <Lottie
      animationData={animationData}
      loop={false}
    />
  )
}
