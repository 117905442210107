import React from 'react'

import Logo from '@assets/logo/juicy-logo-small.svg'

import SignUpForm from './SignUpForm'
import AuthCard from './layout/AuthCard'
import CenteredLayout from './layout/CenteredLayout'
import CitrusSplashWallpaper from './layout/CitrusSplashWallpaper'

import styles from './SignUpScreen.module.scss'

interface Props {
  loginUrl: string
  error: string | null
  onSubmitForm: (email: string, password: string) => void
}

export default function SignUpScreen({ loginUrl, error, onSubmitForm }: Props): React.ReactElement {
  let errorMessage = null
  if (error) {
    errorMessage = <p className={styles.error}>{error}</p>
  }

  return (
    <div className={styles.SignUpScreen}>
      <CitrusSplashWallpaper>
        <CenteredLayout>
          <AuthCard>
            <Logo className={styles.logo} />
            <h1 className={styles.heading}>Welcome to Juicy</h1>
            {errorMessage}
            <SignUpForm
              loginUrl={loginUrl}
              onSubmitForm={onSubmitForm}
            />
          </AuthCard>
        </CenteredLayout>
      </CitrusSplashWallpaper>
    </div>
  )
}
