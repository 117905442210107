import axios from 'axios'

import type UploadedImage from './UploadedImage'
import type { PostEditorBackendClient } from '../../post-editor-backend/client'
import type { ImageContentType } from '../../services/posts/client'

const IMAGE_ALLOWED_MEDIA_TYPES: readonly ImageContentType[] = ['image/png', 'image/jpeg', 'image/svg+xml'] as const

function isAllowedImageType(contentType: string): contentType is ImageContentType {
  return (IMAGE_ALLOWED_MEDIA_TYPES as readonly string[]).includes(contentType)
}

export default class PostEditorFrontendController {
  constructor(
    private readonly postId: string,
    private readonly backend: PostEditorBackendClient,
    private readonly imageUrlsById: Map<string, string>,
  ) {}

  async createPostImage(file: File): Promise<UploadedImage> {
    const contentType = file.type
    const filename = file.name

    if (!isAllowedImageType(contentType)) {
      // TODO: Display user feedback instead of throwing an error.
      throw new Error(`Invalid file type: ${contentType}`)
    }

    const {
      uploadId,
      presignedPost: { postUrl, formFields },
    } = await this.backend.beginImageUpload(this.postId, contentType, filename)

    await axios.postForm(postUrl, {
      ...formFields,
      file,
    })

    const imageRef = await this.backend.completeImageUpload(this.postId, uploadId)

    // Save URL mapping in state
    const {
      imageId,
      presignedGet: { getUrl },
    } = imageRef
    this.imageUrlsById.set(imageId, getUrl)

    return imageRef
  }

  getImageUrl(imageId: string): string {
    const url = this.imageUrlsById.get(imageId)
    if (url === undefined) {
      throw new Error(`Cannot find URL for image. (ID: ${imageId})`)
    }

    return url
  }
}
