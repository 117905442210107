import { useMemo } from 'react'

import { isNodeEditing, isNodeSelected } from './selectors'
import useSelectionState from './useSelectionState'
import useSelectionTreeNode from './useSelectionTreeNode'

interface UseSelectionResult {
  nodeId: string
  isSelected: boolean
  isEditing: boolean
}

export default function useSelection(idPrefix: string): UseSelectionResult {
  const state = useSelectionState()

  const { nodeId } = useSelectionTreeNode(idPrefix)

  const isSelected = isNodeSelected(state, nodeId)
  const isEditing = isNodeEditing(state, nodeId)

  return useMemo(
    () => ({
      nodeId,
      isSelected,
      isEditing,
    }),
    [isEditing, isSelected, nodeId],
  )
}
