import type { TypeOf } from 'io-ts'
import { literal, strict, string } from 'io-ts'

const PostImage = strict({
  type: literal('post-image'),
  postImageId: string,
})
type PostImage = TypeOf<typeof PostImage>

export const PostImage20240531 = PostImage
export type PostImage20240531 = PostImage
