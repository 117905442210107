import React, { useContext } from 'react'

import type PostEditorState from './PostEditorState'

export const PostEditorStateContext = React.createContext<PostEditorState | undefined>(undefined)
export default function usePostEditorState(): PostEditorState {
  const state = useContext(PostEditorStateContext)
  if (state === undefined) {
    throw new Error('PostEditorState is unexpectedly undefined')
  }

  return state
}
