import type { PageNode } from '@computomatic/pages'

import type EmbeddedFile from '../../../posts/files/EmbeddedFile'
import InlineTextFileReader from '../../../posts/files/inline-text/InlineTextFileReader'
import type MappingContext from '../MappingContext'
import type PublishingPlugin from '../PublishingPlugin'

function textNode(text: string): PageNode {
  return {
    type: 'text',
    content: text,
  }
}

export default {
  stylesheet: {},

  mapToPageNode(_context: MappingContext, file: EmbeddedFile): PageNode {
    const { text } = InlineTextFileReader.openFile(file)

    return textNode(text)
  },
} satisfies PublishingPlugin
