import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SignUpScreen from '../../../authentication/SignUpScreen'
import { useRequestContext, useWorkspaceController } from '../../services'

export default function SignUpScreenBinding(): React.ReactElement {
  const ctx = useRequestContext()
  const controller = useWorkspaceController()
  const navigate = useNavigate()

  const [error, setError] = useState<string | null>(null)

  const handleFormSubmitted = (email: string, password: string): void => {
    const doNavigate = async (): Promise<void> => {
      try {
        await controller.signUp(ctx, { email, password })

        // Redirect user to Workspace Home after account sign-up completes successfully.
        navigate('/')
      } catch (err) {
        console.error(`[LoginScreenBinding::handleFormSubmitted] Error:`, err)
        setError(`An unexpected error has occurred.`)
      }
    }

    doNavigate().catch((err): void => {
      console.error(`[LoginScreenBinding::handleFormSubmitted] Error:`, err)
    })
  }

  return (
    <SignUpScreen
      loginUrl="/login"
      error={error}
      onSubmitForm={handleFormSubmitted}
    />
  )
}
