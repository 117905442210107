import type { FormEventHandler } from 'react'
import React, { useState } from 'react'

import { produce } from 'immer'

import AuthHorizontalSeparator from './ui-elements/AuthHorizontalSeparator'
import AuthTextInput from './ui-elements/AuthTextInput'
import Button from './ui-elements/Button'

import styles from './LoginForm.module.scss'

interface FieldErrors {
  email?: string
  password?: string
}

interface Props {
  defaultEmail?: string
  signUpUrl: string
  onSubmitForm: (email: string, password: string) => void
}

export default function LoginForm({ defaultEmail = '', signUpUrl, onSubmitForm }: Props): React.ReactElement {
  const [email, setEmail] = useState(defaultEmail)
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<FieldErrors>({})
  const [hoveringOnSubmit, setHoveringOnSubmit] = useState(false)

  const formEnabled = !!email && !!password

  const highlightRequired = !formEnabled && hoveringOnSubmit

  const handleEmailChanged = (value: string): void => {
    setEmail(value)

    if (errors.email && value) {
      const updatedErrors = produce(errors, (draft) => {
        delete draft.email
      })
      setErrors(updatedErrors)
    }
  }

  const handlePasswordChanged = (value: string): void => {
    setPassword(value)

    if (errors.password && value) {
      const updatedErrors = produce(errors, (draft) => {
        delete draft.password
      })
      setErrors(updatedErrors)
    }
  }

  const handleMouseEnteredSubmit = (): void => setHoveringOnSubmit(true)
  const handleMouseLeftSubmit = (): void => setHoveringOnSubmit(false)

  const handleFormSubmitted: FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const updatedErrors: FieldErrors = {}
    if (!email) {
      updatedErrors.email = 'Email is required'
      hasError = true
    }

    if (!password) {
      updatedErrors.password = 'Password is required'
      hasError = true
    }

    setErrors(updatedErrors)
    if (hasError) {
      return
    }

    onSubmitForm(email, password)
  }

  return (
    <form
      className={styles.LoginForm}
      aria-label="login form"
      onSubmit={handleFormSubmitted}
    >
      <p className={styles.formTitle}>Login</p>
      <AuthTextInput
        type="email"
        defaultValue={defaultEmail}
        placeholder="Email address"
        ariaLabel="Email address"
        autoFocus
        highlight={highlightRequired && !email}
        error={errors.email}
        onChange={handleEmailChanged}
      />
      <AuthTextInput
        type="password"
        defaultValue=""
        placeholder="Password"
        ariaLabel="Password"
        highlight={highlightRequired && !password}
        error={errors.password}
        onChange={handlePasswordChanged}
      />
      <Button
        label="Continue"
        ariaLabel="Continue"
        variant="primary"
        submitButton
        disabled={!formEnabled}
        onMouseEnter={handleMouseEnteredSubmit}
        onMouseLeave={handleMouseLeftSubmit}
      />
      <AuthHorizontalSeparator text="Or" />
      <Button
        href={signUpUrl}
        variant="secondary"
        label="Sign Up"
      />
    </form>
  )
}
