import PublishingPluginRepository from './PublishingPluginRepository'
import FlowDocumentPublishingPlugin from './flow-document/FlowDocumentPublishingPlugin'
import FramePublishingPlugin from './frame/FramePublishingPlugin'
import InlineTextPublishingPlugin from './inline-text/InlineTextPublishingPlugin'
import PostImagePublishingPlugin from './post-image/PostImagePublishingPlugin'
import MediaTypes from '../../posts/files/MediaTypes'

const PUBLISHING_PLUGINS = {
  [MediaTypes.Frame]: FramePublishingPlugin,
  [MediaTypes.InlineText]: InlineTextPublishingPlugin,
  [MediaTypes.PostImage]: PostImagePublishingPlugin,
  [MediaTypes.FlowDocument]: FlowDocumentPublishingPlugin,
}

export default function loadPublishingPlugins(): PublishingPluginRepository {
  return new PublishingPluginRepository(PUBLISHING_PLUGINS)
}
