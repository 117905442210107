import { produce } from 'immer'

import type { StartDragAction } from './DragDropAction'
import type DragDropAction from './DragDropAction'
import type DragDropState from './DragDropState'

function applyStartDrag(state: DragDropState, { payload }: StartDragAction): DragDropState {
  const { initialPointerRelativeOffset, initialSourceOffset, initialSourceRenderedWidthPx, file } = payload

  return produce(state, (draft) => {
    draft.dragState = {
      stateType: 'dragging',
      initialPointerRelativeOffset,
      initialSourceOffset,
      initialSourceRenderedWidthPx,
      file,
    }
  })
}

function applyDrop(state: DragDropState): DragDropState {
  return produce(state, (draft) => {
    draft.dragState = { stateType: 'dropped' }
  })
}

function applyEndDrag(state: DragDropState): DragDropState {
  return produce(state, (draft) => {
    draft.dragState = { stateType: 'inactive' }
  })
}

export default function dragDropReducer(state: DragDropState, action: DragDropAction): DragDropState {
  const { type } = action
  switch (type) {
    case 'dragDrop/startDrag':
      return applyStartDrag(state, action)
    case 'dragDrop/drop':
      return applyDrop(state)
    case 'dragDrop/endDrag':
      return applyEndDrag(state)
    default:
      throw new Error(`Unknown action: ${type}`)
  }
}
