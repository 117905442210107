import type { Dispatch } from 'react'
import React, { useContext } from 'react'

import type PostEditorAction from './PostEditorAction'

export type PostEditorDispatch = Dispatch<PostEditorAction>

export const PostEditorDispatchContext = React.createContext<PostEditorDispatch | undefined>(undefined)
export default function usePostEditorDispatch(): PostEditorDispatch {
  const dispatch = useContext(PostEditorDispatchContext)
  if (dispatch === undefined) {
    throw new Error(`PostEditorDispatch is unexpectedly empty.`)
  }

  return dispatch
}
