import { produce } from 'immer'

import type {
  BlurNodeAction,
  DeselectElementAction,
  EndEditingAction,
  FocusNodeAction,
  MouseEnterAction,
  MouseLeaveAction,
  MouseOverAction,
  RegisterElementAction,
  SelectElementAction,
  SetNodeParentAction,
  StartEditingAction,
  UnregisterElementAction,
} from './SelectionAction'
import type SelectionAction from './SelectionAction'
import type SelectionState from './SelectionState'
import { getParentId, isDescendant, isNodeSelected, traceAncestry } from './selectors'

export const INITIAL_SELECTION_STATE: SelectionState = {
  selectedNodeId: undefined,
  editingNodeId: undefined,
  hoveringNodeId: undefined,
  elementIds: [],
  parentIdsByChildId: {},
  childIdsByChildKey: {},
}

function addToSet<T>(collection: readonly T[], ...elements: T[]): T[] {
  const set = new Set(collection)
  elements.forEach((element) => set.add(element))

  return Array.from(set)
}

function deleteFromSet<T>(collection: readonly T[], ...elements: T[]): T[] {
  const set = new Set(collection)
  elements.forEach((element) => set.delete(element))

  return Array.from(set)
}

function applyRegisterElement(state: SelectionState, { payload }: RegisterElementAction): SelectionState {
  const { nodeId } = payload

  return produce(state, (draft) => {
    draft.elementIds = addToSet(draft.elementIds, nodeId)
  })
}

function applyUnregisterElement(state: SelectionState, { payload }: UnregisterElementAction): SelectionState {
  const { nodeId } = payload

  return produce(state, (draft) => {
    draft.elementIds = deleteFromSet(draft.elementIds, nodeId)
  })
}

function applySelectElement(state: SelectionState, { payload }: SelectElementAction): SelectionState {
  const { nodeId } = payload

  const parentId = getParentId(state, nodeId)

  return produce(state, (draft) => {
    draft.selectedNodeId = nodeId
    draft.editingNodeId = parentId
  })
}

function applyDeselectElement(state: SelectionState, { payload }: DeselectElementAction): SelectionState {
  const { nodeId } = payload

  if (!isNodeSelected(state, nodeId)) {
    // No-op
    return state
  }

  return produce(state, (draft) => {
    draft.selectedNodeId = undefined
  })
}

function startEditingNode(state: SelectionState, nodeId: string): SelectionState {
  return produce(state, (draft) => {
    draft.editingNodeId = nodeId
    draft.selectedNodeId = undefined
  })
}

function applyStartEditing(state: SelectionState, { payload }: StartEditingAction): SelectionState {
  const { nodeId } = payload

  return startEditingNode(state, nodeId)
}

function applyEndEditing(state: SelectionState, { payload }: EndEditingAction): SelectionState {
  const { nodeId } = payload

  return produce(state, (draft) => {
    draft.editingNodeId = undefined
    draft.selectedNodeId = nodeId
  })
}

function applySetNodeParent(state: SelectionState, { payload }: SetNodeParentAction): SelectionState {
  const { childId, childKey, parentId } = payload

  // Check for cycles
  if (parentId !== undefined && isDescendant(state, childId, parentId)) {
    const ancestors = traceAncestry(state, parentId)
    throw new Error(`Cycle detected in selection tree. (${ancestors.join(' -> ')} -> ${childId})`)
  }

  return produce(state, (draft) => {
    if (parentId === undefined) {
      delete draft.parentIdsByChildId[childId]
    } else {
      draft.parentIdsByChildId[childId] = parentId
    }

    if (childKey !== undefined) {
      draft.childIdsByChildKey[childKey] = childId
    }
  })
}

function applyMouseEnter(state: SelectionState, { payload }: MouseEnterAction): SelectionState {
  const { nodeId } = payload

  return produce(state, (draft) => {
    draft.hoveringNodeId = nodeId
  })
}

function applyMouseOver(state: SelectionState, { payload }: MouseOverAction): SelectionState {
  const { nodeId } = payload
  const { hoveringNodeId } = state
  if (hoveringNodeId === nodeId) {
    // No-op
    return state
  }

  return produce(state, (draft) => {
    draft.hoveringNodeId = nodeId
  })
}

function applyMouseLeave(state: SelectionState, { payload }: MouseLeaveAction): SelectionState {
  const { nodeId } = payload
  const { hoveringNodeId } = state
  if (hoveringNodeId !== nodeId) {
    // No-op
    return state
  }

  return produce(state, (draft) => {
    draft.hoveringNodeId = undefined
  })
}

function applyFocusNode(state: SelectionState, { payload }: FocusNodeAction): SelectionState {
  const { nodeId } = payload
  const { editingNodeId } = state

  if (nodeId === editingNodeId) {
    // No-op
    return state
  }

  return startEditingNode(state, nodeId)
}

function applyBlurNode(state: SelectionState, { payload }: BlurNodeAction): SelectionState {
  const { nodeId } = payload
  const { selectedNodeId, editingNodeId } = state

  if (nodeId !== editingNodeId) {
    // No-op
    return state
  }

  if (selectedNodeId !== undefined && isDescendant(state, nodeId, selectedNodeId)) {
    // Do not change focus if child remains selected
    return state
  }

  return produce(state, (draft) => {
    // Stop editing when focus is lost
    draft.editingNodeId = undefined
  })
}

export default function selectionReducer(state: SelectionState, action: SelectionAction): SelectionState {
  const { type: actionType } = action
  switch (actionType) {
    case 'selection/registerElement':
      return applyRegisterElement(state, action)
    case 'selection/unregisterElement':
      return applyUnregisterElement(state, action)
    case 'selection/selectElement':
      return applySelectElement(state, action)
    case 'selection/deselectElement':
      return applyDeselectElement(state, action)
    case 'selection/startEditing':
      return applyStartEditing(state, action)
    case 'selection/endEditing':
      return applyEndEditing(state, action)
    case 'selection/setNodeParent':
      return applySetNodeParent(state, action)
    case 'selection/mouseOver':
      return applyMouseOver(state, action)
    case 'selection/mouseEnter':
      return applyMouseEnter(state, action)
    case 'selection/mouseLeave':
      return applyMouseLeave(state, action)
    case 'selection/focusNode':
      return applyFocusNode(state, action)
    case 'selection/blurNode':
      return applyBlurNode(state, action)
    default:
      throw new Error(`Unknown selection action type: ${actionType}`)
  }
}
