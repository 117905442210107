import type { Type } from 'io-ts'

import { FrameState20240603 } from './2024-06-03/FrameState20240603'
import stringify from '../../serialization/stringify'
import type { FrameState } from '../FrameState'

const CURRENT_SCHEMA: Type<FrameState> = FrameState20240603

export default function serializeFrame(document: FrameState): string {
  return stringify<FrameState>(CURRENT_SCHEMA, document)
}
