import * as t from 'io-ts'

const InlineText = t.strict({
  type: t.literal('inline-text'),
  text: t.string,
})
type InlineText = t.TypeOf<typeof InlineText>

export const InlineText20240118 = InlineText
export type InlineText20240118 = InlineText
