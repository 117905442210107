import type CssProperties from '../../../../posts/client/CssProperties'
import type { Stylesheet } from '../../../../posts/client/PostContent'

export default function resolveStyle(
  stylesheet: Stylesheet,
  classNames: string[],
  inlineStyle: React.CSSProperties | undefined,
): CssProperties {
  const stylesByClassName = stylesheet.rules.reduce<Record<string, React.CSSProperties>>((acc, rule) => {
    const { type } = rule
    if (type !== 'class-rule') {
      throw new Error(`Unknown rule type: ${type}`)
    }

    const { className, cssStyle } = rule

    return Object.assign(acc, { [className]: cssStyle })
  }, {})

  const classStyles = classNames
    .map((className) => {
      const result = stylesByClassName[className]

      if (result === undefined) {
        // Technically supported but there's currently no valid case where this would occur. This scenario implies
        // something isn't right. This could be helpful info for troubleshooting unexpected behavior.
        console.warn(`[resolveStyle] Could not find rule for class:`, className)
      }

      return result
    })
    // Ignore any classes which are not present in the stylesheet
    .filter((style) => style !== undefined)

  // Styles are merged by giving priority to classes that appear later in the list of class names.
  const aggregateClassStyle = classStyles.reduce<React.CSSProperties>((acc, style) => Object.assign(acc, style), {})

  // Inline styles have the highest priority.
  return {
    ...aggregateClassStyle,
    ...inlineStyle,
  }
}
