import type { Type, TypeOf } from 'io-ts'
import { array, literal, recursion, strict, string, union } from 'io-ts'

type ImageRef = TypeOf<typeof ImageRef>

type RichText = TypeOf<typeof RichText>
type InlineHyperlink = TypeOf<typeof InlineHyperlink>

type InlineContent = TypeOf<typeof InlineContent>

type Paragraph = TypeOf<typeof Paragraph>

interface ListItem {
  type: 'list-item'
  classNames: string[]
  content: Block[]
}

interface List {
  type: 'list'
  classNames: string[]
  items: ListItem[]
}

type Image = TypeOf<typeof Image>
type Block = Paragraph | List | Image

type FlowDocument = TypeOf<typeof FlowDocument>

const ImageRef = strict({
  imageId: string,
})

const RichText = strict({
  type: literal('rich-text'),
  classNames: array(string),
  text: string,
})

const InlineHyperlink = strict({
  type: literal('inline-hyperlink'),
  classNames: array(string),
  href: string,
  content: array(RichText),
})

const InlineContent = union([RichText, InlineHyperlink])

const Paragraph = strict({
  type: literal('paragraph'),
  classNames: array(string),
  content: array(InlineContent),
})

const ListItem: Type<ListItem> = recursion('ListItem', () =>
  strict({
    type: literal('list-item'),
    classNames: array(string),
    content: array(Block),
  }),
)

const List: Type<List> = recursion('List', () =>
  strict({
    type: literal('list'),
    classNames: array(string),
    items: array(ListItem),
  }),
)

const Image = strict({
  type: literal('image'),
  classNames: array(string),
  ref: ImageRef,
})

const Block: Type<Block> = recursion('Block', () => union([Paragraph, List, Image]))

const FlowDocument = strict({
  type: literal('document'),
  content: array(Block),
})

export type RichText20240503 = RichText
export type InlineHyperlink20240503 = InlineHyperlink
export type InlineContent20240503 = InlineContent
export type Paragraph20240503 = Paragraph
export type ListItem20240503 = ListItem
export type List20240503 = List
export type Image20240503 = Image
export type Block20240503 = Block

export const FlowDocument20240503 = FlowDocument
export type FlowDocument20240503 = FlowDocument
