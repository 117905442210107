import type { DragEvent } from 'react'
import React, { memo, useEffect, useReducer, useState } from 'react'

import classNames from 'classnames'
import { v4 as uuid } from 'uuid'

import FileDropTarget from './FileDropTarget'
import type FrameEditorState from './state/FrameEditorState'
import frameEditorReducer from './state/frameEditorReducer'
import type { FrameEditorDispatch } from './state/useFrameEditorDispatch'
import useFrameEditorDispatch, { FrameEditorDispatchContext } from './state/useFrameEditorDispatch'
import useFrameEditorState, { FrameEditorStateContext } from './state/useFrameEditorState'
import type EmbeddedFile from '../../../services/posts/files/EmbeddedFile'
import type FlowDocument from '../../../services/posts/files/flow-document/FlowDocument'
import FlowDocumentFileBuilder from '../../../services/posts/files/flow-document/FlowDocumentFileBuilder'
import FrameFileBuilder from '../../../services/posts/files/frame/FrameFileBuilder'
import FrameFileReader from '../../../services/posts/files/frame/FrameFileReader'
import type { FrameElement, FrameFileBlock } from '../../../services/posts/files/frame/FrameState'
import { selectFrameElements } from '../../../services/posts/files/frame/selectors'
import PostImageFileBuilder from '../../../services/posts/files/post-image/PostImageFileBuilder'
import {
  dynamicFrameBlockStyle,
  dynamicFrameStyle,
  FRAME_BLOCK_STYLE_CLASS,
  FRAME_STYLE_CLASS,
} from '../../../services/publishing/plugins/frame/FramePublishingPlugin'
import type XYCoord from '../../drag-drop/XYCoord'
import useUploadImage from '../../images/useUploadImage'
import SelectableChild from '../../selection/containers/SelectableChild'
import useSelectableChild from '../../selection/containers/useSelectableChild'
import useStylesheetClass from '../../stylesheets/useStylesheetClass'
import FileDragSource from '../FileDragSource'
// eslint-disable-next-line import/no-cycle
import FileEditor from '../FileEditor'
import useCreateEmbeddedFile from '../useCreateEmbeddedFile'

import styles from './FrameEditor.module.scss'

const CLASS_DRAGGING_OVER = styles.draggingOver ?? '__unknown-style__'

const IMAGE_ALLOWED_MEDIA_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'] as const

/** Reference: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values */
const KEY_BACKSPACE = 'Backspace'

const DEFAULT_ELEMENT_X = 10
const DEFAULT_ELEMENT_Y = 10
const DEFAULT_IMAGE_WIDTH = 200

const EMPTY_FLOW_DOCUMENT: FlowDocument = {
  type: 'document',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Hello, world!',
        },
      ],
    },
  ],
}

const MemoFileEditor = memo(FileEditor)

async function showFileSelector(): Promise<FileList> {
  // Create a new file input field
  const fileInput = document.createElement('input')
  fileInput.setAttribute('type', 'file')
  fileInput.setAttribute('accept', IMAGE_ALLOWED_MEDIA_TYPES.join(', '))
  fileInput.style.display = 'none'
  document.body.appendChild(fileInput)

  return new Promise<FileList>((resolve, reject) => {
    fileInput.addEventListener('change', () => {
      if (!fileInput.files) {
        reject(new Error('No files available.'))

        return
      }

      resolve(fileInput.files)
    })

    fileInput.click()
  }).finally(() => {
    fileInput.remove()
  })
}

function createFileBlock(fileId: string, x: number, y: number): FrameFileBlock {
  const nodeId = uuid()

  return {
    id: nodeId,
    index: 0.0,
    type: 'block',
    x,
    y,
    blockType: 'file-binding',
    fileRefType: 'embedded-file',
    fileId,
  }
}

interface ChildElementProps {
  element: FrameElement
}

function ChildElement({ element }: ChildElementProps): React.ReactElement {
  const dispatch = useFrameEditorDispatch()

  if (element.fileRefType !== 'embedded-file') {
    throw new Error(`Unknown file ref type: ${element.fileRefType}`)
  }
  const { id: elementId, x, y, fileId } = element

  const removeElement = (): void => {
    dispatch({ type: 'frameEditor/removeFrameElement', payload: { nodeId: elementId } })
  }

  const { childKey, isSelected: childSelected } = useSelectableChild()

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (childSelected && e.key === KEY_BACKSPACE) {
      e.preventDefault()
      removeElement()
    }
  }

  const handleDragStart = (e: DragEvent): void => {
    if (childSelected) {
      // A selected child is being dragged so prevent the event from bubbling up, causing the frame itself to be dragged.
      e.stopPropagation()
    }
  }

  const handleDragEnd = (e: React.DragEvent, didDrop: boolean): void => {
    if (!didDrop) {
      return
    }

    const { dropEffect } = e.dataTransfer
    if (dropEffect === 'move') {
      // Remove elements from the parent frame when they are dragged away
      // TODO: Avoid removing and simply reposition elements when they are dragged within the same frame.
      removeElement()
    }
  }

  const className = useStylesheetClass(FRAME_BLOCK_STYLE_CLASS)
  const dynamicCssStyle = dynamicFrameBlockStyle(x, y)

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      style={dynamicCssStyle}
      onKeyDown={handleKeyDown}
      onDragStart={handleDragStart}
    >
      <SelectableChild childKey={childKey}>
        <FileDragSource
          fileId={fileId}
          draggable={childSelected}
          onDragEnd={handleDragEnd}
        >
          <MemoFileEditor fileId={fileId} />
        </FileDragSource>
      </SelectableChild>
    </div>
  )
}

interface FrameProps {
  onFocus: () => void
  onBlur: () => void
  children: React.ReactNode
}

function Frame({ onFocus, onBlur, children }: FrameProps): React.ReactElement {
  const createEmbeddedFile = useCreateEmbeddedFile()
  const dispatch = useFrameEditorDispatch()
  const { frameState: document } = useFrameEditorState()

  const cssClassName = useStylesheetClass(FRAME_STYLE_CLASS)
  const dynamicCssStyle = dynamicFrameStyle(document.height)

  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const showDragOverEffect = (): void => {
    setIsDraggingOver(true)
  }

  const hideDragOverEffect = (): void => {
    setIsDraggingOver(false)
  }

  const handleFileDropped = (droppedFile: EmbeddedFile, relativeItemOffset: XYCoord): void => {
    const createFileAsync = async (): Promise<void> => {
      const { fileId } = await createEmbeddedFile(droppedFile)

      const { x, y } = relativeItemOffset

      // Insert the new file
      const fileBlock = createFileBlock(fileId, x, y)
      dispatch({ type: 'frameEditor/appendFrameElement', payload: { element: fileBlock } })

      // TODO: Update the current selection to the new block
    }

    createFileAsync()
    hideDragOverEffect()
  }
  const className = classNames(styles.Frame, cssClassName, {
    [CLASS_DRAGGING_OVER]: isDraggingOver,
  })

  return (
    <FileDropTarget
      onDragOver={showDragOverEffect}
      onDragLeave={hideDragOverEffect}
      onDrop={handleFileDropped}
    >
      <div
        className={className}
        style={dynamicCssStyle}
        tabIndex={-1}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {children}
      </div>
    </FileDropTarget>
  )
}

function initialState(file: EmbeddedFile): FrameEditorState {
  const { state } = FrameFileReader.openFile(file)

  return {
    frameState: state,
    localFileVersion: 1,
  }
}

function useFrameFile(
  file: EmbeddedFile,
  onChange: (updatedFile: EmbeddedFile) => void,
): [FrameEditorState, FrameEditorDispatch] {
  const [state, dispatch] = useReducer(frameEditorReducer, initialState(file))

  const [remoteFileVersion, setRemoteFileVersion] = useState(state.localFileVersion)

  // Sync local document updates to embedded file host. This pattern prevents data loss when there are multiple file
  // updates within a single render loop.
  useEffect(() => {
    if (state.localFileVersion > remoteFileVersion) {
      const updatedFile = FrameFileBuilder.fromState(state.frameState).build()
      onChange(updatedFile)
      setRemoteFileVersion(state.localFileVersion)
    }
  }, [onChange, remoteFileVersion, state.frameState, state.localFileVersion])

  return [state, dispatch]
}

function FrameMenu(): React.ReactElement {
  const uploadImage = useUploadImage()
  const createEmbeddedFile = useCreateEmbeddedFile()

  const dispatch = useFrameEditorDispatch()

  const handleCreateImageClicked = (): void => {
    const promptAndUploadImage = async (): Promise<void> => {
      const [selectedFile] = await showFileSelector()
      if (selectedFile === undefined) {
        return
      }

      const { postImageId } = await uploadImage(selectedFile)

      const postImageFile = new PostImageFileBuilder(postImageId).withWidth(DEFAULT_IMAGE_WIDTH).build()
      const { fileId: postImageFileId } = await createEmbeddedFile(postImageFile)

      const fileBlock = createFileBlock(postImageFileId, DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y)
      dispatch({ type: 'frameEditor/appendFrameElement', payload: { element: fileBlock } })
    }

    promptAndUploadImage()
  }

  const handleCreateRichTextClicked = (): void => {
    const asyncCreate = async (): Promise<void> => {
      const richTextFile = new FlowDocumentFileBuilder(EMPTY_FLOW_DOCUMENT).build()
      const { fileId: richTextFileId } = await createEmbeddedFile(richTextFile)

      const fileBlock = createFileBlock(richTextFileId, DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y)
      dispatch({ type: 'frameEditor/appendFrameElement', payload: { element: fileBlock } })
    }

    asyncCreate()
  }

  return (
    <div className={styles.menu}>
      <button
        type="button"
        onClick={handleCreateImageClicked}
      >
        Add Image
      </button>
      <button
        type="button"
        onClick={handleCreateRichTextClicked}
      >
        Add Rich Text
      </button>
    </div>
  )
}

interface Props {
  file: EmbeddedFile
  onChange: (updatedFile: EmbeddedFile) => void
  onFocus: () => void
  onBlur: () => void
}

export default function FrameEditor({ file, onChange, onFocus, onBlur }: Props): React.ReactElement {
  const [state, dispatch] = useFrameFile(file, onChange)

  const orderedElements = selectFrameElements(state.frameState)

  const children = orderedElements.map<React.ReactNode>((element) => (
    <ChildElement
      key={element.id}
      element={element}
    />
  ))

  return (
    <FrameEditorDispatchContext.Provider value={dispatch}>
      <FrameEditorStateContext.Provider value={state}>
        <div className={styles.FrameEditor}>
          <FrameMenu />

          <Frame
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {children}
          </Frame>
        </div>
      </FrameEditorStateContext.Provider>
    </FrameEditorDispatchContext.Provider>
  )
}
