import type CssProperties from '../../services/posts/client/CssProperties'
import type { ClassRule, Stylesheet as PageTemplateStylesheet } from '../../services/posts/client/PostContent'
import type PublishingPluginRepository from '../../services/publishing/plugins/PublishingPluginRepository'

function classRule(className: string, cssStyle: CssProperties): ClassRule {
  return {
    type: 'class-rule',
    className,
    cssStyle,
  }
}

export default function determineMissingStyles(
  publishingPlugins: PublishingPluginRepository,
  currentStyles: PageTemplateStylesheet,
): ClassRule[] {
  const existingRuleClasses = currentStyles.rules.reduce<Set<string>>((acc, rule) => {
    const { type } = rule
    if (type !== 'class-rule') {
      return acc
    }

    const { className } = rule
    acc.add(className)

    return acc
  }, new Set())

  return (
    Object.entries(publishingPlugins.stylesheet)
      // A rule must be added to the template for each class name which is currently absent.
      .filter(([requiredClassName]) => !existingRuleClasses.has(requiredClassName))
      .map(([requiredClassName, cssStyle]) => classRule(requiredClassName, cssStyle))
  )
}
