import type { Type, TypeOf } from 'io-ts'
import { literal, readonlyArray, recursion, strict, string, union } from 'io-ts'

type FlowImageRef = TypeOf<typeof FlowImageRef>
type FlowFileRef = TypeOf<typeof FlowFileRef>

type FlowTextNode = TypeOf<typeof FlowTextNode>

interface FlowStrongElement {
  type: 'strong'
  content: readonly FlowInlineNode[]
}
interface FlowEmphasisElement {
  type: 'emphasis'
  content: readonly FlowInlineNode[]
}

interface FlowCodeElement {
  type: 'inline-code'
  content: readonly FlowInlineNode[]
}
interface FlowLinkElement {
  type: 'inline-hyperlink'
  href: string
  content: readonly FlowInlineNode[]
}

type FlowInlineNode = FlowTextNode | FlowStrongElement | FlowEmphasisElement | FlowCodeElement | FlowLinkElement

type FlowParagraphElement = TypeOf<typeof FlowParagraphElement>
/** Added 2024-05-16 */
type FlowCodeBlockElement = TypeOf<typeof FlowCodeBlockElement>

interface FlowListItemElement {
  type: 'list-item'
  content: readonly FlowBlockNode[]
}

interface FlowListElement {
  type: 'list'
  items: readonly FlowListItemElement[]
}

type FlowImageElement = TypeOf<typeof FlowImageElement>
type FlowFileBinding = TypeOf<typeof FlowFileBinding>
type FlowBlockNode = FlowParagraphElement | FlowCodeBlockElement | FlowListElement | FlowImageElement | FlowFileBinding

type FlowDocument = TypeOf<typeof FlowDocument>

const FlowImageRef = strict({
  imageId: string,
})

/** Added 2024-07-03 */
const FlowFileRef = strict({
  refType: literal('embedded-file'),
  fileId: string,
})

const FlowTextNode = strict({
  type: literal('text'),
  text: string,
})

const FlowStrongElement: Type<FlowStrongElement> = recursion('FlowStrongElement', () =>
  strict({
    type: literal('strong'),
    content: readonlyArray(FlowInlineNode),
  }),
)

const FlowEmphasisElement: Type<FlowEmphasisElement> = recursion('FlowEmphasisElement', () =>
  strict({
    type: literal('emphasis'),
    content: readonlyArray(FlowInlineNode),
  }),
)

const FlowCodeElement: Type<FlowCodeElement> = recursion('FlowCodeElement', () =>
  strict({
    type: literal('inline-code'),
    content: readonlyArray(FlowInlineNode),
  }),
)

const FlowLinkElement: Type<FlowLinkElement> = recursion('FlowLinkElement', () =>
  strict({
    type: literal('inline-hyperlink'),
    href: string,
    content: readonlyArray(FlowInlineNode),
  }),
)

const FlowInlineNode: Type<FlowInlineNode> = recursion('FlowInlineNode', () =>
  union([FlowTextNode, FlowStrongElement, FlowEmphasisElement, FlowCodeElement, FlowLinkElement]),
)

const FlowParagraphElement = strict({
  type: literal('paragraph'),
  content: readonlyArray(FlowInlineNode),
})

/** Added 2024-05-16 */
const FlowCodeBlockElement = strict({
  type: literal('code-block'),
  content: readonlyArray(FlowTextNode),
})

const FlowListItemElement: Type<FlowListItemElement> = recursion('ListItem', () =>
  strict({
    type: literal('list-item'),
    content: readonlyArray(FlowBlockNode),
  }),
)

const FlowListElement: Type<FlowListElement> = recursion('FlowListElement', () =>
  strict({
    type: literal('list'),
    items: readonlyArray(FlowListItemElement),
  }),
)

const FlowImageElement = strict({
  type: literal('image'),
  ref: FlowImageRef,
})

/** Added 2024-07-03 */
const FlowFileBinding = strict({
  type: literal('file-binding'),
  ref: FlowFileRef,
})

const FlowBlockNode: Type<FlowBlockNode> = recursion('FlowBlockNode', () =>
  union([FlowParagraphElement, FlowCodeBlockElement, FlowListElement, FlowImageElement, FlowFileBinding]),
)

const FlowDocument = strict({
  type: literal('document'),
  content: readonlyArray(FlowBlockNode),
})

export type FlowLinkElement20240515 = FlowLinkElement
export type FlowInlineNode20240515 = FlowInlineNode
export type FlowParagraphElement20240515 = FlowParagraphElement
export type FlowListItemElement20240515 = FlowListItemElement
export type FlowListElement20240515 = FlowListElement
export type FlowImageElement20240515 = FlowImageElement
export type FlowBlockNode20240515 = FlowBlockNode

export const FlowDocument20240515 = FlowDocument
export type FlowDocument20240515 = FlowDocument
