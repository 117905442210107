import type { DragEventHandler } from 'react'
import React, { useState } from 'react'

import classNames from 'classnames'

import useEmbeddedFile from './useEmbeddedFile'
import type EmbeddedFileJson from '../../services/posts/files/EmbeddedFileJson'
import type XYCoord from '../drag-drop/XYCoord'
import { setEmbeddedFileJson } from '../drag-drop/draggingFileData'
import useDragDropDispatch from '../drag-drop/useDragDropDispatch'
import useDragDropState from '../drag-drop/useDragDropState'

import styles from './FileDragSource.module.scss'

const CLASS_DRAGGING = styles.dragging ?? '__unknown-style__'

interface StartDraggingFileOutput {
  initialPointerRelativeOffset: XYCoord
  initialSourceOffset: XYCoord
  initialSourceRenderedWidthPx: number
}

function prepareDragStartState(e: React.DragEvent, sourceDomNode: HTMLElement | null): StartDraggingFileOutput {
  const {
    /** horizontal coordinate within the application's viewport at which the event occurred (as opposed to the coordinate within the page). */
    clientX: sourceOffsetX,
    /** vertical coordinate within the application's viewport at which the event occurred (as opposed to the coordinate within the page). */
    clientY: sourceOffsetY,
  } = e
  const {
    /** X coordinate of the mouse pointer between the event and the padding edge of the dragged item (event target node) */
    offsetX: pointerOffsetX,
    /** Y coordinate of the mouse pointer between the event and the padding edge of the dragged item (event target node) */
    offsetY: pointerOffsetY,
  } = e.nativeEvent
  const { width } = sourceDomNode?.getClientRects()[0] ?? {}
  if (width === undefined) {
    throw new Error(`Could not determine layout width of drag source.`)
  }

  return {
    initialPointerRelativeOffset: { x: pointerOffsetX, y: pointerOffsetY },
    initialSourceOffset: { x: sourceOffsetX, y: sourceOffsetY },
    initialSourceRenderedWidthPx: width,
  }
}

export function startDraggingFile(fileId: string, fileJson: EmbeddedFileJson, e: React.DragEvent): void {
  if (e.dataTransfer === null) {
    throw new Error(`dataTransfer is not initialized`)
  }

  e.dataTransfer.effectAllowed = 'copyMove'

  setEmbeddedFileJson(e.dataTransfer, fileJson)

  // Set transfer data for prosemirror
  const node = document.createElement('file-binding')
  node.setAttribute('fileid', fileId) // cSpell:words fileid
  e.dataTransfer.setData('text/html', node.outerHTML)
}

interface Props {
  fileId: string
  draggable: boolean
  onDragStart?: (e: React.DragEvent) => boolean
  onDragEnd?: (e: React.DragEvent, didDrop: boolean) => void
  children: React.ReactNode
}

export default function FileDragSource({
  fileId,
  draggable,
  onDragStart,
  onDragEnd,
  children,
}: Props): React.ReactElement {
  const dispatch = useDragDropDispatch()
  const { dragState } = useDragDropState()

  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null)

  const file = useEmbeddedFile(fileId)
  const fileJson = file.toJson()

  const [isDragging, setIsDragging] = useState(false)
  const handleDragStart: DragEventHandler = (e) => {
    const { initialPointerRelativeOffset, initialSourceOffset, initialSourceRenderedWidthPx } = prepareDragStartState(
      e,
      divRef,
    )
    // Prevent the browser from allowing drag of the image, despite drag being disabled
    if (!draggable) {
      e.dataTransfer.clearData()

      return
    }
    e.stopPropagation()

    setIsDragging(true)
    dispatch({
      type: 'dragDrop/startDrag',
      payload: {
        initialPointerRelativeOffset,
        initialSourceOffset,
        initialSourceRenderedWidthPx,
        file: fileJson,
      },
    })

    const handled = onDragStart?.(e)
    if (handled) {
      return
    }

    startDraggingFile(fileId, fileJson, e)
  }

  const handleDragEnd: DragEventHandler = (e) => {
    e.stopPropagation()

    dispatch({ type: 'dragDrop/endDrag', payload: {} })

    setIsDragging(false)

    const didDrop = dragState.stateType === 'dropped'
    onDragEnd?.(e, didDrop)
  }

  const className = classNames(styles.DraggableFile, {
    [CLASS_DRAGGING]: isDragging,
  })

  return (
    <div
      ref={setDivRef}
      className={className}
      /** required for browser to enable dragging */
      draggable={draggable}
      /** required for browser to enable drop events */
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </div>
  )
}
