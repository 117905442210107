import type { ChangeEventHandler } from 'react'
import React from 'react'

import classNames from 'classnames'

import styles from './AuthTextInput.module.scss'

const STYLE_HIGHLIGHT = styles.highlight ?? '__missing_style__'
const STYLE_ERROR = styles.error ?? '__missing_style__'

interface Props {
  type: 'text' | 'email' | 'password'
  defaultValue: string
  placeholder: string
  ariaLabel?: string
  autoFocus?: boolean
  highlight?: boolean
  error?: string
  onChange: (value: string) => void
}

export default function AuthTextInput({
  ariaLabel,
  type,
  defaultValue,
  placeholder,
  autoFocus,
  highlight = false,
  error,
  onChange,
}: Props): React.ReactElement {
  const handleChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    onChange(value)
  }

  let errorMessage: React.ReactElement | null = null
  if (error) {
    errorMessage = <p className={styles.errorMessage}>{error}</p>
  }

  return (
    <div className={styles.AuthTextInput}>
      <input
        aria-label={ariaLabel}
        className={classNames(styles.input, {
          [STYLE_HIGHLIGHT]: highlight,
          [STYLE_ERROR]: !!error,
        })}
        type={type}
        defaultValue={defaultValue}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={handleChanged}
      />
      {errorMessage}
    </div>
  )
}
