import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type { FrameEditorAction } from './FrameEditorAction'

export type FrameEditorDispatch = Dispatch<FrameEditorAction>

export const FrameEditorDispatchContext = createContext<FrameEditorDispatch>(() => {})

export default function useFrameEditorDispatch(): FrameEditorDispatch {
  return useContext(FrameEditorDispatchContext)
}
