import { produce } from 'immer'

import type { FrameElement, FrameState } from './FrameState'
import { selectLastElement } from './selectors'

export function appendElement(state: FrameState, element: FrameElement): FrameState {
  // Uses 0.0 as the previous index if there are no existing elements.
  const prevIndex = selectLastElement(state)?.index ?? 0.0

  const rangeSize = Number.MAX_VALUE - prevIndex
  // Apply jitter to the index, ensuring concurrent appends never produce the same ordering.
  const jitter = Math.random() * (rangeSize / 20.0)
  const elementIndex = prevIndex + rangeSize / 2.0 + jitter

  const indexedElement = produce(element, (draft) => {
    draft.index = elementIndex
  })

  return produce(state, (draft) => {
    draft.elements.push(indexedElement)
  })
}

export function deleteElement(state: FrameState, nodeId: string): FrameState {
  return produce(state, (draft) => {
    const nodeIndex = draft.elements.findIndex(({ id }) => id === nodeId)
    if (nodeIndex === -1) {
      throw new Error(`Could not find element to remove. (${nodeId})`)
    }

    draft.elements.splice(nodeIndex, 1)
  })
}
