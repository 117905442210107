import React, { memo } from 'react'

import TemplatedContent from './TemplatedContent'
import usePostEditorState from './state/usePostEditorState'

const MemoTemplatedContent = memo(TemplatedContent)

export default function PostContent(): React.ReactElement {
  const { pageTemplate } = usePostEditorState()
  const { baseStyle, content: templateBody } = pageTemplate

  return (
    <div
      style={baseStyle}
      aria-label="page preview"
    >
      <MemoTemplatedContent node={templateBody} />
    </div>
  )
}
