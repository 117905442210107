import type FlowDocument from './FlowDocument'
import parseFlowDocument from './serialization/parseFlowDocument'
import type EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class FlowDocumentFileReader {
  private constructor(public readonly value: FlowDocument) {}

  static openFile(file: EmbeddedFile): FlowDocumentFileReader {
    if (file.mediaType !== MediaTypes.FlowDocument) {
      throw new Error(`File has unexpected mediaType: ${file.mediaType}`)
    }

    const data = file.readAsText()
    const value = parseFlowDocument(data)

    return new FlowDocumentFileReader(value)
  }
}
