import type { TypeOf } from 'io-ts'
import { literal, nullType, number, strict, string, union } from 'io-ts'

const PostImage = strict({
  type: literal('post-image'),
  postImageId: string,
  width: union([number, nullType]),
})
type PostImage = TypeOf<typeof PostImage>

export const PostImage20240611 = PostImage
export type PostImage20240611 = PostImage
