import { hydrateRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import getBootstrap from './getBootstrap'
import App from '../app/App'
import type AppRenderContext from '../app/AppRenderContext'
import buildAppRoutes from '../app/routing/buildAppRoutes'
import {
  createActionDemoControllerHttpClient,
  createKvDbEditorBackendHttpClient,
  createPostEditorBackendHttpClient,
  createWorkspaceControllerHttpClient,
} from '../services/http'
import loadPublishingPlugins from '../services/publishing/plugins/loadPublishingPlugins'
import { APP_ROOT_ID } from '../ssr/constants'

const publishingPlugins = loadPublishingPlugins()
const kvDbEditorBackend = createKvDbEditorBackendHttpClient()
const postEditorBackend = createPostEditorBackendHttpClient()
const actionDemoController = createActionDemoControllerHttpClient()
const workspaceController = createWorkspaceControllerHttpClient()

const bootstrap = getBootstrap()
if (bootstrap === undefined) {
  throw new Error(`Client bootstrap is unexpectedly empty.`)
}

const $root = document.getElementById(APP_ROOT_ID)
if ($root === null) {
  throw new Error(`Could not find $root (ID: ${APP_ROOT_ID})`)
}

const { appConfig } = bootstrap

const renderContext: AppRenderContext = {
  /** Request context is empty client-side. It will be populated by the RPC server. */
  ctx: {},
  appConfig,
  postEditorBackend,
  kvDbEditorBackend,
  actionDemoController,
  publishingPlugins,
  workspaceController,
}
const appRoutes = buildAppRoutes(renderContext)
const router = createBrowserRouter(appRoutes)
hydrateRoot(
  $root,
  <App renderContext={renderContext}>
    <RouterProvider router={router} />
  </App>,
)

console.info('Client-side React hydrated.')
