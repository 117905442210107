import type { Type } from 'io-ts'

import { InlineText20240118 } from './InlineText20240118'
import stringify from '../../serialization/stringify'
import type InlineText from '../InlineText'

const CURRENT_SCHEMA: Type<InlineText> = InlineText20240118

export default function stringifyInlineText(file: InlineText): string {
  return stringify(CURRENT_SCHEMA, file)
}
