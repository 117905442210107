import { useContext } from 'react'

import ClassNameMapContext from './ClassNameMapContext'

const MISSING_STYLE = '__unknown-style__'

export default function useStylesheetClass(className: string): string {
  const classNameMap = useContext(ClassNameMapContext)

  return classNameMap[className] ?? MISSING_STYLE
}
