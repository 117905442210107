import React from 'react'

import OpenInNewIcon from '@assets/icons/OpenInNew.svg'

import InlineTextEditor from './binding-fields/inline/InlineTextEditor'
import usePostEditorDispatch from './state/usePostEditorDispatch'

import styles from './FileMenu.module.scss'

interface Props {
  initialFilename: string
  urlPath: string | undefined
  publishedPageUrl: string | undefined
}

export default function FileMenu({ initialFilename: filename, urlPath, publishedPageUrl }: Props): React.ReactElement {
  const dispatch = usePostEditorDispatch()

  const saveFilename = (newFilename: string): void => {
    dispatch({ type: 'editor/updatePostFilename', payload: { filename: newFilename } })
  }

  const publishPost = (): void => {
    dispatch({ type: 'editor/requestPublish', payload: {} })
  }

  const publishedUrlPath = publishedPageUrl === undefined ? urlPath : new URL(publishedPageUrl).pathname

  const urlPathPreview = publishedPageUrl ? (
    <>
      <a
        className={styles.link}
        href={publishedPageUrl}
        target={encodeURIComponent(publishedPageUrl)}
      >
        {publishedUrlPath}
      </a>{' '}
      <OpenInNewIcon className={styles.openUrlIcon} />
    </>
  ) : (
    'Not published'
  )

  return (
    <div className={styles.FileMenu}>
      <div className={styles.location}>
        <p className={styles.filename}>
          <InlineTextEditor
            className={styles.input}
            defaultValue={filename}
            onChange={saveFilename}
          />
        </p>
        <p className={styles.urlPath}>{urlPathPreview}</p>
      </div>
      <div className={styles.buttons}>
        <button
          type="button"
          className={styles.publishBtn}
          onClick={publishPost}
          aria-label="Publish"
        >
          Publish
        </button>
      </div>
    </div>
  )
}
