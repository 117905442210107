import type { Type } from 'io-ts'

import { FrameState20240603 } from './2024-06-03/FrameState20240603'
import mapFrameFrom20240603 from './2024-06-03/mapFrameFrom20240603'
import type { FrameState } from '../FrameState'

export const CURRENT_SCHEMA: Type<FrameState> = FrameState20240603

function mapLegacySchema(parsed: unknown): FrameState {
  // Base case
  if (CURRENT_SCHEMA.is(parsed)) {
    return parsed
  }

  // Check historical schemas in reverse-chronological order
  if (FrameState20240603.is(parsed)) {
    const nextSchema = mapFrameFrom20240603(parsed)

    return mapLegacySchema(nextSchema)
  }

  throw new Error(`Data does not match any known schema.`)
}

export default function parseFrame(json: string): FrameState {
  const parsed = JSON.parse(json) as unknown

  return mapLegacySchema(parsed)
}
