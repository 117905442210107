function toCodePoint(char: string): number {
  const result = char.codePointAt(0)
  if (result === undefined) {
    throw new Error(`Invalid char: ${char}`)
  }

  return result
}

function encode(bytes: Uint8Array): string {
  const byteString = Array.from(bytes, (b) => String.fromCodePoint(b)).join('')

  return btoa(byteString)
}

function decode(encoded: string): Uint8Array {
  const byteString = atob(encoded)

  return Uint8Array.from(byteString, toCodePoint)
}

export default {
  encode,
  decode,
}
