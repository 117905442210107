import type { FrameElement, FrameState } from './FrameState'

export function selectLastElement(state: FrameState): FrameElement | undefined {
  let maxArrIndex: number | undefined
  let highestElIndex = Number.NEGATIVE_INFINITY
  state.elements.forEach(({ index }, arrIdx) => {
    if (index > highestElIndex) {
      highestElIndex = index
      maxArrIndex = arrIdx
    }
  })

  return maxArrIndex === undefined ? undefined : state.elements[maxArrIndex]
}

export function selectFrameHeight(state: FrameState): number {
  return state.height
}

export function selectFrameElements(state: FrameState): FrameElement[] {
  const result = [...state.elements]
  result.sort((a, b) => a.index - b.index)

  return result
}
