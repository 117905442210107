import type React from 'react'

/**
 * CSS properties which accept numbers but are not in units of "px".
 *
 * Source: https://github.com/facebook/react/blob/c15579631ff4d387401d57d9006d849ca1d5cd71/packages/react-dom-bindings/src/shared/isUnitlessNumber.js
 */
const UNITLESS_NUMBER_STYLES = new Set<string>([
  'animation-iteration-count',
  'aspect-ratio',
  'border-image-outset',
  'border-image-slice',
  'border-image-width',
  'box-flex',
  'box-flex-group',
  'box-ordinal-group',
  'column-count',
  'columns',
  'flex',
  'flex-grow',
  'flex-positive',
  'flex-shrink',
  'flex-negative',
  'flex-order',
  'grid-area',
  'grid-row',
  'grid-row-end',
  'grid-row-span',
  'grid-row-start',
  'grid-column',
  'grid-column-end',
  'grid-column-span',
  'grid-column-start',
  'font-weight',
  'line-clamp',
  'line-height',
  'opacity',
  'order',
  'orphans',
  'scale',
  'tab-size',
  'widows',
  'z-index',
  'zoom',
  'fill-opacity', // SVG-related properties
  'flood-opacity',
  'stop-opacity',
  'stroke-dasharray',
  'stroke-dashoffset',
  'stroke-miter-limit',
  'stroke-opacity',
  'stroke-width',
])

function mapNumberValueToCssString(cssProp: string, value: number): string {
  if (value === 0) {
    return '0'
  }

  if (UNITLESS_NUMBER_STYLES.has(cssProp)) {
    return `${value}`
  }

  return `${value}px`
}

function mapPropNameToCss(propName: string): string {
  return propName.replace(/[A-Z]/g, (char) => `-${char.toLowerCase()}`)
}

function renderDeclaration(propName: string, value: string | number): string {
  const cssPropName = mapPropNameToCss(propName)

  const cssValue: string = typeof value === 'number' ? mapNumberValueToCssString(cssPropName, value) : value

  return `${cssPropName}:${cssValue};`
}

/**
 * - Order matters in CSS and so order of property declarations is preserved.
 * - Returns minified CSS (ie, with whitespace removed). This keeps output predictable for tests.
 */
export default function renderCssRule(selector: string, cssProps: React.CSSProperties): string {
  const declarations = Object.entries(cssProps)
    .map(([propName, value]) => renderDeclaration(propName, value))
    .join('')

  return `${selector}{${declarations}}`
}
