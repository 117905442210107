import { createContext, useContext } from 'react'

import type EmbeddedFile from '../../services/posts/files/EmbeddedFile'

export type EmbeddedFileUpdateHandler = (fileId: string, file: EmbeddedFile) => void

export const UpdateEmbeddedFileContext = createContext<EmbeddedFileUpdateHandler | undefined>(undefined)

export default function useUpdateEmbeddedFile(): EmbeddedFileUpdateHandler {
  const updateEmbeddedFile = useContext(UpdateEmbeddedFileContext)
  if (updateEmbeddedFile === undefined) {
    throw new Error(`UpdateEmbeddedFileContext is unexpectedly empty`)
  }

  return updateEmbeddedFile
}
