import FlowDocumentClassNames from './FlowDocumentClassNames'
import mapFlowDocumentToPageNode from './mapFlowDocumentToPageNode'
import FlowDocumentFileReader from '../../../posts/files/flow-document/FlowDocumentFileReader'
import type Stylesheet from '../../client/Stylesheet'
import type PublishingPlugin from '../PublishingPlugin'

/**
 * These styles apply to flow document fields within posts. They are used in several places:
 * 1. To style the content of the Tiptap editor in the Post Editor UI.
 * 2. They are embedded into Post templates.
 * 3. They are embedded into published pages and used when rendering page content in the browser runtime.
 *
 * Styles in posts are preserved within saved Post templates, meaning any changes to these styles will only effect
 * new posts created after the fact. Any existing posts will preserve their styles indefinitely. This is a desired
 * feature to help guarantee stability of users' content.
 *
 * Despite the above, new styles (ie, with a new class selector) will be backfilled into existing posts when they are
 * opened in the editor. This allows existing posts to benefit from new editor features.
 */
const FlowDocumentStylesheet: Stylesheet = {
  // Monospaced code
  [FlowDocumentClassNames.Code]: {
    fontFamily: 'monospace',
  },
  // Italic text
  [FlowDocumentClassNames.Emphasis]: {
    fontStyle: 'italic',
  },
  // Inline links
  [FlowDocumentClassNames.Hyperlink]: {
    color: 'rgb(0, 0, 238)',
    textDecoration: 'underline',
  },
  // Bold text
  [FlowDocumentClassNames.Strong]: {
    fontWeight: 700,
  },

  // Multiline blocks of pre-formatted, monospaced text
  [FlowDocumentClassNames.CodeBlock]: {
    padding: 16,
    fontFamily: 'monospace',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  // File binding blocks
  [FlowDocumentClassNames.FileBinding]: {
    display: 'block',
  },
  // List item
  [FlowDocumentClassNames.ListItem]: {
    listStyleType: 'disc',
    listStylePosition: 'outside',
  },
  // Bullet lists
  [FlowDocumentClassNames.List]: {
    paddingLeft: 32,
  },
} as const

export default {
  stylesheet: FlowDocumentStylesheet,
  mapToPageNode(context, file) {
    const { value } = FlowDocumentFileReader.openFile(file)

    return mapFlowDocumentToPageNode(context, value)
  },
} satisfies PublishingPlugin
