import type Post from './Post'
import PostTile from './PostTile'

import styles from './TileGrid.module.scss'

interface Props {
  posts: Post[]
}

export default function TileGrid({ posts }: Props): React.ReactElement {
  const tiles = posts.map((post) => (
    <PostTile
      key={post.postId}
      post={post}
    />
  ))

  return <div className={styles.TileGrid}>{tiles}</div>
}
