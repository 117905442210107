import type { ChangeEventHandler, FormEventHandler, KeyboardEventHandler } from 'react'
import { useState } from 'react'

import styles from './LinkMenu.module.scss'

interface Props {
  onSubmit: (value: string) => void
  onCancel: () => void
}
export default function LinkMenu({ onSubmit, onCancel }: Props): React.ReactElement {
  const [urlValue, setUrlValue] = useState('')

  const focusInput = (inputRef: HTMLInputElement | null): void => {
    if (inputRef === null) {
      return
    }

    inputRef.focus()
  }

  const handleInputChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value

    setUrlValue(newValue)
  }

  const handleFormSubmitted: FormEventHandler = (e) => {
    e.preventDefault()

    onSubmit(urlValue)
  }

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault()

      onCancel()
    }
  }

  return (
    <form
      className={styles.LinkMenu}
      aria-label="create link form"
      onSubmit={handleFormSubmitted}
    >
      <input
        ref={focusInput}
        className={styles.urlInput}
        type="text"
        placeholder="Paste URL"
        defaultValue={urlValue}
        aria-label="link URL"
        onChange={handleInputChanged}
        onKeyUp={handleKeyPress}
      />
    </form>
  )
}
