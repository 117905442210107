import getBootstrap from './getBootstrap'

export function isClientEnv(): boolean {
  return getBootstrap() !== undefined
}

export function readClientEnv(key: string): string | undefined {
  const bootstrap = getBootstrap()
  if (bootstrap === undefined) {
    return undefined
  }

  return bootstrap.env[key]
}
