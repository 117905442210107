import { createContext, useContext } from 'react'

type GetImageUrlFunc = (imageId: string) => string

export const GetImageUrlContext = createContext<GetImageUrlFunc>(() => {
  throw new Error(`GetImageUrlContext provider is not in scope.`)
})

export default function useGetImageUrl(): GetImageUrlFunc {
  return useContext(GetImageUrlContext)
}
