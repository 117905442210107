import * as t from 'io-ts'

/** Added 2024-02-06 */
const ImageRef = t.strict({
  imageId: t.string,
})

const RichText = t.strict({
  type: t.literal('rich-text'),
  classNames: t.array(t.string),
  text: t.string,
})
type RichText = t.TypeOf<typeof RichText>

/** Added 2024-03-09 */
const InlineHyperlink = t.strict({
  type: t.literal('inline-hyperlink'),
  classNames: t.array(t.string),
  href: t.string,
  content: t.array(RichText),
})

const InlineContent = t.union([RichText, InlineHyperlink])

const Paragraph = t.strict({
  type: t.literal('paragraph'),
  classNames: t.array(t.string),
  content: t.array(InlineContent),
})
type Paragraph = t.TypeOf<typeof Paragraph>

/** Added 2024-02-06 */
const Image = t.strict({
  type: t.literal('image'),
  classNames: t.array(t.string),
  ref: ImageRef,
})

const Block = t.union([Paragraph, Image])

const FlowDocument = t.strict({
  type: t.literal('document'),
  content: t.array(Block),
})
type FlowDocument = t.TypeOf<typeof FlowDocument>

export const FlowDocument20240118 = FlowDocument
export type FlowDocument20240118 = FlowDocument
