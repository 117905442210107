export function isServerEnv(): boolean {
  return typeof process === 'object' && 'env' in process
}

export function readServerEnv(key: string): string | undefined {
  if (!isServerEnv()) {
    return undefined
  }

  return process.env[key]
}
