import React from 'react'

import styles from './OverlayContainer.module.scss'

interface OverlayFrameProps {
  children: React.ReactNode
}

function OverlayFrame({ children }: OverlayFrameProps): React.ReactElement {
  return <div className={styles.OverlayFrame}>{children}</div>
}

interface Props {
  overlayContent: React.ReactNode
  children: React.ReactNode
}

export default function OverlayContainer({ overlayContent, children }: Props): React.ReactElement {
  return (
    <div className={styles.OverlayContainer}>
      <div className={styles.contentFrame}>{children}</div>
      <OverlayFrame>{overlayContent}</OverlayFrame>
    </div>
  )
}
