import type { TypeOf } from 'io-ts'

import { PostImage20240531 } from './2024-05-31/PostImage20240531'
import mapPostImageFrom20240531 from './2024-05-31/mapPostImageFrom20240531'
import { PostImage20240611 } from './2024-06-11/PostImage20240611'
import mapPostImageFrom20240611 from './2024-06-11/mapPostImageFrom20240611'

const CURRENT_SCHEMA = PostImage20240611
export type PostImage = TypeOf<typeof CURRENT_SCHEMA>

function mapLegacySchema(parsed: unknown): PostImage {
  // Base case
  if (CURRENT_SCHEMA.is(parsed)) {
    return parsed
  }

  // Test legacy schemas in reverse-chronological order
  if (PostImage20240611.is(parsed)) {
    const next = mapPostImageFrom20240611(parsed)

    return mapLegacySchema(next)
  }

  if (PostImage20240531.is(parsed)) {
    const next = mapPostImageFrom20240531(parsed)

    return mapLegacySchema(next)
  }

  throw new Error(`Value does not match any known schema.`)
}

export default function parsePostImage(data: Uint8Array): PostImage {
  const textDecoder = new TextDecoder('utf-8')
  const textData = textDecoder.decode(data)

  const parsed = JSON.parse(textData) as unknown

  return mapLegacySchema(parsed)
}
