import { type RouteObject } from 'react-router-dom'

import type AppRenderContext from '../AppRenderContext'
import HomeScreenBinding, { homeScreenDataLoader } from '../screens/HomeScreenBinding'
import PostEditorScreen, { postEditorScreenDataLoader } from '../screens/PostEditorScreen'
import ActionDemoScreen, { actionDemoScreenDataLoader } from '../screens/actions/ActionDemoScreen'
import LoginScreenBinding from '../screens/auth/LoginScreenBinding'
import SignUpScreenBinding from '../screens/auth/SignUpScreenBinding'
import KvDbEditorScreen, { kvDbEditorScreenDataLoader } from '../screens/kvdb/KvDbEditorScreen'

export default function buildAppRoutes({
  ctx,
  appConfig,
  workspaceController,
  postEditorBackend,
  kvDbEditorBackend,
}: AppRenderContext): RouteObject[] {
  return [
    {
      path: '/',
      loader: homeScreenDataLoader(ctx, appConfig, workspaceController),
      Component: HomeScreenBinding,
    },
    {
      path: '/signup', // cSpell:words signup
      Component: SignUpScreenBinding,
    },
    {
      path: '/login',
      Component: LoginScreenBinding,
    },
    {
      path: '/posts',
      children: [
        {
          path: ':postId/editor',
          loader: postEditorScreenDataLoader(postEditorBackend),
          Component: PostEditorScreen,
        },
      ],
    },
    {
      path: '/action-demo',
      loader: actionDemoScreenDataLoader(ctx),
      Component: ActionDemoScreen,
    },
    {
      path: '/kv-databases',
      loader: kvDbEditorScreenDataLoader(ctx, kvDbEditorBackend),
      Component: KvDbEditorScreen,
      children: [
        {
          path: ':databaseId',
          loader: kvDbEditorScreenDataLoader(ctx, kvDbEditorBackend),
          Component: KvDbEditorScreen,
        },
      ],
    },
  ]
}
