import React, { useEffect, useMemo } from 'react'

import type { SelectableChildContextData } from './SelectableChildContext'
import SelectableChildContext from './SelectableChildContext'
import { getChildIdFromKey, isNodeSelected } from '../selectors'
import useSelectionDispatch from '../useSelectionDispatch'
import useSelectionState from '../useSelectionState'

interface Props {
  childKey: string
  forceSelected?: boolean
  children: React.ReactNode
}

export default function SelectableChild({ childKey, forceSelected, children }: Props): React.ReactElement {
  const dispatch = useSelectionDispatch()
  const state = useSelectionState()

  const childNodeId = getChildIdFromKey(state, childKey)

  const isSelected = childNodeId !== undefined && isNodeSelected(state, childNodeId)

  useEffect(() => {
    if (forceSelected === undefined || childNodeId === undefined) {
      // Leave selection to the current context
      return
    }

    // Force a selection state
    if (forceSelected && !isSelected) {
      dispatch({ type: 'selection/selectElement', payload: { nodeId: childNodeId } })
    } else if (!forceSelected && isSelected) {
      dispatch({ type: 'selection/deselectElement', payload: { nodeId: childNodeId } })
    }
  }, [childNodeId, dispatch, forceSelected, isSelected])

  const childContextData: SelectableChildContextData | undefined = useMemo(() => ({ childKey }), [childKey])

  return <SelectableChildContext.Provider value={childContextData}>{children}</SelectableChildContext.Provider>
}
