import type InlineText from './InlineText'
import parseInlineText from './serialization/parseInlineText'
import type EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class InlineTextFileReader {
  private constructor(private readonly value: InlineText) {}

  get text(): string {
    return this.value.text
  }

  static openFile(file: EmbeddedFile): InlineTextFileReader {
    if (file.mediaType !== MediaTypes.InlineText) {
      throw new Error(`File has unexpected mediaType: ${file.mediaType}`)
    }

    const data = file.readAsText()
    const value = parseInlineText(data)

    return new InlineTextFileReader(value)
  }
}
