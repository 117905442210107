import { createContext, useContext } from 'react'

import { isNodeEditing, isNodeSelected } from './selectors'
import useSelectionState from './useSelectionState'

export interface SelectionNodeContextData {
  nodeId: string
}

export const SelectionNodeContext = createContext<SelectionNodeContextData | undefined>(undefined)

interface UseSelectionNodeResult {
  nodeId: string
  isSelected: boolean
  isEditing: boolean
}

export default function useSelectionNode(): UseSelectionNodeResult {
  const state = useSelectionState()
  const contextData = useContext(SelectionNodeContext)
  if (contextData === undefined) {
    throw new Error(`SelectionNodeContext is unexpectedly empty.`)
  }

  const { nodeId } = contextData
  const isSelected = isNodeSelected(state, nodeId)
  const isEditing = isNodeEditing(state, nodeId)

  return { nodeId, isSelected, isEditing }
}
