import { useContext, useEffect, useId } from 'react'

import SelectableChildContext from './containers/SelectableChildContext'
import useSelectionDispatch from './useSelectionDispatch'
import { SelectionNodeContext } from './useSelectionNode'

interface TreeNodeState {
  nodeId: string
}

export default function useSelectionTreeNode(idPrefix: string): TreeNodeState {
  const dispatch = useSelectionDispatch()

  const idSuffix = useId()

  const nodeId = `${idPrefix};${idSuffix}`

  const parentContext = useContext(SelectionNodeContext)
  const parentId = parentContext?.nodeId

  const selectableChild = useContext(SelectableChildContext)

  useEffect(() => {
    if (parentId !== undefined && selectableChild === undefined) {
      console.warn(`[useSelectionTreeNode] No selectable child context available for child of container. (${parentId})`)
    }

    const { childKey } = selectableChild ?? {}
    if (childKey !== undefined && parentId === undefined) {
      console.warn(
        `[useSelectionTreeNode] Found selectable child context outside of a selection container. (${childKey})`,
      )
    }

    dispatch({ type: 'selection/setNodeParent', payload: { childId: nodeId, childKey, parentId } })
  }, [dispatch, nodeId, parentId, selectableChild])

  return { nodeId }
}
