import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { produce } from 'immer'

import EditorModals from './EditorModals'
import FileMenu from './FileMenu'
import type { KeyDownEvent } from './KeyboardListener'
import KeyboardListener from './KeyboardListener'
import PostContent from './PostContent'
import PostContentProvider from './PostContentProvider'
import PostEditorFrontendController from './controller/PostEditorFrontendController'
import DebugPanel from './debug/DebugPanel'
import type { UploadImageHandler } from './images/useUploadImage'
import type { PostMetadata } from './state/PostEditorState'
import type PostEditorState from './state/PostEditorState'
import initialState from './state/initialState'
import type { PostEditorDispatch } from './state/usePostEditorDispatch'
import { PostEditorDispatchContext } from './state/usePostEditorDispatch'
import usePostEditorReducer from './state/usePostEditorReducer'
import { PostEditorStateContext } from './state/usePostEditorState'
import determineMissingStyles from './stylesheets/determineMissingStyles'
import OverlayContainer from './ui/OverlayContainer'
import { usePostEditorBackendClient, usePublishingPlugins } from '../app/services'
import type { PostEditorBackendClient } from '../post-editor-backend/client'
import type EmbeddedFileCollection from '../services/posts/client/EmbeddedFileCollection'
import type { PageTemplate, Stylesheet as TemplateStylesheet } from '../services/posts/client/PostContent'
import type EmbeddedFile from '../services/posts/files/EmbeddedFile'
import type Stylesheet from '../services/publishing/client/Stylesheet'

import styles from './PostEditor.module.scss'

function mapTemplateStylesheetToFlowDoc(templateStyles: TemplateStylesheet): Stylesheet {
  return templateStyles.rules.reduce<Stylesheet>((result, rule) => {
    const { type } = rule
    switch (type) {
      case 'class-rule': {
        const { className, cssStyle } = rule

        return Object.assign(result, { [className]: cssStyle })
      }
      default:
        throw new Error(`Unknown stylesheet rule type: ${type}`)
    }
  }, {})
}

function usePostEditorFrontendController(
  postId: string,
  backend: PostEditorBackendClient,
  imageUrlsById: Record<string, string>,
): PostEditorFrontendController {
  const imageUrlMap = useRef(new Map<string, string>(Object.entries(imageUrlsById)))

  // Sync image map
  useEffect(() => {
    // Replicate each entry from the state map to the ref map.
    Object.entries(imageUrlsById).forEach(([imageId, url]) => imageUrlMap.current.set(imageId, url))
  }, [imageUrlsById])

  return useMemo(() => new PostEditorFrontendController(postId, backend, imageUrlMap.current), [backend, postId])
}

interface LightboxProps {
  children?: React.ReactElement
}

function Lightbox({ children }: LightboxProps): React.ReactElement {
  return <div className={styles.Lightbox}>{children}</div>
}

function usePostSaving(state: PostEditorState, dispatch: PostEditorDispatch): void {
  const backend = usePostEditorBackendClient()

  const {
    postId,
    localFilenameVersion,
    localTemplateVersion,
    localEmbeddedFileVersions,
    backendVersion,
    pageTemplate,
    embeddedFiles,
    postMetadata: { filename },
  } = state
  const [isSaving, setIsSaving] = useState(false)
  useEffect(() => {
    const doAsyncSave = async (): Promise<void> => {
      if (isSaving) {
        return
      }

      setIsSaving(true)
      try {
        if (localFilenameVersion > backendVersion) {
          await backend.updateFilename(postId, filename)
          dispatch({ type: 'editor/updateBackendVersion', payload: { backendVersion: localFilenameVersion } })
        }
        if (localTemplateVersion > backendVersion) {
          await backend.updateTemplate(postId, pageTemplate)
          dispatch({ type: 'editor/updateBackendVersion', payload: { backendVersion: localTemplateVersion } })
        }

        const fileUpdates = Object.entries(localEmbeddedFileVersions)
          .filter(([, localFileVersion]) => localFileVersion > backendVersion)
          .map<Promise<void>>(async ([fileId, localFileVersion]) => {
            const file = embeddedFiles[fileId]
            if (file === undefined) {
              throw new Error(`Could not find file in local state. (${fileId})`)
            }

            await backend.updateEmbeddedFile(postId, fileId, file)
            dispatch({ type: 'editor/updateBackendVersion', payload: { backendVersion: localFileVersion } })
          })

        await Promise.all(fileUpdates)
      } finally {
        setIsSaving(false)
      }
    }

    doAsyncSave()
  }, [
    backend,
    backendVersion,
    dispatch,
    embeddedFiles,
    filename,
    isSaving,
    localEmbeddedFileVersions,
    localFilenameVersion,
    localTemplateVersion,
    pageTemplate,
    postId,
  ])
}

function usePostPublishing(state: PostEditorState, dispatch: PostEditorDispatch): void {
  const backend = usePostEditorBackendClient()

  const [isPublishing, setIsPublishing] = useState(false)
  const { postId, backendVersion, publishRequestedVersion } = state
  useEffect(() => {
    const doAsyncPublish = async (): Promise<void> => {
      if (isPublishing || publishRequestedVersion === null) {
        return
      }

      if (backendVersion >= publishRequestedVersion) {
        // Only publish when currently saved version is at least as recent as the version for which publishing was requested.
        setIsPublishing(true)
        try {
          const { pageUrl } = await backend.publishPost(postId)
          dispatch({ type: 'editor/completePublish', payload: { publishedVersion: backendVersion, pageUrl } })
        } finally {
          setIsPublishing(false)
        }
      }
    }

    doAsyncPublish()
  }, [backend, backendVersion, dispatch, isPublishing, postId, publishRequestedVersion])
}

interface Props {
  pageTemplate: PageTemplate
  embeddedFiles: EmbeddedFileCollection
  postMetadata: PostMetadata
  imageUrlsById: Record<string, string>
}

export default function PostEditor({
  pageTemplate: initialTemplate,
  embeddedFiles: initialFiles,
  postMetadata,
  imageUrlsById,
}: Props): React.ReactElement {
  const publishingPlugins = usePublishingPlugins()
  const backend = usePostEditorBackendClient()
  const [state, dispatch] = usePostEditorReducer(
    initialState(postMetadata, initialTemplate, initialFiles, imageUrlsById),
  )
  const { pageTemplate, embeddedFiles } = state

  const { postId, filename, urlPath, publishedPageUrl } = postMetadata

  const controller = usePostEditorFrontendController(postId, backend, imageUrlsById)

  // Save changes to the backend
  usePostSaving(state, dispatch)

  // Publish changes when requested
  usePostPublishing(state, dispatch)

  const handleEmbeddedFileChanged = useCallback(
    (fileId: string, updatedFile: EmbeddedFile): void => {
      dispatch({ type: 'editor/updateEmbeddedFile', payload: { fileId, file: updatedFile } })
    },
    [dispatch],
  )

  // Ensure template contains all styles required by the current editor
  useEffect(() => {
    const missingStyles = determineMissingStyles(publishingPlugins, pageTemplate.stylesheet)
    if (missingStyles.length === 0) {
      return
    }

    const updatedTemplate = produce<PageTemplate>(pageTemplate, (draft) => {
      draft.stylesheet.rules.push(...missingStyles)
    })

    dispatch({ type: 'editor/updatePageTemplate', payload: { pageTemplate: updatedTemplate } })
  }, [backend, dispatch, pageTemplate, postId, publishingPlugins])

  const stylesheet = useMemo(() => mapTemplateStylesheetToFlowDoc(pageTemplate.stylesheet), [pageTemplate.stylesheet])

  const getImageUrl = useCallback((imageId: string): string => controller.getImageUrl(imageId), [controller])
  const uploadImage: UploadImageHandler = useCallback(
    async (file) => {
      const { imageId } = await controller.createPostImage(file)

      return { postImageId: imageId }
    },
    [controller],
  )

  const createEmbeddedFile = useCallback(
    async (file: EmbeddedFile): Promise<{ fileId: string }> => {
      const { fileId } = await backend.createEmbeddedFile(postId, file.toJson())

      // Ensure the new file is immediately available in local state.
      // TODO: Prevent this from trigging a second write request (updateEmbeddedFile) to the backend.
      dispatch({ type: 'editor/updateEmbeddedFile', payload: { fileId, file } })

      return { fileId }
    },
    [backend, dispatch, postId],
  )

  const { showDebugPanel } = state
  const toggleDebugPanel = (): void => {
    dispatch({ type: 'editor/setDebugMenuVisible', payload: { visible: !showDebugPanel } })
  }

  /**
   * @returns true if the key triggered a shortcut (prevents event default behavior); otherwise false.
   */
  const handleKeyDown = (e: KeyDownEvent): boolean => {
    const { key, metaKey, shiftKey } = e
    switch (key.toUpperCase()) {
      case 'D': {
        if (metaKey && shiftKey) {
          toggleDebugPanel()

          return true
        }
        if (metaKey) {
          console.warn(`[PostEditor] Trying to open the debug panel? The shortcut is Cmd+Shift+D.`)

          return false
        }

        return false
      }
      default:
        // Indicate no shortcut was triggered
        return false
    }
  }

  const debugPanel = showDebugPanel ? <DebugPanel key="debug" /> : null

  return (
    <PostEditorStateContext.Provider value={state}>
      <PostEditorDispatchContext.Provider value={dispatch}>
        <PostContentProvider
          embeddedFiles={embeddedFiles}
          stylesheet={stylesheet}
          createEmbeddedFile={createEmbeddedFile}
          getImageUrl={getImageUrl}
          uploadImage={uploadImage}
          onEmbeddedFileChange={handleEmbeddedFileChanged}
        >
          <OverlayContainer overlayContent={[<EditorModals key="modals" />, debugPanel]}>
            <div className={styles.PostEditor}>
              <FileMenu
                initialFilename={filename}
                urlPath={urlPath}
                publishedPageUrl={publishedPageUrl}
              />

              <Lightbox>
                <PostContent />
              </Lightbox>
            </div>
          </OverlayContainer>

          <KeyboardListener onKeyDown={handleKeyDown} />
        </PostContentProvider>
      </PostEditorDispatchContext.Provider>
    </PostEditorStateContext.Provider>
  )
}
