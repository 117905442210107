import type { AriaAttributes } from 'react'
import React, { memo, useEffect, useState } from 'react'

// eslint-disable-next-line import/no-cycle
import FrameEditor from './frame/FrameEditor'
import PostImageEditor from './post-images/PostImageEditor'
import useEmbeddedFile from './useEmbeddedFile'
import useUpdateEmbeddedFile from './useUpdateEmbeddedFile'
import type EmbeddedFile from '../../services/posts/files/EmbeddedFile'
import MediaTypes from '../../services/posts/files/MediaTypes'
import InlineTextBindingField from '../binding-fields/InlineTextBindingField'
// eslint-disable-next-line import/no-cycle
import FlowDocumentBindingField from '../binding-fields/flow-document/FlowDocumentBindingField'
import SelectionTreeNode from '../selection/SelectionTreeNode'
import useSelection from '../selection/useSelection'
import useSelectionDispatch from '../selection/useSelectionDispatch'

const MemoPostImageEditor = memo(PostImageEditor)
const MemoInlineTextBindingField = memo(InlineTextBindingField)
const MemoFlowDocumentBindingField = memo(FlowDocumentBindingField)
const MemoFrameEditor = memo(FrameEditor)

interface TypeSpecificEditorProps extends Pick<AriaAttributes, 'aria-label'> {
  file: EmbeddedFile
  onChange: (file: EmbeddedFile) => void
  onFocus: () => void
  onBlur: () => void
}

function TypeSpecificEditor({
  file,
  'aria-label': ariaLabel,
  onChange,
  onFocus,
  onBlur,
}: TypeSpecificEditorProps): React.ReactElement {
  switch (file.mediaType) {
    case MediaTypes.PostImage:
      return <MemoPostImageEditor file={file} />
    case MediaTypes.InlineText:
      return (
        <MemoInlineTextBindingField
          file={file}
          aria-label={ariaLabel}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    case MediaTypes.FlowDocument:
      return (
        <MemoFlowDocumentBindingField
          file={file}
          aria-label={ariaLabel}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    case MediaTypes.Frame:
      return (
        <MemoFrameEditor
          file={file}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    default:
      throw new Error(`Unexpected media type: ${file.mediaType}`)
  }
}

const MemoSelectionTreeNode = memo(SelectionTreeNode)

interface Props extends Pick<AriaAttributes, 'aria-label'> {
  fileId: string
}

export default function FileEditor({ fileId, 'aria-label': ariaLabel }: Props): React.ReactElement {
  const updateEmbeddedFile = useUpdateEmbeddedFile()
  const selectionDispatch = useSelectionDispatch()

  const [clientLoaded, setClientLoaded] = useState(false)
  useEffect(() => {
    setClientLoaded(true)
  }, [])

  const file = useEmbeddedFile(fileId)
  const handleFileChanged = (updatedFile: EmbeddedFile): void => updateEmbeddedFile(fileId, updatedFile)

  const { nodeId, isEditing } = useSelection(file.mediaType)

  const handleEditorFocused = (): void => {
    selectionDispatch({ type: 'selection/focusNode', payload: { nodeId } })
  }

  const stopPropagationIfEditing = (e: React.SyntheticEvent): void => {
    if (isEditing) {
      e.stopPropagation()
    }
  }

  const editor = (
    <TypeSpecificEditor
      file={file}
      aria-label={ariaLabel}
      onChange={handleFileChanged}
      onFocus={handleEditorFocused}
      onBlur={() => {}}
    />
  )

  // Workaround to prevent SSR hydration errors due to invalid element nesting
  if (!clientLoaded) {
    return editor
  }

  return (
    <MemoSelectionTreeNode nodeId={nodeId}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={stopPropagationIfEditing}
        onDoubleClick={stopPropagationIfEditing}
      >
        {editor}
      </div>
    </MemoSelectionTreeNode>
  )
}
