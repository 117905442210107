import type { AriaAttributes } from 'react'
import React, { useEffect, useState } from 'react'

import type { Editor } from '@tiptap/core'
import type { EditorEvents } from '@tiptap/react'
import { BubbleMenu, EditorProvider, FloatingMenu } from '@tiptap/react'

import type RichTextClassNames from './RichTextClassNames'
import type { FileEditorComponent } from './extensions/FileBinding'
import BlockMenu from './menus/BlockMenu'
import type { FormattingOptions } from './menus/FormattingMenu'
import FormattingMenu from './menus/FormattingMenu'
import { RichTextDocument } from './model'
import useTiptapExtensions from './useTiptapExtensions'
import type { CreateFileOutput, EmbeddedFileCreateHandler } from '../../files/useCreateEmbeddedFile'

import styles from './RichTextEditor.module.scss'

/**
 * A feature-flag to facilitate dark-launching Frames
 */
const FLAG_ENABLE_FRAMES = false

const IMAGE_ALLOWED_MEDIA_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'] as const

const EDITOR_CLASS_NAME = styles.TipTapEditor

function isEmpty(json: RichTextDocument): boolean {
  if ((json.content?.length ?? 0) > 1) {
    return false
  }

  const [block] = json.content ?? []
  if (block === undefined) {
    return true
  }

  if (block.type !== 'paragraph') {
    return false
  }

  return (block.content?.length ?? 0) === 0
}

async function showFileSelector(): Promise<FileList> {
  // Create a new file input field
  const fileInput = document.createElement('input')
  fileInput.setAttribute('type', 'file')
  fileInput.setAttribute('accept', IMAGE_ALLOWED_MEDIA_TYPES.join(', '))
  fileInput.style.display = 'none'
  document.body.appendChild(fileInput)

  return new Promise<FileList>((resolve, reject) => {
    fileInput.addEventListener('change', () => {
      if (!fileInput.files) {
        reject(new Error('No files available.'))

        return
      }

      resolve(fileInput.files)
    })

    fileInput.click()
  }).finally(() => {
    fileInput.remove()
  })
}

interface PlaceholderProps {
  children: React.ReactNode
}
function Placeholder({ children }: PlaceholderProps): React.ReactElement {
  return <div className={styles.Placeholder}>{children}</div>
}

function StaticDisplay(): React.ReactElement {
  // TODO: Render content
  return <div />
}

interface Props extends Pick<AriaAttributes, 'aria-label'> {
  styleMap: RichTextClassNames
  defaultValue: RichTextDocument
  placeholder: string
  onChange: (value: RichTextDocument) => void
  onFocus: () => void
  onBlur: () => void
  uploadImage: (file: File) => Promise<{ fileId: string }>
  createCanvas: () => Promise<CreateFileOutput>
  createEmbeddedFile: EmbeddedFileCreateHandler
  FileEditorComponent: FileEditorComponent
}

export default function RichTextEditor({
  styleMap,
  defaultValue,
  'placeholder': placeholderText,
  'aria-label': ariaLabel,
  onChange,
  onFocus,
  onBlur,
  uploadImage,
  createCanvas,
  createEmbeddedFile,
  FileEditorComponent,
}: Props): React.ReactElement {
  const [clientLoaded, setClientLoaded] = useState(false)
  const [valueIsEmpty, setValueIsEmpty] = useState(isEmpty(defaultValue))
  const [editorIsFocused, setEditorIsFocused] = useState(false)
  const [selectionIsEmpty, setSelectionIsEmpty] = useState(true)
  const [formattingOptions, setFormattingOptions] = useState<FormattingOptions>({
    canToggleBold: false,
    canToggleItalic: false,
    canToggleCode: false,
    canSetLink: false,
    canUnsetLink: false,
  })

  // Avoid rendering editor during server-side render
  useEffect(() => setClientLoaded(true), [])

  const extensions = useTiptapExtensions(styleMap, FileEditorComponent, createEmbeddedFile)

  // Captures the default value indefinitely. Prevents changes to defaultValue from causing the content prop to change.
  const [initialValue] = useState(defaultValue)

  const [editor, setEditor] = useState<Editor | undefined>(undefined)

  const syncEditorStates = (updatedEditor: Editor): void => {
    setEditor(updatedEditor)
    setEditorIsFocused(updatedEditor.isFocused)
    setSelectionIsEmpty(updatedEditor.state.selection.empty)

    // This must run on both "update" and  "selectionUpdate" events because the latter will not fire when a link is removed.
    const hasLink = updatedEditor?.isActive('link') ?? false

    setFormattingOptions({
      canToggleBold: updatedEditor.can().toggleBold(),
      canToggleItalic: updatedEditor.can().toggleItalic(),
      canToggleCode: updatedEditor.can().toggleCode(),
      canSetLink: updatedEditor.can().setLink({ href: '#' }),
      canUnsetLink: updatedEditor.can().unsetLink() && hasLink,
    })
  }

  const handleEditorCreated = ({ editor: createdEditor }: EditorEvents['create']): void => {
    syncEditorStates(createdEditor)
  }

  /**
   * Note: this is only invoked when the content changes, not when the selection changes.
   */
  const handleContentUpdated = ({ editor: updatedEditor }: EditorEvents['update']): void => {
    syncEditorStates(updatedEditor)

    const json = updatedEditor.getJSON()
    if (!RichTextDocument.is(json)) {
      // Do you need to update model.ts after adding a new node type?
      console.error(`[handleContentUpdated] Unexpected JSONContent schema:`, json)
      throw new Error(`JSONContent from Tiptap does not match the expected schema.`)
    }

    setValueIsEmpty(isEmpty(json))

    onChange(json)
  }

  const handleSelectionUpdated = ({ editor: updatedEditor }: EditorEvents['selectionUpdate']): void => {
    syncEditorStates(updatedEditor)
  }

  const handleEditorFocused = (): void => {
    setEditorIsFocused(true)

    onFocus()
  }
  const handleEditorBlurred = (): void => {
    setEditorIsFocused(false)

    onBlur()
  }

  const attributes: Record<string, string> = {}

  if (EDITOR_CLASS_NAME) {
    attributes.class = EDITOR_CLASS_NAME
  }

  if (ariaLabel) {
    attributes['aria-label'] = ariaLabel
  }

  const editorProps = { attributes }

  const toggleBold = (): void => {
    editor?.chain().focus().toggleBold().run()
  }

  const toggleItalic = (): void => {
    editor?.chain().focus().toggleItalic().run()
  }

  const toggleCode = (): void => {
    editor?.chain().focus().toggleCode().run()
  }

  const handleInsertImageClicked = (): void => {
    const promptForImage = async (): Promise<void> => {
      const files = await showFileSelector()
      if (files.length === 0) {
        return
      }

      const uploads = Array.from(files, (file) => uploadImage(file).then(({ fileId }) => fileId))

      const imageFileIds = await Promise.all(uploads)

      imageFileIds.forEach((fileId) => {
        editor?.chain().focus().setFileBinding({ fileId }).run()
      })
    }
    promptForImage()
  }

  const handleInsertCanvasClicked = (): void => {
    const asyncInsert = async (): Promise<void> => {
      const { fileId: canvasFileId } = await createCanvas()
      editor?.chain().focus().setFileBinding({ fileId: canvasFileId }).run()
    }
    asyncInsert()
  }

  const createLink = (urlValue: string): void => {
    editor?.chain().focus().setLink({ href: urlValue }).run()
  }

  const removeLink = (): void => {
    editor?.chain().focus().unsetLink().run()
  }

  const refocus = (): void => {
    editor?.commands.focus()
  }

  if (!clientLoaded) {
    return <StaticDisplay />
  }

  // TODO: Lift the placeholder implementation up to FlowDocumentBindingField
  const showPlaceholder = valueIsEmpty && !editorIsFocused
  const placeholder = showPlaceholder ? <Placeholder>{placeholderText}</Placeholder> : null

  // This resets the menu state (eg, close the link menu) when the selection changes.
  const formattingMenu = selectionIsEmpty ? null : (
    <FormattingMenu
      formattingOptions={formattingOptions}
      onClickBold={toggleBold}
      onClickCode={toggleCode}
      onClickItalic={toggleItalic}
      onSetLink={createLink}
      onUnsetLink={removeLink}
      onEndInteraction={refocus}
    />
  )

  return (
    <div className={styles.RichTextEditor}>
      {placeholder}
      <EditorProvider
        extensions={extensions}
        content={initialValue}
        editorProps={editorProps}
        onCreate={handleEditorCreated}
        onUpdate={handleContentUpdated}
        onSelectionUpdate={handleSelectionUpdated}
        onFocus={handleEditorFocused}
        onBlur={handleEditorBlurred}
      >
        <BubbleMenu updateDelay={0}>{formattingMenu}</BubbleMenu>
        <FloatingMenu>
          <BlockMenu
            enableFrames={FLAG_ENABLE_FRAMES}
            onClickInsertImage={handleInsertImageClicked}
            onClickInsertCanvas={handleInsertCanvasClicked}
          />
        </FloatingMenu>
      </EditorProvider>
    </div>
  )
}
