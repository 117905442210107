import React, { useCallback, useEffect, useState } from 'react'

import type { AnyCommands, Command, Editor } from '@tiptap/core'
import { mergeAttributes, Node } from '@tiptap/core'
import type { Slice } from '@tiptap/pm/model'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import type { EditorView } from '@tiptap/pm/view'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'

import type EmbeddedFile from '../../../../services/posts/files/EmbeddedFile'
import { getEmbeddedFile, hasEmbeddedFileJson } from '../../../drag-drop/draggingFileData'
import type DropEffect from '../../../files/DropEffect'
import FileDragSource, { startDraggingFile } from '../../../files/FileDragSource'
import type { EmbeddedFileCreateHandler } from '../../../files/useCreateEmbeddedFile'
import useEmbeddedFile from '../../../files/useEmbeddedFile'
import SelectableChild from '../../../selection/containers/SelectableChild'
import useSelectableChild from '../../../selection/containers/useSelectableChild'
import useSelectionDispatch from '../../../selection/useSelectionDispatch'
import useSelectionNode from '../../../selection/useSelectionNode'
import type { JsonFileBinding } from '../model'

/** Reference: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values */
const KEY_ENTER = 'Enter'

interface SetFileBindingOptions {
  fileId: string
}

/** cSpell:words skippable */
interface SkippableEvent extends Event {
  skipProsemirror?: boolean
}

function skippableEventHandler(_view: EditorView, event: SkippableEvent): boolean {
  if (event.skipProsemirror !== undefined && event.skipProsemirror) {
    // Signal to prosemirror that the event has been handled, skipping prosemirror's default handlers.
    return true
  }

  return false
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fileBinding: {
      setFileBinding: (options: SetFileBindingOptions) => ReturnType
    }
  }
}

interface FileEditorComponentProps {
  fileId: string
}
export type FileEditorComponent = React.ComponentType<FileEditorComponentProps>

/** Reference: https://tiptap.dev/docs/editor/guide/node-views/react#all-available-props */
interface FileEditorWrapperProps {
  editor?: Editor
  node?: {
    attrs: {
      fileId: string
    }
  }
  selected?: boolean
}

interface ConfigOptions {
  HTMLAttributes: Record<string, string>
  FileEditorComponent: FileEditorComponent
  createEmbeddedFile: EmbeddedFileCreateHandler
  onDrop: (dropEffect: DropEffect) => void
}

function readEventPosition(view: EditorView, event: DragEvent): { pos: number; inside: number } {
  const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
  if (coordinates === null) {
    throw new Error(`Event position does not map to document coordinates.`)
  }

  return coordinates
}

const wrapFileEditor = ({
  FileEditorComponent,
  HTMLAttributes,
}: ConfigOptions): React.ComponentType<FileEditorWrapperProps> =>
  function Wrapper({
    editor,
    node,
    selected: proseMirrorNodeSelected = false,
  }: FileEditorWrapperProps): React.ReactElement {
    if (editor === undefined) {
      throw new Error(`editor is undefined`)
    }
    if (node === undefined) {
      throw new Error(`node is undefined`)
    }
    const selectionDispatch = useSelectionDispatch()

    const { fileId } = node.attrs

    const file = useEmbeddedFile(fileId)
    const fileJson = file.toJson()

    const { isEditing: isEditorInteractive } = useSelectionNode()
    const { childKey, childNodeId, isSelected } = useSelectableChild()

    const handleDragStart = (e: React.DragEvent): boolean => {
      // manually setup dragging
      startDraggingFile(fileId, fileJson, e)

      const { selection, doc } = editor.view.state

      const { pos: dragPos } = readEventPosition(editor.view, e.nativeEvent)
      const draggingNode = doc.nodeAt(dragPos)

      if (draggingNode?.type.name !== 'fileBinding') {
        throw new Error(`Expected dragging node to be fileBinding. Found: ${draggingNode?.type.name}`)
      }

      // Manually set the prosemirror dragging state
      let draggingSlice: Slice
      if (selection.from <= dragPos && selection.to >= dragPos) {
        draggingSlice = selection.content()
      } else {
        // If this occurs in practice, manually create the slice and update the selection.
        throw new Error(`Event coordinates are not within current selection.`)
      }

      editor.view.dragging = {
        slice: draggingSlice,
        move: true,
      }

      return true
    }

    const handleDragEnd = (e: React.DragEvent, didDrop: boolean): void => {
      if (!didDrop) {
        return
      }

      const { dropEffect } = e.dataTransfer
      if (dropEffect === 'move') {
        editor.commands.deleteSelection()
      }
    }

    const isolateInteractionEvents = useCallback(
      (e: SkippableEvent): void => {
        if (isEditorInteractive && isSelected) {
          // Prevent interaction event from bubbling up to Prosemirror when the user is interacting with this file editor
          e.skipProsemirror = true
        }
      },
      [isEditorInteractive, isSelected],
    )

    const [domNode, setDomNode] = useState<HTMLDivElement | null>(null)
    useEffect(() => {
      if (domNode === null) {
        return
      }

      if (isSelected && document.activeElement !== domNode) {
        domNode.focus()
      }
    }, [domNode, isSelected])
    useEffect(() => {
      if (domNode === null) {
        return () => {}
      }

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        domNode.setAttribute(key, value)
      })

      /** Prevents interaction events from bubbling up to prosemirror */
      const skipProsemirror = (e: SkippableEvent): void => {
        e.skipProsemirror = true
      }

      const handleNativeMouseDown = (e: SkippableEvent & MouseEvent): void => {
        if (!isEditorInteractive) {
          // Prevent mouse event from bubbling up to Prosemirror when the user is interacting with this file editor
          e.skipProsemirror = true
        }
      }

      // Must be attached as a native DOM event listener (not react) so that events are caught before propagating to prosemirror
      domNode.addEventListener('mousedown', handleNativeMouseDown)

      domNode.addEventListener('mouseup', isolateInteractionEvents)
      domNode.addEventListener('touchstart', isolateInteractionEvents)
      domNode.addEventListener('touchmove', isolateInteractionEvents)
      domNode.addEventListener('touchend', isolateInteractionEvents)
      domNode.addEventListener('contextmenu', isolateInteractionEvents)
      domNode.addEventListener('copy', skipProsemirror)
      domNode.addEventListener('drag', skipProsemirror)
      domNode.addEventListener('dragstart', skipProsemirror)
      domNode.addEventListener('dragover', skipProsemirror)
      domNode.addEventListener('dragenter', skipProsemirror)
      domNode.addEventListener('dragend', skipProsemirror)
      domNode.addEventListener('drop', skipProsemirror)
      domNode.addEventListener('beforeinput', isolateInteractionEvents) // cSpell:words beforeinput

      return () => {
        domNode.removeEventListener('mousedown', handleNativeMouseDown)

        domNode.removeEventListener('mouseup', isolateInteractionEvents)
        domNode.removeEventListener('touchstart', isolateInteractionEvents)
        domNode.removeEventListener('touchmove', isolateInteractionEvents)
        domNode.removeEventListener('touchend', isolateInteractionEvents)
        domNode.removeEventListener('contextmenu', isolateInteractionEvents)
        domNode.removeEventListener('copy', skipProsemirror)
        domNode.removeEventListener('drag', skipProsemirror)
        domNode.removeEventListener('dragstart', skipProsemirror)
        domNode.removeEventListener('dragover', skipProsemirror)
        domNode.removeEventListener('dragenter', skipProsemirror)
        domNode.removeEventListener('dragend', skipProsemirror)
        domNode.removeEventListener('drop', skipProsemirror)
        domNode.removeEventListener('beforeinput', isolateInteractionEvents) // cSpell:words beforeinput
      }
    }, [domNode, isEditorInteractive, isolateInteractionEvents])

    useEffect(() => {
      if (domNode === null) {
        return () => {}
      }

      const handleNativeKeyDown = (e: KeyboardEvent & SkippableEvent): void => {
        // Handle selection-related keyboard events when this file is selected.
        if (isSelected && e.key === KEY_ENTER) {
          if (childNodeId === undefined) {
            throw new Error(`Selection node ID is not available.`)
          }

          e.stopPropagation()
          e.skipProsemirror = true

          // TODO: Only start editing if a) node has children to select; or b) the node is otherwise editable (eg, flow doc)
          selectionDispatch({ type: 'selection/startEditing', payload: { nodeId: childNodeId } })
        } else if (isSelected && ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
          editor.view.focus()

          // Allow event to propagate up
          return
        }

        isolateInteractionEvents(e)
      }

      domNode.addEventListener('keydown', handleNativeKeyDown)

      return () => {
        domNode.removeEventListener('keydown', handleNativeKeyDown)
      }
    }, [childNodeId, domNode, editor.view, isSelected, isolateInteractionEvents, selectionDispatch])

    /** Sync selected state */
    const forceSelection = isEditorInteractive ? proseMirrorNodeSelected : undefined

    return (
      <NodeViewWrapper>
        <div
          ref={setDomNode}
          /** Required by Tiptap to make element draggable. */
          data-drag-handle
          tabIndex={-1}
          style={{ outline: 'none' }}
        >
          <SelectableChild
            childKey={childKey}
            forceSelected={forceSelection}
          >
            <FileDragSource
              fileId={fileId}
              draggable
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <FileEditorComponent fileId={fileId} />
            </FileDragSource>
          </SelectableChild>
        </div>
      </NodeViewWrapper>
    )
  }

function insertFileAtPosition(fileId: string, pos: number): Command {
  const fileBindingJson: JsonFileBinding = {
    type: 'fileBinding',
    attrs: {
      fileId,
    },
  }

  return ({ state, commands }) => {
    const $pos = state.doc.resolve(pos)

    let insertPos = pos
    if ($pos.parentOffset === 0) {
      // Insert at the cursor, unless the cursor is at the start of a block. In that case, insert before the current block.
      // Source: https://github.com/ueberdosis/tiptap/blob/1e562ec7dae682cc720619d87c91302442baeff5/packages/extension-horizontal-rule/src/horizontal-rule.ts#L56C11-L60C12
      insertPos = Math.max($pos.pos - 2, 0)
    }

    return commands.insertContentAt(insertPos, fileBindingJson)
  }
}

const FileBinding = Node.create<ConfigOptions>({
  name: 'fileBinding',
  group: 'block',
  atom: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      FileEditorComponent: () => null,
      createEmbeddedFile: () => {
        throw new Error(`createEmbeddedFile has not been initialized`)
      },
      onDrop: () => {},
    }
  },

  addAttributes() {
    return {
      fileId: {
        default: null,
      },
    }
  },

  addCommands(): AnyCommands {
    return {
      setFileBinding:
        (options: SetFileBindingOptions) =>
        ({ state, commands }) => {
          const { fileId } = options
          const { to: insertionPosition } = state.selection

          const insertCmd = insertFileAtPosition(fileId, insertionPosition)

          return commands.command(insertCmd)
        },
    }
  },

  addNodeView() {
    const WrappedComponent = wrapFileEditor(this.options)

    return ReactNodeViewRenderer(WrappedComponent)
  },

  renderHTML({ HTMLAttributes }) {
    return ['file-binding', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  parseHTML() {
    return [{ tag: 'file-binding' }]
  },

  addProseMirrorPlugins() {
    const insertFile = async (file: EmbeddedFile, pos: number): Promise<void> => {
      const { createEmbeddedFile } = this.options

      const { fileId } = await createEmbeddedFile(file)

      const insertCmd = insertFileAtPosition(fileId, pos)

      this.editor.commands.command(insertCmd)
    }

    const handleDrop = (view: EditorView, event: DragEvent): boolean => {
      const { onDrop } = this.options

      const hasFileJson = event.dataTransfer !== null && hasEmbeddedFileJson(event.dataTransfer)
      if (!hasFileJson) {
        return false
      }

      event.preventDefault()

      const { dropEffect } = event.dataTransfer
      onDrop(dropEffect)

      const { pos: dropPosition } = readEventPosition(view, event)

      const file = getEmbeddedFile(event.dataTransfer)

      insertFile(file, dropPosition)

      return true
    }

    return [
      new Plugin({
        key: new PluginKey('FileBinding-dropEvents'),
        props: {
          handleDOMEvents: {
            mouseup: skippableEventHandler,
            mousedown: skippableEventHandler,
            keydown: skippableEventHandler,
            touchstart: skippableEventHandler,
            touchmove: skippableEventHandler,
            touchend: skippableEventHandler,
            contextmenu: skippableEventHandler,
            copy: skippableEventHandler,
            drag: skippableEventHandler,
            dragstart: skippableEventHandler,
            dragover: skippableEventHandler,
            dragenter: skippableEventHandler,
            dragend: skippableEventHandler,
            drop: skippableEventHandler,
            beforeinput: skippableEventHandler,
          },
          handleDrop,
        },
      }),
    ]
  },
})

export default FileBinding
