import { createContext, useContext } from 'react'

import type EmbeddedFile from '../../services/posts/files/EmbeddedFile'

export type CreateFileOutput = { fileId: string }
export type EmbeddedFileCreateHandler = (file: EmbeddedFile) => Promise<CreateFileOutput>

export const CreateEmbeddedFileContext = createContext<EmbeddedFileCreateHandler | undefined>(undefined)

export default function useCreateEmbeddedFile(): EmbeddedFileCreateHandler {
  const createEmbeddedFile = useContext(CreateEmbeddedFileContext)
  if (createEmbeddedFile === undefined) {
    throw new Error(`CreateEmbeddedFileContext is unexpectedly empty`)
  }

  return createEmbeddedFile
}
