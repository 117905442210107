import React from 'react'

import styles from './ErrorBoundary.module.scss'

interface ErrorMessageProps {
  label?: string
  message: string
  stack?: string
  componentStack?: string
}

function ErrorMessage({ label, message, stack, componentStack }: ErrorMessageProps): React.ReactElement {
  const heading = `Runtime Error${label ? ` (${label})` : ''}`

  const traces: React.ReactNode[] = []
  if (stack !== undefined) {
    traces.push(
      <div
        key="stack"
        className={styles.block}
      >
        <h2 className={styles.subheading}>JS Stack</h2>

        <pre className={styles.stack}>{stack}</pre>
      </div>,
    )
  }
  if (componentStack !== undefined) {
    traces.push(
      <div
        key="component-stack"
        className={styles.block}
      >
        <h2 className={styles.subheading}>Component Stack</h2>
        <pre className={styles.componentStack}>{componentStack}</pre>
      </div>,
    )
  }

  return (
    <div className={styles.ErrorMessage}>
      <h1 className={styles.heading}>{heading}</h1>
      <pre className={styles.message}>{message}</pre>
      {traces}
    </div>
  )
}

interface Props {
  label?: string
  children: React.ReactNode
}

interface State {
  message: string | undefined
  stack: string | undefined
  componentStack: string | undefined
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      message: undefined,
      stack: undefined,
      componentStack: undefined,
    }
  }

  /** @public */
  static getDerivedStateFromError(err: Error): Partial<State> {
    const { message, stack } = err

    return {
      message,
      stack,
    }
  }

  /** @public */
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { message } = this.state
    if (message === undefined) {
      // Note: update this assumption if necessary.
      throw new Error(`Expected error to be set before invoking componentDidCatch().`)
    }

    // Verify this is the same error that's currently displayed
    if (error.message !== message) {
      console.warn(`[ErrorBoundary] Received different error than current:`, { error, errorInfo })

      return
    }

    const { componentStack } = errorInfo
    this.setState({ componentStack })
  }

  /** @public */
  render(): React.ReactNode {
    const { label, children } = this.props
    const { message, stack, componentStack } = this.state

    if (message !== undefined) {
      return (
        <ErrorMessage
          label={label}
          message={message}
          stack={stack}
          componentStack={componentStack}
        />
      )
    }

    return children
  }
}
