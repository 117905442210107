import type { AriaAttributes, MouseEventHandler } from 'react'
import React, { useState } from 'react'

import LinkMenu from './LinkMenu'

import styles from './FormattingMenu.module.scss'

export interface FormattingOptions {
  canToggleBold: boolean
  canToggleItalic: boolean
  canToggleCode: boolean
  canSetLink: boolean
  canUnsetLink: boolean
}

interface ButtonProps extends Pick<AriaAttributes, 'aria-label'> {
  children: React.ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
}

function Button({ 'aria-label': ariaLabel, children, onClick }: ButtonProps): React.ReactElement {
  return (
    <button
      className={styles.button}
      type="button"
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

interface Props {
  formattingOptions: FormattingOptions
  onClickBold: () => void
  onClickCode: () => void
  onClickItalic: () => void
  onSetLink: (linkUrl: string) => void
  onUnsetLink: () => void
  onEndInteraction: () => void
}

export default function FormattingMenu({
  formattingOptions,
  onClickBold,
  onClickCode,
  onClickItalic,
  onSetLink,
  onUnsetLink,
  onEndInteraction,
}: Props): React.ReactElement {
  const [showLinkForm, setShowLinkForm] = useState(false)

  const closeLinkForm = (): void => {
    setShowLinkForm(false)
    onEndInteraction()
  }

  const handleSetLinkClicked: React.MouseEventHandler = (e) => {
    e.preventDefault()

    if (showLinkForm) {
      closeLinkForm()

      return
    }

    setShowLinkForm(true)
  }

  const handleLinkFormSubmitted = (urlValue: string): void => {
    closeLinkForm()

    onSetLink(urlValue)
  }

  const boldButton = formattingOptions.canToggleBold ? (
    <Button
      aria-label="bold"
      onClick={onClickBold}
    >
      Bold
    </Button>
  ) : null

  const italicButton = formattingOptions.canToggleItalic ? (
    <Button
      aria-label="italic"
      onClick={onClickItalic}
    >
      Italic
    </Button>
  ) : null

  const linkButton = formattingOptions.canSetLink ? (
    <Button
      aria-label="link"
      onClick={handleSetLinkClicked}
    >
      Link
    </Button>
  ) : null
  const unlinkButton = formattingOptions.canUnsetLink ? (
    <Button
      aria-label="link"
      onClick={onUnsetLink}
    >
      Unlink
    </Button>
  ) : null

  const codeButton = formattingOptions.canToggleCode ? (
    <Button
      aria-label="code"
      onClick={onClickCode}
    >
      Code
    </Button>
  ) : null

  const linkMenu = showLinkForm ? (
    <div className={styles.linkMenu}>
      {' '}
      <LinkMenu
        onSubmit={handleLinkFormSubmitted}
        onCancel={closeLinkForm}
      />
    </div>
  ) : null

  return (
    <div
      className={styles.FormattingMenu}
      aria-label="formatting menu"
    >
      {boldButton}
      {italicButton}
      {linkButton}
      {unlinkButton}
      {codeButton}
      {linkMenu}
    </div>
  )
}
