import classNames from 'classnames'

import MenuCloseIcon from './MenuCloseIcon.svg'
import MenuIcon from './MenuIcon.svg'

import styles from './MenuButton.module.scss'

interface MenuButtonProps {
  className?: string
  isOpen: boolean
  onClickOpen: () => void
  onClickClose: () => void
}

export default function MenuButton({
  className,
  isOpen,
  onClickOpen,
  onClickClose,
}: MenuButtonProps): React.ReactElement {
  const icon = isOpen ? <MenuCloseIcon className={styles.icon} /> : <MenuIcon className={styles.icon} />
  const accessLabel = isOpen ? 'close menu' : 'open menu'

  const handleButtonClicked = isOpen ? onClickClose : onClickOpen

  return (
    <button
      className={classNames(styles.MenuButton, className)}
      type="button"
      aria-label={accessLabel}
      onClick={handleButtonClicked}
    >
      {icon}
    </button>
  )
}
