import { FlowDocument20240118 } from './FlowDocument20240118'
import type {
  Block20240503,
  Image20240503,
  InlineContent20240503,
  InlineHyperlink20240503,
  List20240503,
  ListItem20240503,
  Paragraph20240503,
  RichText20240503,
} from './FlowDocument20240503'
import { FlowDocument20240503 } from './FlowDocument20240503'
import type {
  FlowBlockNode20240515,
  FlowImageElement20240515,
  FlowInlineNode20240515,
  FlowLinkElement20240515,
  FlowListElement20240515,
  FlowListItemElement20240515,
  FlowParagraphElement20240515,
} from './FlowDocument20240515'
import { FlowDocument20240515 } from './FlowDocument20240515'
import type FlowDocument from '../FlowDocument'

const CURRENT_SCHEMA = FlowDocument20240515

const CODE_STYLE_CLASS_20240503 = 'code'
const EMPHASIS_STYLE_CLASS_20240503 = 'emphasis'
const STRONG_STYLE_CLASS_20240503 = 'strong'

function mapFlowDocumentFrom20240515(parsed: FlowDocument20240515): FlowDocument {
  // Current schema
  return parsed
}

function stripClassNameFromRichText20240503(richText: RichText20240503, className: string): RichText20240503 {
  const { classNames } = richText
  const remainingClassNames = classNames.filter((curClass) => curClass !== className)

  return {
    ...richText,
    classNames: remainingClassNames,
  }
}

function mapRichTextFrom20240503(richText: RichText20240503): FlowInlineNode20240515 {
  const { text, classNames } = richText

  if (classNames.includes(STRONG_STYLE_CLASS_20240503)) {
    const remainingText = stripClassNameFromRichText20240503(richText, STRONG_STYLE_CLASS_20240503)
    const childNodes = mapRichTextFrom20240503(remainingText)

    return {
      type: 'strong',
      content: [childNodes],
    }
  }

  if (classNames.includes(EMPHASIS_STYLE_CLASS_20240503)) {
    const remainingText = stripClassNameFromRichText20240503(richText, EMPHASIS_STYLE_CLASS_20240503)
    const childNodes = mapRichTextFrom20240503(remainingText)

    return {
      type: 'emphasis',
      content: [childNodes],
    }
  }

  if (classNames.includes(CODE_STYLE_CLASS_20240503)) {
    const remainingText = stripClassNameFromRichText20240503(richText, CODE_STYLE_CLASS_20240503)
    const childNode = mapRichTextFrom20240503(remainingText)

    return {
      type: 'inline-code',
      content: [childNode],
    }
  }

  // Return a plain text node when there are no styles
  return {
    type: 'text',
    text,
  }
}

function mapInlineHyperlinkFrom20240503(hyperlink: InlineHyperlink20240503): FlowLinkElement20240515 {
  const {
    href,
    // Property `classNames` is dropped. In practice, the value was always exactly `["hyperlink"]`
    content,
  } = hyperlink

  const mappedContent = content.map(mapRichTextFrom20240503)

  return {
    type: 'inline-hyperlink',
    href,
    content: mappedContent,
  }
}

function mapInlineContentFrom20240503(inlineContent: InlineContent20240503): FlowInlineNode20240515 {
  const { type } = inlineContent

  switch (type) {
    case 'rich-text':
      return mapRichTextFrom20240503(inlineContent)
    case 'inline-hyperlink':
      return mapInlineHyperlinkFrom20240503(inlineContent)
    default:
      throw new Error(`Unknown inline content type: ${type}`)
  }
}

function mapParagraphFrom20240503(paragraph: Paragraph20240503): FlowParagraphElement20240515 {
  const {
    content,
    // Note: the property `classNames` is dropped. In practice, the value should have always been empty.
  } = paragraph

  const mappedContent = content.map(mapInlineContentFrom20240503)

  return {
    type: 'paragraph',
    content: mappedContent,
  }
}

function mapListItemFrom20240503(listItem: ListItem20240503): FlowListItemElement20240515 {
  return {
    type: 'list-item',
    // Property `classNames` is dropped. The value was always exactly `["list-item"]`
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    content: listItem.content.map(mapBlockFrom20240503),
  }
}

function mapListFrom20240503(list: List20240503): FlowListElement20240515 {
  return {
    type: 'list',
    // Property `classNames` is dropped. The value was always exactly `["list"]`
    items: list.items.map(mapListItemFrom20240503),
  }
}

function mapImageFrom20240503(image: Image20240503): FlowImageElement20240515 {
  return {
    type: 'image',
    // Property `classNames` is dropped. The value was always exactly `["image"]`
    ref: image.ref,
  }
}

function mapBlockFrom20240503(block: Block20240503): FlowBlockNode20240515 {
  const { type: blockType } = block

  switch (blockType) {
    case 'paragraph':
      return mapParagraphFrom20240503(block)
    case 'image':
      return mapImageFrom20240503(block)
    case 'list':
      return mapListFrom20240503(block)
    default:
      throw new Error(`Unknown block type: ${blockType}`)
  }
}

function mapFlowDocumentFrom20240503(flowDoc: FlowDocument20240503): FlowDocument20240515 {
  return {
    ...flowDoc,
    content: flowDoc.content.map(mapBlockFrom20240503),
  }
}

function mapFlowDocumentFrom20240118(flowDoc20240118: FlowDocument20240118): FlowDocument20240503 {
  // Compatible schema
  return flowDoc20240118
}

function mapLegacySchema(parsed: unknown): FlowDocument {
  // Base case
  if (CURRENT_SCHEMA.is(parsed)) {
    return parsed
  }

  // Test schemas in reverse-chronological order.
  if (FlowDocument20240515.is(parsed)) {
    const next = mapFlowDocumentFrom20240515(parsed)

    return mapLegacySchema(next)
  }

  if (FlowDocument20240503.is(parsed)) {
    const flowDoc20240515 = mapFlowDocumentFrom20240503(parsed)

    return mapLegacySchema(flowDoc20240515)
  }

  if (FlowDocument20240118.is(parsed)) {
    const flowDoc20240503 = mapFlowDocumentFrom20240118(parsed)

    return mapLegacySchema(flowDoc20240503)
  }

  throw new Error(`Parsed json does not match any known schema.`)
}

export default function parseFlowDocument(data: string): FlowDocument {
  const json = JSON.parse(data) as unknown

  return mapLegacySchema(json)
}
