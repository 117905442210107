import { createContext, useContext } from 'react'

import type FrameEditorState from './FrameEditorState'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FrameEditorStateContext = createContext<FrameEditorState>({} as any)

export default function useFrameEditorState(): FrameEditorState {
  return useContext(FrameEditorStateContext)
}
