import type { PageNode } from '@computomatic/pages'

import type CssProperties from '../../../posts/client/CssProperties'
import type EmbeddedFile from '../../../posts/files/EmbeddedFile'
import PostImageFileReader from '../../../posts/files/post-image/PostImageFileReader'
import type Stylesheet from '../../client/Stylesheet'
import resolveStyle from '../../server/planning/page-content/resolveStyle'
import type MappingContext from '../MappingContext'
import type PublishingPlugin from '../PublishingPlugin'

export const IMAGE_STYLE_CLASS = 'image'

const POST_IMAGE_STYLESHEET: Stylesheet = {
  [IMAGE_STYLE_CLASS]: {
    display: 'block',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
} as const

export function dynamicPostImageStyle(width: number | null): CssProperties {
  const result: CssProperties = {}
  if (width !== null) {
    result.width = `${width}px`
  }

  return result
}

export default {
  stylesheet: POST_IMAGE_STYLESHEET,

  mapToPageNode({ stylesheet, imageUrlsById }: MappingContext, file: EmbeddedFile): PageNode {
    const { postImageId, width } = PostImageFileReader.openFile(file)

    const dynamicStyle = dynamicPostImageStyle(width)
    const nodeStyle = resolveStyle(stylesheet, [IMAGE_STYLE_CLASS], dynamicStyle)

    const imageUrl = imageUrlsById[postImageId]
    if (imageUrl === undefined) {
      throw new Error(`Image URL is missing from resource map. (${postImageId})`)
    }

    return {
      type: 'dom-element',
      tag: 'img',
      style: nodeStyle,
      attributes: {
        src: imageUrl,
      },
      children: [],
    }
  },
} satisfies PublishingPlugin
