import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Logo from '@assets/logo/juicy-logo-small.svg'

import HeroBanner from './HeroBanner'
import type Post from './Post'
import TileGrid from './TileGrid'
import Domain from './domain/Domain'
import type UpdateDomainResult from './domain/UpdateDomainResult'
import MenuButton from './menu/MenuButton'
import MenuDrawer from './menu/MenuDrawer'
import Button from './ui/Button'

import styles from './HomeScreen.module.scss'

const CREATE_POST_URL = '/posts/new'
const LOGOUT_URL = '/logout'

interface TopBarProps {
  menuOpen: boolean
  onClickMenuOpen: () => void
  onClickMenuClose: () => void
}

function TopBar({ menuOpen, onClickMenuOpen, onClickMenuClose }: TopBarProps): React.ReactElement {
  return (
    <div className={styles.TopBar}>
      <MenuButton
        className={styles.menuButton}
        isOpen={menuOpen}
        onClickOpen={onClickMenuOpen}
        onClickClose={onClickMenuClose}
      />
      <Logo className={styles.logo} />
      <div className={styles.actionButton}>
        <Button
          variant="primary"
          href={CREATE_POST_URL}
          aria-label="create a post"
        >
          Create a post
        </Button>
      </div>
    </div>
  )
}

function SideBar(): React.ReactElement {
  return (
    <div className={styles.SideBar}>
      <Link
        className={styles.logoutButton}
        to={LOGOUT_URL}
        relative="path"
        reloadDocument
        aria-label="logout"
      >
        Logout
      </Link>
    </div>
  )
}

interface ContentAreaProps {
  children: React.ReactNode
}

function ContentArea({ children }: ContentAreaProps): React.ReactElement {
  return <div className={styles.ContentArea}>{children}</div>
}

interface Props {
  domain: string
  rootDomain: string
  hasCustomDomain: boolean
  posts: Post[]
  onSetCustomDomain: (domain: string) => Promise<UpdateDomainResult>
  onRemoveCustomDomain: () => void
}

export default function HomeScreen({
  domain,
  rootDomain,
  hasCustomDomain,
  posts,
  onSetCustomDomain,
  onRemoveCustomDomain,
}: Props): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = (): void => {
    setMenuOpen(true)
  }
  const closeMenu = (): void => {
    setMenuOpen(false)
  }

  return (
    <div className={styles.HomeScreen}>
      <TopBar
        menuOpen={menuOpen}
        onClickMenuOpen={openMenu}
        onClickMenuClose={closeMenu}
      />
      <div className={styles.mainSection}>
        <MenuDrawer
          open={menuOpen}
          testingId="menu-drawer"
        >
          <SideBar />
        </MenuDrawer>
        <ContentArea>
          <HeroBanner createPostUrl={CREATE_POST_URL} />
          <Domain
            domainName={domain}
            rootDomain={rootDomain}
            isCustom={hasCustomDomain}
            onSetCustomDomain={onSetCustomDomain}
            onRemoveCustomDomain={onRemoveCustomDomain}
          />
          <TileGrid posts={posts} />
        </ContentArea>
      </div>
    </div>
  )
}
