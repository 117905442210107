import type { TypeOf } from 'io-ts'
import { literal, number, readonlyArray, strict, string } from 'io-ts'

const FrameElement = strict({
  id: string,
  index: number,

  type: literal('block'),
  x: number,
  y: number,

  blockType: literal('file-binding'),

  fileRefType: literal('embedded-file'),
  fileId: string,
})

const FrameState = strict({
  type: literal('frame.v1'),
  elements: readonlyArray(FrameElement),
  height: number,
})
type FrameState = TypeOf<typeof FrameState>

export const FrameState20240603 = FrameState
export type FrameState20240603 = FrameState
