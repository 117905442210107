import React from 'react'

import Logo from '@assets/logo/juicy-logo-small.svg'

import LoginForm from './LoginForm'
import AuthCard from './layout/AuthCard'
import CenteredLayout from './layout/CenteredLayout'
import CitrusSplashWallpaper from './layout/CitrusSplashWallpaper'

import styles from './LoginScreen.module.scss'

interface Props {
  signUpUrl: string
  showUnknownAccountError?: boolean
  error: string | null
  onSubmitForm: (email: string, password: string) => void
}

export default function LoginScreen({
  signUpUrl,
  showUnknownAccountError = false,
  error,
  onSubmitForm,
}: Props): React.ReactElement {
  let errorMessage = null
  if (error) {
    errorMessage = <p className={styles.error}>{error}</p>
  }
  if (showUnknownAccountError) {
    errorMessage = (
      <p className={styles.error}>
        {/* cSpell:words squot */}
        That account does not exist. Please try again or sign up for a new account if you don&squot;t have one already.
      </p>
    )
  }

  return (
    <div className={styles.LoginScreen}>
      <CitrusSplashWallpaper>
        <CenteredLayout>
          <AuthCard>
            <Logo className={styles.logo} />
            <h1 className={styles.heading}>Welcome to Juicy</h1>
            {errorMessage}
            <LoginForm
              signUpUrl={signUpUrl}
              onSubmitForm={onSubmitForm}
            />
          </AuthCard>
        </CenteredLayout>
      </CitrusSplashWallpaper>
    </div>
  )
}
