import { createContext, useContext } from 'react'

interface UploadImageOutput {
  postImageId: string
}

export type UploadImageHandler = (image: File) => Promise<UploadImageOutput>

export const UploadImageContext = createContext<UploadImageHandler>(() => {
  throw new Error(`UploadImageContext has no provider in scope.`)
})

export default function useUploadImage(): UploadImageHandler {
  return useContext(UploadImageContext)
}
