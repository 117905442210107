import type SelectionState from './SelectionState'

/**
 * Recursively follows the parent relationships from the given node until a root node is found.
 *
 * @returns A list of node IDs ordered from leaf to root. The first element is always the given node ID.
 */
export function traceAncestry({ parentIdsByChildId }: SelectionState, nodeId: string): string[] {
  const result: string[] = []
  let nextAncestorId: string | undefined = nodeId
  do {
    if (result.includes(nextAncestorId)) {
      throw new Error(`Cycle in ancestry map. (${result} => ${nextAncestorId})`)
    }

    result.push(nextAncestorId)
    nextAncestorId = parentIdsByChildId[nextAncestorId]
  } while (nextAncestorId !== undefined)

  return result
}

export function getParentId({ parentIdsByChildId }: SelectionState, childId: string): string | undefined {
  return parentIdsByChildId[childId]
}

function isSibling(state: SelectionState, nodeIdA: string, nodeIdB: string): boolean {
  return getParentId(state, nodeIdA) === getParentId(state, nodeIdB)
}

export function getChildIdFromKey({ childIdsByChildKey }: SelectionState, childKey: string): string | undefined {
  return childIdsByChildKey[childKey]
}

export function isDescendant(state: SelectionState, containerNodeId: string, maybeChildId: string): boolean {
  const lineage = traceAncestry(state, maybeChildId)

  return lineage.includes(containerNodeId)
}

export function getSelectedNodeId({ selectedNodeId }: SelectionState): string | undefined {
  return selectedNodeId
}

export function getEditingNodeId({ editingNodeId }: SelectionState): string | undefined {
  return editingNodeId
}

export function isNodeSelected({ selectedNodeId }: SelectionState, nodeId: string): boolean {
  return nodeId === selectedNodeId
}

export function isNodeEditing({ editingNodeId }: SelectionState, nodeId: string): boolean {
  return nodeId === editingNodeId
}

/**
 *
 * Determines whether the given element is the best candidate for selection when clicked, taking into consideration how
 * click events propagate through the DOM. Assumes a click event will stop propagating to ancestors once an element has
 * been selected.
 *
 * - The root of a selection tree (i.e., has no selectable ancestors) can always be selected.
 * - A descendant is currently selected.
 * - The current node's selectable ancestor is the lowest-common-ancestor between itself and the current selectable node.
 */
function canSelectWithClick(state: SelectionState, elementId: string): boolean {
  const parentId = getParentId(state, elementId)
  if (parentId === undefined) {
    // A node with no selectable ancestor is the root of a given selection tree and is always selectable.
    return true
  }

  const { editingNodeId } = state
  // Allow selection of any child of the current editingNodeId. Note: this is a superset of the siblings of any currently selected node (when composed with the rule above).
  if (parentId === editingNodeId) {
    return true
  }

  // Any sibling of any ancestor of the currently editing node can be selected with a click. This includes the currently editing node.
  if (editingNodeId === undefined) {
    return false
  }
  const editingLineage = traceAncestry(state, editingNodeId)

  return editingLineage.some((selectionAncestorId) => isSibling(state, elementId, selectionAncestorId))
}

/**
 * Identifies the node which would be selected if the user clicks the node currently under the cursor (ie, hoveringNodeId)
 */
function hoveringSelectionNodeId(state: SelectionState): string | undefined {
  const { hoveringNodeId } = state
  if (hoveringNodeId === undefined) {
    // Nothing would be clicked
    return undefined
  }

  const hoveredLineage = traceAncestry(state, hoveringNodeId)

  // Find the nearest ancestor to the hovered node which can currently be selected
  return hoveredLineage.find((ancestorId) => canSelectWithClick(state, ancestorId))
}

/**
 * Indicates if a click on the node currently under the cursor (ie, hoveringNodeId) would select the given nodeId.
 */
export function clickWouldSelectNodeId(state: SelectionState, nodeId: string): boolean {
  return hoveringSelectionNodeId(state) === nodeId
}
