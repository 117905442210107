import type { AriaAttributes } from 'react'
import React from 'react'

import mapFlowDocumentToRichTextJson from './mapFlowDocumentToRichTextJson'
import mapRichTextJsonToFlowDocument from './mapRichTextJsonToFlowDocument'
import type EmbeddedFile from '../../../services/posts/files/EmbeddedFile'
import FlowDocumentFileBuilder from '../../../services/posts/files/flow-document/FlowDocumentFileBuilder'
import FlowDocumentFileReader from '../../../services/posts/files/flow-document/FlowDocumentFileReader'
import FrameFileBuilder from '../../../services/posts/files/frame/FrameFileBuilder'
import PostImageFileBuilder from '../../../services/posts/files/post-image/PostImageFileBuilder'
import FlowDocumentClassNames from '../../../services/publishing/plugins/flow-document/FlowDocumentClassNames'
// eslint-disable-next-line import/no-cycle
import FileEditor from '../../files/FileEditor'
import type { CreateFileOutput } from '../../files/useCreateEmbeddedFile'
import useCreateEmbeddedFile from '../../files/useCreateEmbeddedFile'
import useUploadImage from '../../images/useUploadImage'
import useStylesheetClass from '../../stylesheets/useStylesheetClass'
import ErrorBoundary from '../../ui/ErrorBoundary'
import type RichTextClassNames from '../rich-text/RichTextClassNames'
import RichTextEditor from '../rich-text/RichTextEditor'
import type { RichTextDocument } from '../rich-text/model'

const DEFAULT_CANVAS_HEIGHT_PX = 450

/**
 * Maps class names between domains.
 *
 * There are three different domains involved:
 * 1. Flow documents have classes such as "strong", "emphasis", "list", etc. These class names mimic modern CSS
 *    philosophies including separation of semantic meaning from presentation.
 * 2. The rich-text editor (ie, Tiptap editor) references classes such as "bold", "italic", "bullet-list", etc. These
 *    class names derive directly from naming of Tiptap extensions and have a tendency to reflect styling without
 *    concern for semantics.
 * 3. CSS style classes available in the current document. These class names may change on each page load or even
 *    between renders.
 */
function useRichTextClassNames(): RichTextClassNames {
  const bold = useStylesheetClass(FlowDocumentClassNames.Strong)
  const code = useStylesheetClass(FlowDocumentClassNames.Code)
  const italic = useStylesheetClass(FlowDocumentClassNames.Emphasis)
  const link = useStylesheetClass(FlowDocumentClassNames.Hyperlink)
  const listItem = useStylesheetClass(FlowDocumentClassNames.ListItem)
  const bulletList = useStylesheetClass(FlowDocumentClassNames.List)
  const codeBlock = useStylesheetClass(FlowDocumentClassNames.CodeBlock)
  const fileBinding = useStylesheetClass(FlowDocumentClassNames.FileBinding)

  return {
    bold,
    code,
    italic,
    link,
    listItem,
    bulletList,
    codeBlock,
    fileBinding,
  }
}

interface Props extends Pick<AriaAttributes, 'aria-label'> {
  file: EmbeddedFile
  onChange: (updatedFile: EmbeddedFile) => void
  onFocus: () => void
  onBlur: () => void
}

export default function FlowDocumentBindingField({
  'file': flowDocFile,
  'aria-label': ariaLabel,
  onChange,
  onFocus,
  onBlur,
}: Props): React.ReactElement {
  const uploadImage = useUploadImage()
  const createEmbeddedFile = useCreateEmbeddedFile()
  const richTextClassNames = useRichTextClassNames()

  const { value: initialValue } = FlowDocumentFileReader.openFile(flowDocFile)
  const initialRichTextDoc = mapFlowDocumentToRichTextJson(initialValue)

  const handleChanged = (value: RichTextDocument): void => {
    const updatedFlowDoc = mapRichTextJsonToFlowDocument(value)
    const updatedFile = new FlowDocumentFileBuilder(updatedFlowDoc).build()
    onChange(updatedFile)
  }

  const handleImageUpload = async (file: File): Promise<{ fileId: string }> => {
    const { postImageId } = await uploadImage(file)

    const postImageFile = new PostImageFileBuilder(postImageId).build()

    return createEmbeddedFile(postImageFile)
  }

  const handleCreateCanvas = async (): Promise<CreateFileOutput> => {
    const canvasFile = new FrameFileBuilder(DEFAULT_CANVAS_HEIGHT_PX).build()

    return createEmbeddedFile(canvasFile)
  }

  return (
    <ErrorBoundary label="FlowDocumentBindingField">
      <RichTextEditor
        styleMap={richTextClassNames}
        defaultValue={initialRichTextDoc}
        placeholder="Write something..."
        aria-label={ariaLabel}
        onChange={handleChanged}
        onFocus={onFocus}
        onBlur={onBlur}
        uploadImage={handleImageUpload}
        createCanvas={handleCreateCanvas}
        createEmbeddedFile={createEmbeddedFile}
        FileEditorComponent={FileEditor}
      />
    </ErrorBoundary>
  )
}
