import styles from './HeroBanner.module.scss'

const HEADING = "What's on your mind?"
const TAGLINE = 'Write anything and share with anyone'

interface HeroButtonProps {
  href: string
}

function HeroButton({ href }: HeroButtonProps): React.ReactElement {
  return (
    <a
      className={styles.HeroButton}
      aria-label="create a post"
      href={href}
    >
      Create a post
    </a>
  )
}

interface Props {
  createPostUrl: string
}

export default function HeroBanner({ createPostUrl }: Props): React.ReactElement {
  return (
    <div className={styles.HeroBanner}>
      <div className={styles.copy}>
        <h2 className={styles.heading}>{HEADING}</h2>
        <p className={styles.tagline}>{TAGLINE}</p>
      </div>
      <HeroButton href={createPostUrl} />
    </div>
  )
}
