import type { Extensions } from '@tiptap/core'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'

import type RichTextClassNames from './RichTextClassNames'
import type { FileEditorComponent } from './extensions/FileBinding'
import FileBinding from './extensions/FileBinding'
import useDragDropDispatch from '../../drag-drop/useDragDropDispatch'
import type { EmbeddedFileCreateHandler } from '../../files/useCreateEmbeddedFile'

import styles from './tiptapExtensions.module.scss'

const UNDO_HISTORY_DEPTH = 1000

export default function useTiptapExtensions(
  classNames: RichTextClassNames,
  FileEditorComponent: FileEditorComponent,
  createEmbeddedFile: EmbeddedFileCreateHandler,
): Extensions {
  const dragDropDispatch = useDragDropDispatch()
  const handleFileDropped = (): void => {
    dragDropDispatch({ type: 'dragDrop/drop', payload: {} })
  }

  return [
    Document,
    Paragraph,
    Text,
    Bold.configure({
      HTMLAttributes: {
        class: classNames.bold,
      },
    }),
    Code.configure({
      HTMLAttributes: {
        class: classNames.code,
      },
    }),
    Italic.configure({
      HTMLAttributes: {
        class: classNames.italic,
      },
    }),
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        class: classNames.link,
      },
    }),
    ListItem.configure({
      HTMLAttributes: {
        class: classNames.listItem,
      },
    }),
    BulletList.configure({
      HTMLAttributes: {
        class: classNames.bulletList,
      },
    }),
    CodeBlock.configure({
      HTMLAttributes: {
        class: classNames.codeBlock,
      },
    }),
    FileBinding.configure({
      HTMLAttributes: {
        class: classNames.fileBinding,
      },
      FileEditorComponent,
      createEmbeddedFile,
      onDrop: handleFileDropped,
    }),
    History.configure({
      depth: UNDO_HISTORY_DEPTH,
    }),
    Dropcursor.configure({
      /** disable the default color */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: false as any,
      /** width cannot be set via CSS, unfortunately */
      // TODO: Try setting a border or outline
      width: 4,
      class: styles.DropcursorExtension,
    }),
    Gapcursor.configure({
      // Style is set via global CSS class: `.ProseMirror-gapcursor:after`
    }),
  ]
}
