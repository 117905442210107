import React, { useLayoutEffect, useState } from 'react'

import NotAvailableIcon from './NotAvailableIcon.svg'
import Button from '../ui/Button'

import styles from './EditForm.module.scss'

function parseSubdomain(fullDomainName: string): string {
  const rootStartIdx = fullDomainName.indexOf('.')
  if (rootStartIdx <= 0) {
    throw new Error(`Current domain (${fullDomainName}) is not valid.`)
  }

  return fullDomainName.substring(0, rootStartIdx)
}

function cleanSubdomainValue(value: string): string {
  // Remove any invalid chars
  return value.replace(/\./, '')
}

interface Props {
  initialDomainName: string
  rootDomain: string
  showUnavailable: boolean
  onDirty: () => void
  onSubmit: (domainName: string) => void
  onCancel: () => void
}

export default function EditForm({
  initialDomainName,
  rootDomain,
  showUnavailable,
  onDirty,
  onSubmit,
  onCancel,
}: Props): React.ReactElement {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null)
  const [value, setValue] = useState(parseSubdomain(initialDomainName))

  // Set focus to editing input
  useLayoutEffect(() => {
    if (inputEl === null) {
      return
    }

    inputEl.focus()
    inputEl.select()
  }, [inputEl])

  const updateValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const {
      target: { value: rawValue },
    } = e
    const cleanValue = cleanSubdomainValue(rawValue)
    setValue(cleanValue)
    onDirty()

    if (rawValue !== cleanValue) {
      e.target.value = cleanValue
    }
  }

  const save = (): void => {
    onSubmit(`${value}.${rootDomain}`)
  }

  const handleFormSubmitted: React.FormEventHandler = (e) => {
    e.preventDefault()
    save()
  }

  const statusMessage: React.ReactNode = showUnavailable ? (
    <div className={styles.errorMessage}>
      <p className={styles.domainUnavailable}>
        <NotAvailableIcon className={styles.icon} /> Domain unavailable
      </p>
    </div>
  ) : null

  return (
    <form
      className={styles.EditForm}
      aria-label="edit domain form"
      onSubmit={handleFormSubmitted}
    >
      <div className={styles.field}>
        <input
          ref={setInputEl}
          className={styles.textInput}
          type="text"
          aria-label="subdomain"
          defaultValue={value}
          onChange={updateValue}
        />
        <span className={styles.rootDomain}>{`.${rootDomain}`}</span>
      </div>
      {statusMessage}
      <div className={styles.buttonGroup}>
        <Button
          variant="secondary"
          size="small"
          aria-label="cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          aria-label="update"
          onClick={save}
        >
          Update
        </Button>
      </div>
    </form>
  )
}
