import type SelectionState from '../selection/SelectionState'
import useSelectionState from '../selection/useSelectionState'
import usePostEditorState from '../state/usePostEditorState'

import styles from './DebugPanel.module.scss'

function buildSelectionSubtree(state: SelectionState, subtreeRootId: string): Record<string, unknown>[] {
  const { parentIdsByChildId } = state

  const childIds = Object.entries(parentIdsByChildId)
    .filter(([, parentId]) => parentId === subtreeRootId)
    .map(([childId]) => childId)

  return childIds.map<Record<string, unknown>>((childId) => ({ [childId]: buildSelectionSubtree(state, childId) }))
}

function buildSelectionTree(state: SelectionState): Record<string, unknown>[] {
  const { elementIds, parentIdsByChildId } = state
  const rootIds = new Set(elementIds)
  Array.from(Object.keys(parentIdsByChildId)).forEach((childId) => rootIds.delete(childId))

  return Array.from(rootIds).map<Record<string, unknown>>((rootId) => ({
    [rootId]: buildSelectionSubtree(state, rootId),
  }))
}

interface ObjectInspectorProps {
  obj: object
}

function ObjectInspector({ obj }: ObjectInspectorProps): React.ReactElement {
  const json = JSON.stringify(obj, undefined, 2)

  return <pre className={styles.ObjectInspector}>{json}</pre>
}

interface PanelProps {
  children: React.ReactNode
}

function Panel({ children }: PanelProps): React.ReactElement {
  return <div className={styles.Panel}>{children}</div>
}

export default function DebugPanel(): React.ReactElement {
  const { pageTemplate, embeddedFiles } = usePostEditorState()
  const selectionState = useSelectionState()

  const selectionTree = buildSelectionTree(selectionState)

  return (
    <div
      className={styles.DebugPanel}
      aria-label="debug panel"
    >
      <div className={styles.titleBar}>Debug</div>
      <div className={styles.panels}>
        <Panel>
          <details>
            <summary>Template</summary>
            <ObjectInspector obj={pageTemplate} />
          </details>
        </Panel>
        <Panel>
          <details>
            <summary>Embedded files</summary>
            <ObjectInspector obj={embeddedFiles} />
          </details>
        </Panel>
        <Panel>
          <details>
            <summary>Selection state</summary>
            <ObjectInspector obj={selectionState} />
          </details>
        </Panel>
        <Panel>
          <details>
            <summary>Selection hierarchy</summary>
            <ObjectInspector obj={selectionTree} />
          </details>
        </Panel>
      </div>
    </div>
  )
}
