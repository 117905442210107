import { useEffect, useReducer, useState } from 'react'

import selectionReducer, { INITIAL_SELECTION_STATE } from './selectionReducer'
import { getEditingNodeId, getSelectedNodeId } from './selectors'
import { SelectionDispatchContext } from './useSelectionDispatch'
import { SelectionStateContext } from './useSelectionState'

/** Reference: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values */
const KEY_ESCAPE = 'Escape'
const KEY_ENTER = 'Enter'

interface Props {
  children: React.ReactNode
}

export default function SelectionProvider({ children }: Props): React.ReactElement {
  const [state, dispatch] = useReducer(selectionReducer, INITIAL_SELECTION_STATE)

  const selectedNodeId = getSelectedNodeId(state)
  const editingNodeId = getEditingNodeId(state)

  const [domNode, setDomNode] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    if (domNode === null) {
      return () => {}
    }

    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === KEY_ENTER && selectedNodeId !== undefined) {
        e.preventDefault()

        // TODO: Only start editing if a) node has children to select; or b) the node is otherwise editable (eg, flow doc)
        dispatch({ type: 'selection/startEditing', payload: { nodeId: selectedNodeId } })
      } else if (e.key === KEY_ESCAPE && editingNodeId !== undefined) {
        e.preventDefault()
        e.stopPropagation()

        dispatch({ type: 'selection/endEditing', payload: { nodeId: editingNodeId } })
      }
    }

    domNode.addEventListener('keydown', handleKeyDown)

    return () => {
      domNode.removeEventListener('keydown', handleKeyDown)
    }
  }, [domNode, editingNodeId, selectedNodeId])

  return (
    <SelectionStateContext.Provider value={state}>
      <SelectionDispatchContext.Provider value={dispatch}>
        <div ref={setDomNode}>{children}</div>
      </SelectionDispatchContext.Provider>
    </SelectionStateContext.Provider>
  )
}
