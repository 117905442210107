import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type DragDropAction from './DragDropAction'

export const DragDropDispatchContext = createContext<Dispatch<DragDropAction>>(() => {})

export default function useDragDropDispatch(): Dispatch<DragDropAction> {
  return useContext(DragDropDispatchContext)
}
