import React from 'react'

import styles from './Domain.module.scss'

interface Props {
  children: React.ReactNode
}

export default function DomainCard({ children }: Props): React.ReactElement {
  return <div className={styles.DomainCard}>{children}</div>
}
