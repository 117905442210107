import { fold } from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import type { Type } from 'io-ts'

export default function stringify<T>(codec: Type<T>, value: T): string {
  if (!codec.is(value)) {
    throw new Error(`Value does not match codec.`)
  }

  // Remove any extraneous properties (requires codec using `strict` types)
  const clean = pipe(
    codec.decode(value),
    fold(
      // failure handler
      (errors) => {
        console.error(`Error serializing value:`, errors)
        throw new Error(`Attempted to serialize value which does match codec.`)
      },
      // success handler
      (a) => a,
    ),
  )

  return JSON.stringify(clean)
}
