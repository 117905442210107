import type { Type } from 'io-ts'

import { FlowDocument20240515 } from './FlowDocument20240515'
import stringify from '../../serialization/stringify'
import type FlowDocument from '../FlowDocument'

const CURRENT_SCHEMA: Type<FlowDocument> = FlowDocument20240515

export default function stringifyFlowDocument(document: FlowDocument): string {
  return stringify(CURRENT_SCHEMA, document)
}
