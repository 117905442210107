import React from 'react'

import './layout.scss'

interface Props {
  children: React.ReactNode
}

export default function Layout({ children }: Props): React.ReactElement {
  // No-op. This element simply loads the common layout styles
  return <>{children}</>
}
