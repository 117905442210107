import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoginScreen from '../../../authentication/LoginScreen'
import { useRequestContext, useWorkspaceController } from '../../services'

export default function LoginScreenBinding(): React.ReactElement {
  const ctx = useRequestContext()
  const controller = useWorkspaceController()
  const navigate = useNavigate()

  const [unknownAccount, setUnknownAccount] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmitted = (email: string, password: string): void => {
    const doNavigate = async (): Promise<void> => {
      setUnknownAccount(false)
      try {
        const { userId } = await controller.login(ctx, { email, password })
        if (userId === null) {
          // No such account exists
          setUnknownAccount(true)

          return
        }

        // Redirect user to Workspace Home after account login completes successfully.
        navigate('/')
      } catch (err) {
        console.error(`[LoginScreenBinding::handleFormSubmitted] Error:`, err)
        setError('An unexpected error has occurred.')
      }
    }

    doNavigate().catch((err): void => {
      console.error(`[LoginScreenBinding::handleFormSubmitted] Error:`, err)
    })
  }

  return (
    <LoginScreen
      signUpUrl="/signup" // cSpell:words signup
      error={error}
      showUnknownAccountError={unknownAccount}
      onSubmitForm={handleFormSubmitted}
    />
  )
}
