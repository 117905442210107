import React, { memo } from 'react'

import FileEditor from './files/FileEditor'
import type { PageTemplateNode } from '../services/posts/client/PostContent'

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const MemoTemplatedContent = memo(TemplatedContent)
const MemoFileEditor = memo(FileEditor)

interface Props {
  node: PageTemplateNode
}

export default function TemplatedContent({ node }: Props): React.ReactElement {
  const { type } = node
  switch (type) {
    case 'dom-element': {
      const { tag, style, attributes, children: childNodes } = node
      const childElements = childNodes.map((childNode, idx) => (
        <MemoTemplatedContent
          // eslint-disable-next-line react/no-array-index-key
          key={`${tag}/child(${idx})`}
          node={childNode}
        />
      ))

      // Spread is used for childElements so that nothing is passed when the collection is empty. This avoids an error
      // thrown by React for certain elements that don't support children..
      // Example: "Error: img is a self-closing tag and must neither have `children` nor use `dangerouslySetInnerHTML`."
      return React.createElement(tag, { ...attributes, style }, ...childElements)
    }
    case 'text': {
      const { content } = node

      return <>{content}</>
    }
    case 'fragment': {
      const { children: childNodes } = node
      const childElements = childNodes.map((childNode, idx) => (
        <MemoTemplatedContent
          // eslint-disable-next-line react/no-array-index-key
          key={`fragment/child(${idx})`}
          node={childNode}
        />
      ))

      return <>{childElements}</>
    }
    case 'slot': {
      const { name: slotName } = node

      return (
        <MemoFileEditor
          fileId={slotName}
          aria-label={slotName}
        />
      )
    }
    default:
      throw new Error(`Unknown node type: ${type}`)
  }
}
