import React, { useReducer } from 'react'

import type DragDropState from './DragDropState'
import dragDropReducer from './dragDropReducer'
import { DragDropDispatchContext } from './useDragDropDispatch'
import { DragDropStateContext } from './useDragDropState'

const INITIAL_STATE: DragDropState = {
  dragState: { stateType: 'inactive' },
}

interface Props {
  children: React.ReactNode
}

export default function DragDropProvider({ children }: Props): React.ReactElement {
  const [state, dispatch] = useReducer(dragDropReducer, INITIAL_STATE)

  return (
    <DragDropStateContext.Provider value={state}>
      <DragDropDispatchContext.Provider value={dispatch}>{children}</DragDropDispatchContext.Provider>
    </DragDropStateContext.Provider>
  )
}
