import parsePostImage from './serialization/parsePostImage'
import type EmbeddedFile from '../EmbeddedFile'
import MediaTypes from '../MediaTypes'

export default class PostImageFileReader {
  private constructor(
    public readonly postImageId: string,
    public readonly width: number | null,
  ) {}

  static openFile(file: EmbeddedFile): PostImageFileReader {
    if (file.mediaType !== MediaTypes.PostImage) {
      throw new Error(`File has unexpected mediaType: ${file.mediaType}`)
    }

    const bytes = file.readAsBytes()
    const { postImageId, width } = parsePostImage(bytes)

    return new PostImageFileReader(postImageId, width)
  }
}
