import React from 'react'

import styles from './AuthCard.module.scss'

interface Props {
  children: React.ReactNode
}

export default function AuthCard({ children }: Props): React.ReactElement {
  return <div className={styles.AuthCard}>{children}</div>
}
