import React, { useState } from 'react'

import DomainCard from './DomainCard'
import EditForm from './EditForm'
import type UpdateDomainResult from './UpdateDomainResult'
import WebIcon from './Web.svg'

import styles from './Domain.module.scss'

interface ViewCardProps {
  domainName: string
  isCustom: boolean
  onClickEdit: () => void
  onClickRemove: () => void
}

function ViewCard({ domainName, isCustom, onClickEdit, onClickRemove }: ViewCardProps): React.ReactElement {
  const status = isCustom ? 'Custom' : 'Free'

  const actionButton = isCustom ? (
    <button
      className={styles.editButton}
      type="button"
      aria-label="remove custom domain"
      onClick={onClickRemove}
    >
      Remove
    </button>
  ) : (
    <button
      className={styles.editButton}
      type="button"
      aria-label="customize domain"
      onClick={onClickEdit}
    >
      Customize
    </button>
  )

  return (
    <div className={styles.ViewCard}>
      <div className={styles.icon}>
        <WebIcon />
      </div>
      <p className={styles.domain}>{domainName}</p>
      <p className={styles.details}>
        {status} · {actionButton}
      </p>
    </div>
  )
}

interface Props {
  domainName: string
  rootDomain: string
  isCustom: boolean
  onSetCustomDomain: (domainName: string) => Promise<UpdateDomainResult>
  onRemoveCustomDomain: () => void
}

export default function Domain({
  domainName,
  rootDomain,
  isCustom,
  onSetCustomDomain,
  onRemoveCustomDomain,
}: Props): React.ReactElement {
  const [editing, setEditing] = useState(false)
  const [domainUnavailable, setDomainUnavailable] = useState(false)

  const beginEditing = (): void => {
    setEditing(true)
    setDomainUnavailable(false)
  }

  const saveCustomDomain = (updatedDomainName: string): void => {
    async function doSave(): Promise<void> {
      const result = await onSetCustomDomain(updatedDomainName)

      if (result.status === 'success') {
        setEditing(false)

        return
      }

      const { status, reason } = result
      if (status !== 'failed') {
        throw new Error(`Unknown result status: ${status}`)
      }
      if (reason !== 'domain-unavailable') {
        throw new Error(`Unknown update failure reason: ${reason}`)
      }

      setDomainUnavailable(true)
    }

    doSave()
  }

  const cancelEditing = (): void => {
    setEditing(false)
    setDomainUnavailable(false)
  }

  const resetUnavailableState = (): void => {
    setDomainUnavailable(false)
  }

  if (editing) {
    return (
      <DomainCard>
        <EditForm
          initialDomainName={domainName}
          rootDomain={rootDomain}
          showUnavailable={domainUnavailable}
          onDirty={resetUnavailableState}
          onSubmit={saveCustomDomain}
          onCancel={cancelEditing}
        />
      </DomainCard>
    )
  }

  return (
    <DomainCard>
      <ViewCard
        domainName={domainName}
        isCustom={isCustom}
        onClickEdit={beginEditing}
        onClickRemove={onRemoveCustomDomain}
      />
    </DomainCard>
  )
}
