import React, { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { clickWouldSelectNodeId, getEditingNodeId, getParentId, isNodeEditing, isNodeSelected } from './selectors'
import useSelectionDispatch from './useSelectionDispatch'
import type { SelectionNodeContextData } from './useSelectionNode'
import { SelectionNodeContext } from './useSelectionNode'
import useSelectionState from './useSelectionState'

import styles from './selection.module.scss'

const CLASS_NAME_SELECTABLE = styles.SelectableElement ?? '__unknown-style__'
const CLASS_NAME_SELECTED = styles.selected ?? '__unknown-style__'
const CLASS_NAME_EDITING = styles.editing ?? '__unknown-style__'
const CLASS_NAME_CAN_SELECT = styles.canSelect ?? '__unknown-style__'

interface Props {
  nodeId: string
  children: React.ReactNode
}

export default function SelectionTreeNode({ nodeId, children }: Props): React.ReactElement {
  const dispatch = useSelectionDispatch()
  const state = useSelectionState()

  const [clientLoaded, setClientLoaded] = useState(false)

  useEffect(() => {
    setClientLoaded(true)
    dispatch({ type: 'selection/registerElement', payload: { nodeId } })

    return () => {
      dispatch({ type: 'selection/unregisterElement', payload: { nodeId } })
    }
  }, [dispatch, nodeId])

  const isSelected = isNodeSelected(state, nodeId)
  const isEditing = isNodeEditing(state, nodeId)
  const parentId = getParentId(state, nodeId)

  // Includes case where both are undefined (i.e., node is a root and nothing is editing)
  const parentIsEditing = getEditingNodeId(state) === parentId

  const parentIsSelected = parentId === undefined || isNodeSelected(state, parentId)

  const clickWouldSelect = clickWouldSelectNodeId(state, nodeId)

  const handleClicked = (e: React.MouseEvent): void => {
    if (clickWouldSelect && !isSelected && !isEditing) {
      e.stopPropagation()
      dispatch({ type: 'selection/selectElement', payload: { nodeId } })
    }
  }

  const handleDoubleClicked = (e: React.MouseEvent): void => {
    if (parentIsSelected) {
      e.stopPropagation()
      dispatch({ type: 'selection/selectElement', payload: { nodeId } })
    }
  }

  const handleMouseEnter = (e: React.MouseEvent): void => {
    e.stopPropagation()
    dispatch({ type: 'selection/mouseEnter', payload: { nodeId } })
  }

  const handleMouseOver = (e: React.MouseEvent): void => {
    e.stopPropagation()
    dispatch({ type: 'selection/mouseOver', payload: { nodeId } })
  }

  const handleMouseLeave = (e: React.MouseEvent): void => {
    e.stopPropagation()
    dispatch({ type: 'selection/mouseLeave', payload: { nodeId } })
  }

  const contextData: SelectionNodeContextData = useMemo(() => ({ nodeId }), [nodeId])
  const className = classNames(CLASS_NAME_SELECTABLE, {
    [CLASS_NAME_SELECTED]: isSelected,
    [CLASS_NAME_EDITING]: isEditing,
    [CLASS_NAME_CAN_SELECT]: clickWouldSelect,
  })

  // A quick workaround to prevent SSR hydration errors due to invalid nesting
  if (!clientLoaded) {
    return <>{children}</>
  }

  return (
    <SelectionNodeContext.Provider value={contextData}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={className}
        data-selection-node-id={nodeId}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={clickWouldSelect || parentIsEditing ? 0 : undefined}
        onClick={handleClicked}
        onDoubleClick={handleDoubleClicked}
        onMouseEnter={handleMouseEnter}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </SelectionNodeContext.Provider>
  )
}
