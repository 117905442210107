import type { AriaAttributes } from 'react'
import React from 'react'

import classNames from 'classnames'

import styles from './Button.module.scss'

interface ButtonProps extends Pick<AriaAttributes, 'aria-label'> {
  variant: 'primary' | 'secondary'
  size?: 'small' | 'medium'
  disabled?: boolean
  children: React.ReactNode
  href?: string
  onClick?: () => void
}

export default function Button({
  variant,
  size = 'medium',
  disabled = false,
  'aria-label': ariaLabel,
  children,
  href,
  onClick,
}: ButtonProps): React.ReactElement {
  const className = classNames(styles.Button, styles[variant], styles[size])

  if (href && !disabled) {
    return (
      <a
        className={className}
        aria-label={ariaLabel}
        href={href}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      className={className}
      type="button"
      disabled={disabled}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
